import React, { useEffect, useState } from "react";
import FamillyService from "../../../services/familles.service";
import SubCategoryService from "../../../services/subcategory.service";
import useDataTableScripts from "../../inc/helpfulComponents/useDataTableScripts";
import WichPage from "../../inc/helpfulComponents/WichPage";
import CategoryService from "../../../services/category.service";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Swal from "sweetalert2";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";

// import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import MenuItem from '@material-ui/core/MenuItem';

const Families = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [familles, setfamilles] = useState([]);
  const [subcategories, setsubcategories] = useState([]);
  const [familly, setFamilly] = useState("");
  const [subCategory, setsubCategory] = useState("");
  const [subcategoryId, setSubCategoryId] = useState(0);
  const [idFamillyUpdate, setIdFamillyUpdate] = useState(0);
  const [category, setCategory] = useState(null);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const renderActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <button
          data-toggle="modal"
          data-target="#modal-edit"
          className="btn btn-icon btn-outline-primary my-2 mx-3"
          onClick={() => {
            setFamilly(row.name);
            setsubCategory(row.sub_category);
            setIdFamillyUpdate(row.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
        {/* <ModalEditFamilyName /> */}
        <button
          type="button"
          className="btn btn-icon btn-outline-danger my-2 mx-3"
          id={row.id}
          onClick={deleteFamilly}
        >
          <i className="far fa-trash-alt" id={row.id}></i>
        </button>
      </div>
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "id", width: "90px"},
    {
      selector: "name",
      name: "Nom de la sous-catégorie",
      width: "300px",
      wrap: true,
    },
    {
      selector: "sub_category",
      name: "Sous-Catégorie",
      width: "300px",
      wrap: true,
    },
    {
      selector: "category",
      name: "NOM DE LA CATEGORIE",
      width: "300px",
      wrap: true,

    },
    {
      selector: "nbr_product",
      name: "NOMBRE DE PRODUITS",
      width: "auto",

      // hide: false
    },
    {
      name: "Action",
      width: "170px",
      cell: (row) => renderActions(row),
    },
  ]);
  const tableData = {
    columns,
    data: familles,
  };
  useEffect(() => {
    SubCategoryService.getSybCateoiesByCategoryId(category).then((res) => {
      setsubcategories(res.data.results);
    });
  }, [category]);

  useEffect(() => {
    CategoryService.allCategories().then((res) => {
      setCategories(res.data.results);
    });

    FamillyService.allfamilles().then((res) => {
      console.log(res);
      setfamilles(res.data.results);
      console.log(familles);
    });
  }, []);

  //Add Familly

  // load categories for the select option
  useEffect(() => {
    setLoading(true);
    FamillyService.allSubCategories().then((res) => {
      // console.log(res)
      setsubcategories(res.data.results);
      setLoading(false);
    });
  }, []);
  const addFamilly = () => {
    console.log(name);
    const userId = sessionStorage.getItem("id_user");
    console.log(userId);
    const token = sessionStorage.getItem("token");
    if (name !== "" && subcategoryId !== 0) {
      FamillyService.addFamilly(subcategoryId, name, userId, token)
        .then((res) => {
          // console.log(res)
          FamillyService.allfamilles().then((res) => {
            // console.log(res)
            NotificationManager.success(
              "Famille est ajouter avec  success",
              "",
              2000
            );
            setfamilles(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);

          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });

      setName("");
    } else {
      if (name === "") {
        NotificationManager.warning(
          "Le nom de la famille est necessaire ",
          "",
          2000
        );
      }
      if (subcategoryId === "0") {
        NotificationManager.warning(
          "Vous devez choisir une sous-categorie",
          "",
          2000
        );
      }
    }
  };

  // Update Familly
  const updateFamilly = () => {
    setName("");
    const token = sessionStorage.getItem("token");
    if (name !== "" && subcategoryId !== 0) {
      FamillyService.updateFamilly(idFamillyUpdate, subcategoryId, name, token)
        .then((res) => {
          FamillyService.allfamilles().then((res) => {
            NotificationManager.success(
              "La famille est mise a jour avec success",
              "",
              2000
            );
            setfamilles(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });
    } else {
      if (name === "") {
        NotificationManager.warning(
          "le nom de la famille est necessaire",
          "",
          2000
        );
      }
      if (subcategoryId === "0") {
        NotificationManager.warning(
          "Vous devez choisir une sous-categories",
          "",
          2000
        );
      }
    }
  };
  // console.log(subcategoryId);
  // DELETE CATEGORY

  const deleteFamilly = (event) => {
    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    const famillyId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "Vous ne pourrez pas revenir sur cette famille!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez-le",
        cancelButtonText: "Non, annulez !",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          FamillyService.deleteFamilly(famillyId, userId, token).then((res) => {
            // console.log(res)
            FamillyService.allfamilles().then((res) => {
              // console.log(res)
              setfamilles(res.data.results);
            });
          });

          swalWithBootstrapButtons.fire(
            "Supprimé !",
            "La famille a été supprimée.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "La famille n'a pas été supprimée.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <WichPage pageTitle="Familles" pageLocation="familles" />
      <div className="row" id="modalId">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5>Familles</h5>
              <div className="card-header-right">
                <h5 className="">Ajouter une famille</h5>
                <button
                  className="btn btn-icon btn-outline-primary"
                  data-toggle="modal"
                  data-target="#modal-add"
                >
                  <i className="fas fa-plus-circle"></i>
                </button>
                {/* <ModalAddFamily /> */}
              </div>
            </div>
            <div className="card-body">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* MODALS */}

      {/* START ADD SUBCATEGORY MODAL */}
      <div
        className="modal fade"
        id="modal-add"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter une famille</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label className="" htmlFor="Category">
                      Catégorie
                    </label>
                    <select
                      className="form-control"
                      id="Category"
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="0"></option>
                      {categories.map((cat, index) => (
                        <option value={cat.id}>{cat.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="" htmlFor="Category">
                      Sous-Catégorie
                    </label>
                    <select
                      className="form-control"
                      id="sub_Category"
                      onChange={(e) => setSubCategoryId(e.target.value)}
                    >
                      <option value="0"></option>
                      {subcategories.map((subcategory, index) => (
                        <option value={subcategory.id}>
                          {subcategory.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="" htmlFor="Name">
                      Nom de la famille
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      placeholder="Nom de la sous-catégorie"
                    />
                  </div>
                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary"
                    onClick={addFamilly}
                  >
                    <i className="feather icon-save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* END ADD SUBCATEGORY MODAL */}

      {/* START UPDATE SUBCATEGORY MODAL*/}
      <div
        className="modal fade"
        id="modal-edit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modifier le nom de la famille</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Nom actuel : {familly}</label>
                  </div>
                  <div className="form-group">
                    <label>Nom de la sous-catégorie : {subCategory} </label>
                  </div>
                  <div className="form-group">
                    <label className="floating-label" htmlFor="Category">
                      Sous-Catégorie
                    </label>
                    <select
                      className="form-control"
                      id="Category"
                      onChange={(e) => setSubCategoryId(e.target.value)}
                    >
                      <option value="0"></option>
                      {subcategories.map((subcategory, index) => (
                        <option value={subcategory.id}>
                          {subcategory.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="floating-label" htmlFor="Name">
                      le nouveau nom
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary"
                    onClick={updateFamilly}
                  >
                    <i className="feather icon-save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* END UPDATE SUBCATEGORY MODAL*/}
    </>
  );
};

export default Families;
