import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Categories from "./components/pages/categories/Categories";
import Home from "./components/pages/Home/Home";
import NotFound from "./components/pages/notFound/NoteFound";
import Sidebar from "./components/inc/sidebar/Sidebar";
import Navbar from "./components/inc/navbar/Navbar";
import Profil from "./components/pages/profil/Profil";
import Product from "./components/pages/product/Product";
import Admins from "./components/pages/admins/Admins";
import Clients from "./components/pages/client/Clients";
import SubCategories from "./components/pages/subcategories/SubCategories";
import Families from "./components/pages/families/Families";
import DemandQuotation from "./components/pages/devis/demandQuotation/DemandQuotation";
import ValidatedQuotaion from "./components/pages/devis/validatedQuotation/ValidatedQuotaion";
import QuotationForm from "./components/pages/devis/demandQuotation/QuotationForm";
// import ValidatedQuotationDetails from "./components/pages/devis/validatedQuotation/ValidatedQuotationDetails";
import BlogEditor from "./components/pages/blogs/BlogEditor";
import Blogs from "./components/pages/blogs/Blogs";
import ProductDetails from "./components/pages/product/ProductDetails";
import ModifierProduit from "./components/pages/product/ModifierProduit";
import detailNewArrival from "./components/pages/newarrivals/detailNewArrival";
import detailAmazingProduct from "./components/pages/amazing_products/details";
import updateNewArrival from "./components/pages/newarrivals/updateNewArrival";
import Complaints from "./components/pages/complaints/Complaints";
import NewArrivals from "./components/pages/newarrivals/NewArrivals";
import AddProduct from "./components/pages/product/AddProduct";
import AddAmazingProduct from "./components/pages/amazing_products/AddAmazingProduct";
import Login from "./components/pages/login/Login";
import AuthService from "./services/auth.service";
import Subscribers from "./components/pages/subscibers/Subscribers";
import ProductInSold from "./components/pages/product/ProductInSold";
import SessionTimeout from "./services/SessionTimeout";
import Marques from "./components/pages/marques/Marques";
import CategoryBlogs from "./components/pages/categoryBlogs/CategoryBlogs";
import { NotificationContainer } from "react-notifications";
import Bloggers from "./components/pages/bloggers/Bloggers";
import Stock from "./components/pages/stock/Stock";
import BlogDetails from "./components/pages/blogs/BlogDetails";
import Promodujour from "./components/pages/product/Promodujour";
import Contacts from "./components/pages/contactmessages/Contact";
import Slider from "./components/pages/sliders/Slider";
import SubSlider from "./components/pages/sliders/SubSlider";
import UpdateBlog from "./components/pages/blogs/UpdateBlog";
import ListOrders from "./components/pages/orders"
import OrderDetails from './components/pages/orders/orderDetails'
import ListAmazingProducts from './components/pages/amazing_products/list'
import ModifierAmazingProduit from "./components/pages/amazing_products/edit";
import PlusEtonnants from "./components/pages/plus_etonnants";
import CovidProducts from "./components/pages/covid_products";
import AchatMain from './components/pages/achat_imediat'
import AchatMainDetails from './components/pages/achat_imediat/detail'
import ManageSliders from './components/pages/product/manageSlider'

function App() {
  if (!AuthService.getCurrentUser().token) {
    return <Login />;
  }
  return (
    <Router>
      <SessionTimeout />
      <NotificationContainer />
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/categories" component={Categories} />
            <Route path="/profil" component={Profil} />
            <Route path="/produits" component={Product} />
            <Route path="/amazing_products" component={ListAmazingProducts} />
            <Route path="/etonnant-products" component={PlusEtonnants} />
            <Route path="/covid-products" component={CovidProducts} />
            <Route path="/stock" component={Stock} />
            <Route path="/redacteurs" component={Bloggers} />
            <Route
              exact
              path="/detailsProduit/:id"
              component={ProductDetails}
            />
            <Route
              exact
              path="/detailsNouveauArrive/:id"
              component={detailNewArrival}
            />
            <Route
              exact
              path="/modifierProduit/:id"
              component={ModifierProduit}
            />
            <Route
              exact
              path="/modifierNouveauArrive/:id"
              component={updateNewArrival}
            />
            <Route
              exact
              path="/details/amazing_products/:id"
              component={detailAmazingProduct}
            />
            <Route
              exact
              path="/modifier/amazing_products/:id"
              component={ModifierAmazingProduit}
            />
            <Route path="/admins" component={Admins} />
            <Route path="/clients" component={Clients} />
            <Route path="/familles" component={Families} />
            <Route path="/marques" component={Marques} />
            <Route path="/blogs" component={Blogs} />
            <Route path="/blogDetails/:id" component={BlogDetails} />
            <Route path="/reclamations" component={Complaints} />
            <Route path="/contacts" component={Contacts} />
            <Route path="/devis" component={DemandQuotation} />
            <Route path="/devisDemandes" component={DemandQuotation} />
            <Route exact path="/devisDetails/:id" component={QuotationForm} />
            {/* <Route path="/devisDetails/:id" component={ValidatedQuotationDetails} /> */}
            <Route path="/editBlog" component={BlogEditor} />
            <Route path="/updateBlog/:id" component={UpdateBlog} />
            <Route path="/categoryBlogs" component={CategoryBlogs} />
            <Route path="/devisValides" component={ValidatedQuotaion} />
            <Route path="/nouveauxArrivages" component={NewArrivals} />
            <Route path="/souscategories" component={SubCategories} />
            <Route path="/ajouterProduit" component={AddProduct} />
            <Route path="/addAmazingProduct" component={AddAmazingProduct} />
            <Route path="/pdoduitsEnSolde" component={ProductInSold} />
            <Route path="/promodujour" component={Promodujour} />
            <Route path="/abonnes" component={Subscribers} />
            <Route path="/sliders" component={Slider} />
            <Route path="/subsliders" component={SubSlider} />
            <Route path="/orders/:status" component={ListOrders} />
            <Route path="/orders" component={ListOrders} />
            <Route path="/details/order/:id" component={OrderDetails} />
            <Route exact path="/achat-imediat" component={AchatMain} />
            <Route path="/achat-imediat/:id" component={AchatMainDetails} />
            <Route exact path="/manage-slider/:id" component={ManageSliders} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
