import React, { useRef, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../../services/auth.service";
import isEmail from "validator/lib/isEmail";



const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const form = useRef();
  const checkBtn = useRef();

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(email, password)
        .then(
          () => {
            window.location.reload();
          },
          (error) => {
            let message =
              typeof error.response !== "undefined"
                ? error.response.data.erreur
                : error.erreur;
            setLoading(false);
            setMessage(message);
          }
        )
        .catch((err) => {
          console.warn("error", message);
        });
    } else {
      setLoading(false);
    }
  };

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Ce champ est obligatoire!
        </div>
      );
    }
  };

  const checkEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Email n'est pas valide.
        </div>
      );
    }
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };






  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="card">
          <div className="row align-items-center text-center">
            <div className="col-md-12">
              <Form className="card-body" onSubmit={handleLogin} ref={form}>
                <img
                  src="/assets/images/logo.png"
                  alt=""
                  className="img-fluid mb-4"
                  style={{ width:"50%" }}
                />
                <h4 className="mb-3 f-w-400">Connexion</h4>
                <div className="form-floating text-start mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={onChangeEmail}
                    validations={[required, checkEmail]}
                    placeholder="Email"
                  />
                </div>
                <div className="form-floating mb-4">
                  <Input
                    type="password"
                    className="form-control"
                    id="Password"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    validations={[required]}
                    placeholder=" mot de passe"
                  />
                </div>

                <button
                  className="btn btn-block btn-primary mb-4"
                  disabled={loading}
                >
                  Se connecter
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                </button>
                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
