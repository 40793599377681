import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import WichPage from "../../inc/helpfulComponents/WichPage";
import { useEffect, useState } from "react";
import BlogService from "../../../services/blog.service";
import DOMPurify from "dompurify";
import { useDropzone } from "react-dropzone";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { makeStyles, TextField } from "@material-ui/core";
import "./editorStyles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import createImagePlugin from "@draft-js-plugins/image";
import "@draft-js-plugins/image/lib/plugin.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const BlogEditor = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [id_categorieBlg, setId_categorieBlg] = useState("");
  const [image, setImage] = useState("");
  const [catBlogs, setCatBlogs] = useState([]);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(true);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: () => setSeconImage(""),
  });
  const [seconImage, setSeconImage] = useState("");
  const copy = async () => {
    await navigator.clipboard.writeText(seconImage);
    NotificationManager.success("copied");
  };
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {!seconImage ? (
        <button
          className="btn btn-icon btn-outline-primary"
          onClick={() => saveAndGedEmbedingLink(file)}
        >
          <i className="far fa-save"></i>
        </button>
      ) : (
        <button className="btn btn-icon btn-outline-primary" onClick={copy}>
          <i className="far fa-copy" />
        </button>
      )}
    </li>
  ));
  const saveAndGedEmbedingLink = (file) => {
    const data = new FormData();
    data.append("image", file);
    BlogService.importImage(data).then((res) => {
      setSeconImage(window.IMAGE_API_URL + "blogs/" + res.data.filename);
    });
  };

  useEffect(() => {
    BlogService.allCategoryBlogs()
      .then((res) => {
        setCatBlogs(res.data.results);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        NotificationManager.error(resMessage);
      });
  }, []);
  // const html = convertToHTML({
  //   styleToHTML: (style) => {
  //     if (style === "BOLD") {
  //       return <span style={{ fontWeight: "bold" }} />;
  //     }
  //     if (style === "ITALIC") {
  //       return <span style={{ fontStyle: "italic" }} />;
  //     }
  //   },
  //   blockToHTML: (block) => {
  //     const type = block.type;
  //     if (type === "PARAGRAPH") {
  //       return <p />;
  //     }
  //     if (type === "code-block") {
  //       return {
  //         start: "<pre>",
  //         end: "</pre>",
  //       };
  //     }
  //   },
  //   entityToHTML: (entity, originalText) => {
  //     if (entity.type === "LINK") {
  //       return <a href={entity.data.url}>{originalText}</a>;
  //     }
  //     if (entity.type === "IMAGE") {
  //       console.log(entity);
  //       let url = entity.data.src;
  //       return {
  //         start: '<img width="800px" src=\'' + url + "'",
  //         end: "</img>",
  //       };
  //     }
  //     if (entity.type === "code-block") {
  //       return {
  //         start: "<pre>",
  //         end: "</pre>",
  //       };
  //     }
  //     return {
  //       start: "<pre>" + originalText,
  //       end: "</pre>",
  //     };
  //   },
  // })(editorState.getCurrentContent());
  const convertContentToHTML = async () => {
    setLoading(true);
    let currentContentAsHTML = convertToHTML({
      styleToHTML: (style) => {
        if (style === "BOLD") {
          return <span style={{ fontWeight: "bold" }} />;
        }
        if (style === "ITALIC") {
          return <span style={{ fontStyle: "italic" }} />;
        }
      },
      blockToHTML: (block) => {
        const type = block.type;
        if (type === "PARAGRAPH") {
          return <p />;
        }
        if (type === "code-block") {
          return {
            start: "<pre>",
            end: "</pre>",
          };
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          return <a href={entity.data.url}>{originalText}</a>;
        }
        if (entity.type === "IMAGE") {
          // console.log(entity);
          let url = entity.data.src;
          return {
            start: '<img width="800px" src=\'' + url + "'",
            end: "</img>",
          };
        }
        if (entity.type === "code-block") {
          return {
            start: "<pre>",
            end: "</pre>",
          };
        }
        return {
          start: "<pre>" + originalText,
          end: "</pre>",
        };
      },
    })(editorState.getCurrentContent());

    await setConvertedContent(currentContentAsHTML);
    setLoading(false);
  };
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  const imagePlugin = createImagePlugin();
  useEffect(() => {}, [editorState]);
  const handelSubmit = () => {
    const redId = sessionStorage.getItem("id_user");
    // console.log(redId + " kkkk");
    if (
      redId &&
      titre &&
      description &&
      id_categorieBlg &&
      convertedContent &&
      image
    ) {
      const data = new FormData();
      data.append("titre", titre);
      data.append("description", description);
      data.append("id_categorieBlg", id_categorieBlg);
      data.append("contenue", convertedContent);
      data.append("redacteur", redId);
      data.append("image", image);

      BlogService.addBlog(data)
        .then((res) => {
          Swal.fire("blog est ajouté");
          setEditorState(() => EditorState.createEmpty());
          setTitre("");
          setImage("");
          setId_categorieBlg("");
        })
        .catch((err) => NotificationManager.error(`${err}`));
    } else {
      NotificationManager.warning("Veuillez remplir toutes les champs");
    }
  };

  const classes = useStyles();
  return (
    <>
      <WichPage pageTitle="Édition de blogs" pageLocation="blogs/edtionBlog" />
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Édition de blog</h5>
            </div>
            <div className="card-body">
              <form className={classes.root} noValidate autoComplete="off">
                <div className="row">
                  <div className="col-sm-6">
                    <TextField
                      id="title"
                      label="Titre du blog"
                      variant="outlined"
                      className="w-100"
                      multiline
                      maxRows={4}
                      value={titre}
                      onChange={(e) => setTitre(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      id="autName"
                      label="Nom de l'auteur"
                      disabled
                      className="w-100"
                      variant="outlined"
                      multiline
                      maxRows={4}
                      value={sessionStorage.getItem("user")}
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      id="desc"
                      label="Description"
                      className="w-100"
                      variant="outlined"
                      multiline
                      maxRows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 p-3">
                    <div className="form-group">
                      <label htmlFor="Category">Catégorie</label>
                      <select
                        className="form-control"
                        id="Category"
                        value={id_categorieBlg}
                        onChange={(e) => setId_categorieBlg(e.target.value)}
                      >
                        <option value />
                        {catBlogs.map((cat, i) => (
                          <option key={i} value={cat.id}>
                            {cat.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6 p-3">
                    <div className="form-group fill">
                      <label htmlFor="image">Image principale du blog</label>
                      <input
                        type="file"
                        className="form-control"
                        id="image"
                        onChange={(e) => {
                          // console.log(e.target.files);
                          setImage(e.target.files[0] ? e.target.files[0] : "");
                        }}
                      />
                      {/* <div
                        {...getRootProps({ className: "dropzone" })}
                        style={{
                          border: "1px dashed black",
                          borderRadius: "10px",
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <input {...getInputProps()} />
                        <p className="mt-2">
                          Faites glisser et déposez les fichiers ici, ou appuyez
                          pour sélectionner des fichiers
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </form>
              <div className="p-5 text-center">
                <div
                  {...getRootProps({ className: "dropzone" })}
                  style={{ border: "1px dashed black", borderRadius: "10px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <input {...getInputProps()} />
                  <p className="mt-2">
                    Faites glisser et déposez les fichiers ici, ou appuyez pour
                    sélectionner des fichiers
                  </p>
                </div>
                <aside className="mt-4">
                  <ul style={{ listStyle: "none" }}>{files}</ul>
                  <h5 onClick={copy}> {seconImage}</h5>
                </aside>
              </div>
              <div
                style={{
                  border: "1px solid black",
                  padding: "2px",
                  minHeight: "400px",
                }}
              >
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  toolbarClassName="toolbar-class"
                  plugins={[imagePlugin]}
                />
              </div>

              <div className="m-4">
                <button
                  className="btn btn-warning"
                  onClick={() => setPreview(!preview)}
                >
                  <i className="fas fa-eye"></i>
                </button>
                {preview && (
                  <div>
                    {loading ? (
                      <ClipLoader />
                    ) : (
                      <div>
                        <h1>{titre}</h1>
                        {image && (
                          <img
                            width="100%"
                            src={URL.createObjectURL(image)}
                            alt=" "
                          />
                        )}
                        <div
                          className="m-5"
                          dangerouslySetInnerHTML={createMarkup(
                            convertedContent
                          )}
                        ></div>
                      </div>
                    )}{" "}
                  </div>
                )}
              </div>
              <div className="text-center m-5">
                <button className="btn btn-primary" onClick={handelSubmit}>
                  sauvegarder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogEditor;
