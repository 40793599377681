import React, { useEffect, useState } from "react";
import CategoryService from "../../../services/category.service";
import ProductService from "../../../services/product.service";
import MarqueService from "../../../services/marque.service";
import SubCategoryService from "../../../services/subcategory.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
import { useParams, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';

import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import FamillyService from "../../../services/familles.service";
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
import { ClipLoader } from "react-spinners";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import axios from "axios";
import AuthService from "../../../services/auth.service";
import ColorSizeArea from './ColorSizeArea'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));


const ModifierProduit = () => {
    const history = useHistory();
    const [product, setProduct] = useState({});
    const [images, setImages] = useState([]);
    const [catalogue, setCatalogue] = useState();
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [familly, setFamilly] = useState('');
    const [famillies, setFamilles] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [caracteristique, setCaracteristique] = useState('');
    const [tarif, setTarif] = useState(0);
    const [marque, setMarque] = useState('');
    const [marques, setMarques] = useState([]);
    const [quantite, setQuantite] = useState(0)
    const [mention_speciale, setMention_special] = useState('')
    const [info_supp, setInfo_supp] = useState('')
    // const [nouveau_arrivage, setNouveau_arrivage] = useState('')
    const [reference, setReference] = useState('')
    const [designation, setDesignation] = useState('')
    const [largeur, setLargeur] = useState(0);
    const [longueur, setLongueur] = useState(0);
    const [hauteur, setHauteur] = useState(0);
    const [surface, setSurface] = useState(0);
    const [volume, setVolume] = useState(0);
    const [taille, setTaille] = useState(0)
    const [tva, setTva] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null);
    const classes = useStyles();
    const [disabled, setDisabled] = useState(false)
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      maxFiles : 5,
      draggedFiles : images,
      onDropAccepted : (files, e) => {
        files = files.filter((item) => {
          if (isImage(item.name)) {
              // setImages(files.slice(0, 5))
              return true;
          }
          else {
              NotificationManager.error(
                  "L'un des fichiers n'est pas une image",
                  "",
                  2000
              );
          }
          return false
      })
      setImages((old) => old.concat(files).slice(0, 5));
      }
    });
    const [loading, setLoading] = useState(false)
    const [color, setColor] = useState("#00000");
    const [imagesNames, setImagesNames] = useState('')
    const [imagesList, setImagesList] = useState([])
    const [ColorSizeDialog, setColorSizeDialog] = useState(false);
    const [ColorSize, setColorSize] = useState([])
    const [NewColorSize, setNewColorSize] = useState(
      {
        color : '',
        colorCode : '#080000',
        size : '',
        quantity : 0
      }
    );

    useEffect(() => 
    setQuantite(ColorSize.reduce((prev, val) => prev + parseInt(val.quantity), 0))
  , [ColorSize])

    const params = useParams();

    useEffect(() => {
        setLoading(true)


        CategoryService.allCategories().then((res) => {
            setCategories(res.data.results);
        });
        MarqueService.allMarques().then((res) => {
            setMarques(res.data.results)
        })

        ProductService.getProductById(params.id)
            .then(async (res) => {
                const data = res.data.result[0]
                console.log(data)
                setName(data.name)
                setDescription(data.description)
                setCaracteristique(data.caracteristique)
                setTarif(data.tarif)
                setQuantite(data.quantite)
                setMention_special(data.mention_spéciale)
                setInfo_supp(data.infos_supp)
                setReference(data.reference)
                setDesignation(data.designation)
                setLargeur(data.largeur)
                setLongueur(data.longueur)
                setHauteur(data.hauteur)
                setSurface(data.surface)
                setVolume(data.volume)
                setTaille(data.taille)
                setCategory(data.category_id)
                setSubCategory(data.subcat_id)
                setFamilly(data.family_id)
                setMarque(data.marque_id)
                setTaille(data.taille)
                setImagesNames(data.image)
                setImagesList(data.image.substring(0, data.image.length - 1).split(';'))
                setCatalogue(data.Catalogue)
                setTva(data.tva)
                setLoading(false)
                setColorSize(JSON.parse(data.ColorSize) || []);
            })

    }, []);

    useEffect(() => {
        SubCategoryService.getSybCateoiesByCategoryId(category)
            .then((res) => {
                setSubCategories(res.data.results)
            })
        // console.log(imagesList)
    }, [category])

    useEffect(() => {
        // console.log(subCategory)
        FamillyService.getFamilliesBySubCategoryId(subCategory)
            .then((res) => {
                setFamilles(res.data.results)
            })
    }, [subCategory])

    const [defaultImg, setdefaultImg] = useState(null);
  
    useEffect(() => {
      if (images.length)
        return setdefaultImg(images[0]);
      setdefaultImg(null);
    }, [images]);

    useEffect(() => {
      if (imagesList.length)
        return setdefaultImg(`/images/products/${imagesList[0]}`);
      setdefaultImg(null);
    }, [imagesList]);

    function getExtension(filename) {
        try {
            var parts = filename.split('.');
            return parts[parts.length - 1];
        } catch (error) {
            return ''
        }
    }

    function isImage(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'jpg':
                return true
            case 'jpeg':
                return true
            case 'png':
                return true
            default:
                return false
        }
    }
    // useEffect(() => {
    //     acceptedFiles.map((item) => {
    //         if (isImage(item.name)) {
    //             setImages(acceptedFiles.slice(0, 5))
    //         }
    //         else {
    //             NotificationManager.error(
    //                 "L'un des fichiers n'est pas une image",
    //                 "",
    //                 2000
    //             );
    //         }
    //     })

    // }, [acceptedFiles])

    const downloadFile = (e) => {
        const path = e.target.id
        axios.get(`${window.API_URL}/download/catalogue/${path}`)
    }

    const changeHandler = (event) => {
        if (event.target.files && getExtension(event.target.files[0].name) !== "pdf") {
            NotificationManager.error(
                "Catalogue should be pdf",
                "",
                2000
            );
        } else {
            setSelectedFile(event.target.files[0] ? event.target.files[0] : null);
            setCatalogue(event.target.files[0] ? event.target.files[0].name : "");
        }
    };


    const loadFile = function (img) {

        return URL.createObjectURL(img);

    };

    // const deleteImage = (e) => {
    //     const index = e.target.id
    //     console.log(index);
    //     if (index > -1) {
    //         setImages(images.splice(index, 1))
    //     }

    // }

    const updateProduit = () => {
        setLoading(true)
        // const userId = sessionStorage.getItem("id_user");
        const formData = new FormData();
        formData.append('name', name);
        formData.append('category_id', category);
        formData.append('subcat_id', subCategory);
        formData.append('family_id', familly);
        formData.append('description', description);
        formData.append('reference', reference);
        formData.append('designation', designation);
        formData.append('caracteristique', caracteristique);
        formData.append('tarif', tarif);
        formData.append('marque_id', marque);
        formData.append('quantite', quantite);
        formData.append('mention_spéciale', mention_speciale);
        formData.append('infos_supp', info_supp);
        formData.append('largeur', largeur);
        formData.append('longueur', longueur);
        formData.append('hauteur', hauteur);
        formData.append('surface', surface);
        formData.append('volume', volume);
        formData.append('taille', taille);
        formData.append('Catalogue', selectedFile);
        formData.append('listImages', imagesNames);
        formData.append('cataloguePath', catalogue)
        formData.append('tva', tva)
        formData.append('id', params.id);
        formData.append('ColorSize', JSON.stringify(ColorSize));
        images.map(file => {
            formData.append('image', file);
        })

        const NormalData = {
            name: formData.get('name'),
            category_id: formData.get('category_id'),
            subcat_id: formData.get('subcat_id'),
            family_id: formData.get('family_id'),
            description: formData.get('description'),
            reference: formData.get('reference'),
            designation: formData.get('designation'),
            caracteristique: formData.get('caracteristique'),
            tarif: formData.get('tarif'),
            marque_id: formData.get('marque_id'),
            quantite: formData.get('quantite'),
            mention_spéciale: formData.get('mention_spéciale'),
            infos_supp: formData.get('infos_supp'),
            largeur: formData.get('largeur'),
            longueur: formData.get('longueur'),
            hauteur: formData.get('hauteur'),
            surface: formData.get('surface'),
            volume: formData.get('volume'),
            taille: formData.get('taille'),
            tva: formData.get('tva'),
            id: formData.get('id'),
            ColorSize : formData.get('ColorSize')
        }

        if (name === "" || category === "" || !category || description === "" || reference === "" || designation === "" || tarif < 0 || marque === "" || !marque || quantite < 0) {
            NotificationManager.warning("Please check all the inputs", "", 2000);
            setLoading(false)
        }
        else {

            // console.log(formData.get('Catalogue'))
            // console.log(formData.get('cataloguePath'))
            // console.log(formData.get('listImages'))
            // console.log(formData.get('image'))
            try {
            if (formData.get('image')) {
                setLoading(true)
                ProductService.updateImagesProduct(formData)
                    .then((res) => {
                        NotificationManager.success(
                            "product image updated successfully",
                            "",
                            2000
                        );
                        setLoading(false)
                    })
                    .catch(function (error) {
                        if (!error.response) {
                            NotificationManager.error(
                                "Votre session est expiree, veuillez vous reconnectez",
                                "",
                                4000
                            );
                        }
                        else if (error.response.status === 403) {
                            setLoading(false)
                            error.response.data.error ?
                                NotificationManager.warning(error.response.data.error, "", 2000)
                                :
                                NotificationManager.warning("Could not update product image", "", 2000)

                            // console.log(error.response.data);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                        } else if (error.response.status === 401) {
                            setLoading(false)
                            NotificationManager.error(
                                "Votre session est expiree, veuillez vous reconnectez",
                                "",
                                4000
                            );
                            setTimeout(() => {
                                AuthService.logout()
                            }, 500);
                        }
                    });
            }

            if (formData.get('Catalogue') && formData.get('Catalogue') != "null") {
                setLoading(true)
                ProductService.updateCatalogueProduct(formData)
                    .then((res) => {
                        NotificationManager.success(
                            "product catalogue updated successfully",
                            "",
                            2000
                        );
                        setLoading(false)
                    })
                    .catch(function (error) {
                        if (!error.response) {
                            NotificationManager.error(
                                "Votre session est expiree, veuillez vous reconnectez",
                                "",
                                4000
                            );
                        }
                        else if (error.response.status === 403) {
                            setLoading(false)
                            error.response.data.error ?
                                NotificationManager.warning(error.response.data.error, "", 2000)
                                :
                                NotificationManager.warning("Could not update product catalogue", "", 2000)

                            // console.log(error.response.data);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                        } else if (error.response.status === 401) {
                            setLoading(false)
                            NotificationManager.error(
                                "Votre session est expiree, veuillez vous reconnectez",
                                "",
                                4000
                            );
                            setTimeout(() => {
                                AuthService.logout()
                            }, 500);
                        }
                    });
            }
            ProductService.updateProduct(NormalData)
                .then((res) => {
                    setDisabled(true)
                    NotificationManager.success(
                        "product updated successfully",
                        "",
                        2000
                    );
                    setLoading(false)
                    setTimeout(() => {
                        history?.goBack();
                        // window.location.href = "/produits";
                    }, 500);
                })
                .catch(function (error) {
                    if (!error.response) {
                        NotificationManager.error(
                            "Votre session est expiree, veuillez vous reconnectez",
                            "",
                            4000
                        );
                    }
                    else if (error.response.status === 403) {
                        setLoading(false)
                        error.response.data.error ?
                            NotificationManager.warning(error.response.data.error, "", 2000)
                            :
                            NotificationManager.warning("Could not update product", "", 2000)

                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.response.status === 401) {
                        setLoading(false)
                        NotificationManager.error(
                            "Votre session est expiree, veuillez vous reconnectez",
                            "",
                            4000
                        );
                        setTimeout(() => {
                            // history?.goBack();
                            window.location.href = "/produits";
                            AuthService.logout()
                        }, 500)
                    }
                });
            }catch (e) {
                setLoading(false);
            }
        }
    }





    return (
      <>
        <WichPage
          pageTitle="Ajouter un produit"
          pageLocation="produits/ajouterProduit"
        />
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5>Modifier produit</h5>
                {loading ? <ClipLoader color={color} loading={loading} /> : ""}
              </div>
              <div className="card-body">
                {/* <form ref={form} onSubmit={handelSubmit}> */}
                <div className="row">
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="Name"
                      label="Titre"
                      className="w-100"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    {/* <label>Current : {categoryName}</label> */}
                    <TextField
                      id="Category"
                      className="w-100"
                      select
                      label="Catégorie"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      {categories.map((cat, index) => (
                        <MenuItem key={index} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="subcat"
                      className="w-100"
                      select
                      label="Sous catégorie"
                      value={subCategory}
                      onChange={(e) => setSubCategory(e.target.value)}
                    >
                      {subCategories.map((subCat, index) => (
                        <MenuItem key={index} value={subCat.id}>
                          {subCat.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="family_id"
                      className="w-100"
                      select
                      label="Famille"
                      value={familly}
                      onChange={(e) => setFamilly(e.target.value)}
                    >
                      {famillies.map((fam, index) => (
                        <MenuItem key={index} value={fam.id}>
                          {fam.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="marque_id"
                      className="w-100"
                      select
                      label="Marque"
                      value={marque}
                      onChange={(e) => setMarque(e.target.value)}
                    >
                      {marques.map((marq, index) => (
                        <MenuItem key={index} value={marq.id}>
                          {marq.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="desg"
                      label="Code Produit"
                      className="w-100"
                      multiline
                      maxRows={4}
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="desc"
                      label="Description"
                      className="w-100"
                      multiline
                      maxRows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="infos_supp"
                      label="Info Supp"
                      className="w-100"
                      multiline
                      maxRows={4}
                      value={info_supp}
                      onChange={(e) => setInfo_supp(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="reference"
                      label="Reference"
                      className="w-100"
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="cara"
                      label="Caractéristques"
                      className="w-100"
                      multiline
                      maxRows={4}
                      value={caracteristique}
                      onChange={(e) => setCaracteristique(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="tarif"
                      label="Tarif"
                      className="w-100"
                      type="number"
                      step="0.5"
                      min="0"
                      value={tarif}
                      onChange={(e) => setTarif(e.target.value)}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 0.5,
                        },
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-2 col-sm-12">
                    <TextField
                      id="quantite"
                      label="total Quantité"
                      className="w-100"
                      type="number"
                      step="0.5"
                      min="0"
                      value={quantite}
                      onChange={(e) => setQuantite(e.target.value)}
                      disabled
                    />
                  </div>
                  <div className="col-md-2 my-2 col-sm-6">
                    <TextField
                      label="Largeur"
                      id="largeur"
                      className="w-100"
                      type="number"
                      step="0.50"
                      min="0"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end" className="mr-2">
                            cm
                          </InputAdornment>
                        ),
                        inputProps: {
                          max: 10000,
                          min: 0,
                          step: 0.5,
                        },
                      }}
                      value={largeur}
                      onChange={(e) => setLargeur(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 my-2 col-sm-6">
                    <TextField
                      label="Longueur"
                      id="longueur"
                      className="w-100"
                      type="number"
                      step="0.50"
                      min="0"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end" className="mr-2">
                            cm
                          </InputAdornment>
                        ),
                      }}
                      value={longueur}
                      onChange={(e) => setLongueur(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 my-2 col-sm-6">
                    <TextField
                      label="Hauteur"
                      id="hauteur"
                      className="w-100"
                      type="number"
                      step="0.50"
                      min="0"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end" className="mr-2">
                            cm
                          </InputAdornment>
                        ),
                        inputProps: {
                          max: 10000,
                          min: 0,
                          step: 0.5,
                        },
                      }}
                      value={hauteur}
                      onChange={(e) => setHauteur(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 my-2 col-sm-6">
                    <TextField
                      label="Surface"
                      id="surface"
                      className="w-100"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="end"
                            className="mr-2 text-muted"
                          >
                            Cm<sup>2</sup>
                          </InputAdornment>
                        ),
                        inputProps: {
                          max: 10000,
                          min: 0,
                          step: 0.5,
                        },
                      }}
                      value={surface}
                      onChange={(e) => setSurface(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 my-2 col-sm-6">
                    <TextField
                      label="volume"
                      id="volume"
                      className="w-100"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="end"
                            className="mr-2 text-muted"
                          >
                            Cm<sup>3</sup> (mL)
                          </InputAdornment>
                        ),
                        inputProps: {
                          max: 10000,
                          min: 0,
                          step: 0.5,
                        },
                      }}
                      value={surface}
                      onChange={(e) => setSurface(e.target.value)}
                    />
                  </div>
                  {/* <div className="col-md-2 my-2 col-sm-6">
                                    <TextField
                                        id="taille"
                                        className="w-100"
                                        select
                                        label="Taille"
                                        value={taille}
                                        onChange={(e) => (setTaille(e.target.value))}

                                    >
                                        <MenuItem key="XS" value='XS'>XS</MenuItem>
                                        <MenuItem key="S" value='S'>S</MenuItem>
                                        <MenuItem key="M" value='M'>M</MenuItem>
                                        <MenuItem key="L" value='L'>L</MenuItem>
                                        <MenuItem key="XL" value='XL'>XL</MenuItem>
                                        <MenuItem key="XXL" value='XXL'>XXL</MenuItem>
                                        <MenuItem key="XXXL" value='XXXL'>XXXL</MenuItem>
                                    </TextField>
                                </div> */}
                  {/* <div className="col-md-6 col-sm-12">
                    <TextField
                      id="infos_supp"
                      label="Taille : S,M ,XL ,XXL ,XXXL"
                      className="w-100"
                      value={taille}
                      onChange={(e) => setTaille(e.target.value)}
                    ></TextField>
                  </div> */}
                  <div className="col-md-3 mb-2 col-sm-12 mb-1">
                    {/* <TextField
                      id="Mention_Special"
                      label="Mention Special"
                      className="w-100"
                      value={mention_speciale}
                      onChange={(e) => setMention_special(e.target.value)}
                    /> */}
                  </div>
                  <div className="col-md-3 mb-2 col-sm-12 mb-1">
                    <TextField
                      id="tva"
                      label="TVA"
                      type="number"
                      // min="0"
                      className="w-100"
                      value={tva}
                      onChange={(e) => setTva(e.target.value)}
                      InputProps={{
                        inputProps: {
                          max: 100,
                          min: 0,
                          step: 0.5,
                        },
                      }}
                    />
                  </div>
                  <ColorSizeArea 
                    ColorSize={ColorSize}
                    dialogOpen={ColorSizeDialog}
                    handleCloseDialog={() => setColorSizeDialog(false)}
                    handleClickOpenDialog={() => setColorSizeDialog(true)}
                    CreateNew={() => {
                      NewColorSize.index === undefined ?
                      setColorSize(old => old.concat([NewColorSize])) :
                      setColorSize(old => old.map((val, ind) => ind === NewColorSize.index ? {...NewColorSize, index : undefined} : val));
                      setNewColorSize(
                        {
                          color : '',
                          colorCode : '#080000',
                          size : '',
                          quantity : 0
                        }
                      )
                    }}
                    NewColorSize={NewColorSize}
                    setNewColorSize={setNewColorSize}
                    handleClickDelete={(index) => setColorSize(old => old.filter((val, ind) => ind !== index))}
                    handleClickUpdate={(val, index) => {
                      setNewColorSize({...val, index})
                      setColorSizeDialog(true)
                    }}
                    defaultImg={defaultImg}
                  />
                  <div className="col-md-6 mb-2 col-sm-12 mb-1">
                    {catalogue ? (
                      <>
                        <label className="">Telecharger Catalogue :</label>
                        <button
                          className="btn btn-primary btn-sm ml-4"
                          id={catalogue}
                          onClick={downloadFile}
                        >
                          Telecharger
                        </button>
                      </>
                    ) : null}
                  </div>
                  <div class="col-md-6 mb-2 col-sm-12 mb-1">
                    <div class="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="catalogue"
                        name="catalogue"
                        onChange={changeHandler}
                      />
                      <label class="custom-file-label" for="catalogue">
                        Catalogue (PDF)
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 mb-2 col-sm-12">
                    <h6>
                      <span>NB:</span> l'image principale est la premiere (5
                      images max) (accecpted type : jpg, jpeg and png)
                    </h6>

                    <section className="container">
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        style={{
                          border: "1px dashed black",
                          borderRadius: "10px",
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <input {...getInputProps()} />
                        <p className="mt-2">
                          Drag and drop files here, or click to select files
                        </p>
                      </div>
                      <aside className="my-2">
                        <h4>Fichies</h4>
                        {/* <ul>{files}</ul> */}
                        <div className={classes.root}>
                          <ImageList className={classes.imageList} cols={2.5}>
                            {images.length > 0
                              ? images.map((item, index) => (
                                  <ImageListItem key={item.img}>
                                    <img src={loadFile(item)} alt={item.name} />
                                    <ImageListItemBar
                                      title={item.name}
                                      classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                      }}
                                      // actionIcon={
                                      //   <IconButton aria-label={`star ${item.name}`} id={index} onClick={deleteImage} >
                                      //     <DeleteIcon className={classes.title} />
                                      //   </IconButton>
                                      // }
                                    />
                                  </ImageListItem>
                                ))
                              : imagesList.map((item, index) => (
                                  <ImageListItem key={item.img}>
                                    <img
                                      src={
                                        window.IMAGE_API_URL +
                                        "products/" +
                                        item
                                      }
                                      alt={item}
                                    />
                                    <ImageListItemBar
                                      title={item}
                                      classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                      }}
                                      // actionIcon={
                                      //     <IconButton aria-label={`star ${item}`} id={index} onClick={deleteImage} >
                                      //         <DeleteIcon className={classes.title} />
                                      //     </IconButton>
                                      // }
                                    />
                                  </ImageListItem>
                                ))}
                          </ImageList>
                        </div>
                      </aside>
                    </section>
                  </div>
                </div>
                <div className="col-sm-12 text-right m-6">
                  {!loading ? (
                    <button
                      type="button"
                      className="btn btn-icon btn-outline-primary"
                      onClick={updateProduit}
                      disabled={disabled}
                    >
                      <i className="feather icon-save" />
                    </button>
                  ) : (
                    <ClipLoader color={color} loading={loading} />
                  )}
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default ModifierProduit;
