import { useRef } from "react";
import { NotificationManager } from "react-notifications";
import AdminService from "../../../services/admin.service";
import AuthService from "../../../services/auth.service";
const PasswordUpdate = () => {
  const form = useRef(null)
  const fdToJson = (fd) => {
    let obj = {};
    for (let key of fd.keys()) {
      obj[key] = fd.get(key);
    }
    return obj;
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const data = fdToJson(new FormData(form.current))
    AdminService.updatePassword(data).then(
      (res) => NotificationManager.success('Vôtre mot de passe a été bien modifié')
    )
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
              "Impossible de charger les informations d'admin",
              "",
              2000
            );

        } else if (error.response.status === 401) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="mb-0">Modifier le mot de passe</h5>
      </div>
      <div className="card-body">
        <form ref={form} className="form" onSubmit={handelSubmit}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Ancien
            </label>
            <div className="col-sm-9">
              <input
                type="password"
                name="oldPassword"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Nouveaux
            </label>
            <div className="col-sm-9">
              <input type="password" name="password" className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label font-weight-bolder">
              Confirmer le nouveaux mot de passe
            </label>
            <div className="col-sm-9">
              <input
                type="password"
                name="passwordConfirm"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" />
            <div className="col-sm-9 text-right">
              <button
                type="submit"
                className="btn btn-icon btn-outline-primary"
              >
                <i className="feather icon-save" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordUpdate;
