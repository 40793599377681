import React, { useEffect, useState } from "react";

import DashboardService from "../../../services/dashboard.service";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";

const SubscibersCard = () => {

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [subscribes, setSubscribes] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    setLoading(true)
    // Subscribers Number
    if (role === "Admin" || role === "Moderateur") {
      DashboardService.getSubscribersNumber().then((res) => {
        setSubscribers(res.data.results[0].number);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });

      //Lasst Subscribers
      DashboardService.getLastSubscrivers().then((res) => {
        setSubscribes(res.data.results);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer la liste des abonnees", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });

    }


  }, []);


  return (
    <>
      {
        role === "Admin" || role === "Moderateur" ?
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5 className="mb-0">Nouveaux abonnés</h5>
              <span className="badge badge-light-primary float-right">+{subscribers}</span>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover m-0">
                  <thead>
                    <tr>
                      <th className="text-center">Emails</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      subscribes.map((subscriber, index) => (
                        <tr key={index}>
                          <td className="text-center">{subscriber.email}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> : null
      }

    </>
  )
};

export default SubscibersCard;
