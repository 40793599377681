import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    getOrders,
    UpdateOrder
} from "../../../services/orders";
import WichPage from "../../inc/helpfulComponents/WichPage";
import Moment from "react-moment";
import NotificationManager from "react-notifications/lib/NotificationManager";
import "react-notifications/lib/notifications.css";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";

export default function ListOrders(props) {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [length, setlength] = useState(0);
  const [perPage, setperPage] = useState(10);

  const renderActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <Link
          to={`/details/order/${row.order_id}`}
          className="btn btn-icon btn-outline-success mx-3 my-2"
        >
          <i className="fas fa-eye" id={row.order_id}></i>
        </Link>
        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          id={row.order_id}
          onClick={deleteDemande}
        >
          <i className="far fa-trash-alt" id={row.order_id} />
        </button>
      </div>
    );
  };

  const renderDate = (row) => {
    return (
      <div className="d-flex w-100 align-items-center justify-content-center">
        <Moment format="DD/MM/YYYY" className="text-center">
          {row.created_at}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{row.created_at}</Moment> )
        </div>
      </div>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
        selector: "order_id",
        name: "N°ID"
    },
    {
      selector: "client_name",
      name: "Nom du client",
    },
    {
      selector: "status",
      name: "Status",
    },
    {
      selector: "created_at",
      name: "Date de commande",
      width: "300px",
      cell: (row) => renderDate(row),
    },

    {
      name: "Action",
      width: "200px",

      sortable: false,
      cell: (row) => renderActions(row),
    },
  ]);

  const tableData = {
    columns,
    data: data,
  };

  const LoadOrders = (pageId, npage) => {
    setLoading(true);
    let status = '';// New,Validated,Delivered,Canceled
    switch (props.match.params.status) {
        case ('pending') :
            status='New,Validated';break;
        case ('delivered') :
            status='Delivered';break;
        case ('cancelled') :
            status='Canceled';break;
    }
    // pending,delivered,cancelled
    getOrders({
        pageId, npage, status
      })
        .then((res) => {
        setdata(res.data.data);
        setlength(res.data.length);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403 || error.response.status === 400) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Impossible de charger les devis",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }

  useEffect(() => LoadOrders(0, perPage), []);

  const deleteDemande = (event) => {
    if (props.match.params.status === 'cancelled')
        return swalWithBootstrapButtons.fire(
        "Order already cancelled !!",
        "",
        "info"
        );
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure ?",
        text: "Once you delete this order, it will be listed on Cancelled orders page !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete it",
        cancelButtonText: "No, Cancel",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          UpdateOrder({
              order_id : event.target.id,
              status : 'Canceled'
          }).then((res) => {
            setdata((old) => old.filter((item) => item.order_id != event.target.id))  
            swalWithBootstrapButtons.fire(
              "Deleted!",
              "Data deleted successfully !!.",
              "success"
            );
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403 || error.response.status === 400) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Impossible de supprimer order",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  const handlePageChange = page => LoadOrders(page - 1, perPage)

	const handlePerRowsChange = (newPerPage, page) => (LoadOrders(page - 1, newPerPage),setperPage(newPerPage))

  return (
    <>
      <WichPage
        pageTitle="List orders"
        pageLocation="orders"
      />
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>List orders</h5>
            </div>
            <div className="card-body">
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="order_id"
                    defaultSortAsc={false}
                    highlightOnHover
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={length}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                </DataTableExtensions>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};