import React, { useEffect, useState } from "react";
import WichPage from "../../inc/helpfulComponents/WichPage";
import Moment from "react-moment";
import Swal from "sweetalert2";
import Products from "../../../services/product.service";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { NotificationManager } from "react-notifications";

const PlusEtonnants = () => {
    const history = useHistory();
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [products, setproducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const renderProductImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + "products/" + row.image.split(";")[0]}
        alt={row.image.split(";")[0]}
        width={150}
      />
    );
  };

  const renderProductDate = (row) => {
    return (
      <div className="d-flex w-100 align-items-center justify-content-start">
        <Moment format="D MMM YYYY" withTitle>
          {row.created_at}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{row.created_at}</Moment> )
        </div>
      </div>
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "id", width: "70px" },
    {
      selector: "image",
      name: "Image",
      width: "180px",
      cellExport : (row) => row.images,
      cell: (row) => renderProductImage(row),
    },
    {
      selector: "name",
      name: "Titre",
      width: "180px",
    },
    {
      selector: "created_at",
      name: "Date d'ajout",
      type: "date",
      width: "250px",
      cell: (row) => renderProductDate(row),
    },
    {
    // selector: "count",
    name: "Plus etonnant",
    // type: 'number',
    width: "200px",
    cell : (row) => 
    <Button variant="outlined"
    style={{fontSize : 12, fontWeight : 'bold'}}
    onClick={(e) => UpdatePlusEtonnant(row, '0')}
    >{
        'remove'
    }</Button>
    }
  ]);

  const UpdatePlusEtonnant = async (row, plus_etonnants) => {
    Products.updateProductPLusett({id : row.id, plus_etonnants})
    .then((result) => {
      setproducts((old) => old.filter(val => val.id !== row.id))
      NotificationManager.success(
        "Product updated successfully",
        "",
        4000
      );
    })
    .catch(function (error) {
      NotificationManager.error(
        error?.response?.data?.erreur || error?.response?.data?.error || error?.response?.data?.message || error?.message,
        "",
        4000
      );
    });
  }

  const tableData = {
    columns,
    data: products,
  };

  useEffect(() => {
    setLoading(true);
    Products.allProductEtonants().then(async (res) => {
        // console.log(res.data);
      setproducts(res.data.results);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <WichPage
        pageTitle="Plus etonnants products"
        pageLocation="etonnant-products"
      />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    onRowClicked={(row) => history.push("/DetailsProduit/" + row.id)}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlusEtonnants;
