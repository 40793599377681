import Moment from "react-moment";
import React, { useEffect, useState } from "react";
import ContactService from "../../../services/contact.service";
import useDataTableScripts from "../../inc/helpfulComponents/useDataTableScripts";
import WichPage from "../../inc/helpfulComponents/WichPage";
import dataTableScripts from "../../inc/scripts/dataTableScripts";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { ClipLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import AuthService from "../../../services/auth.service";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";



const Contacts = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState({})
  const [loading, setLoading] = useState(false)

  const [columns, setColumns] = useState([
    
    {
        name: "Numéro de contact",
        selector: "id",
        sortable: true,
        width: "70px",
      },
      {
        name: "Client",
        selector: "first_name",
        sortable: true,
        width: "100px",
        wrap: true
      },
      {
        name: "Telephone",
        selector: "phone",
        sortable: true,
        width: "100px",
        wrap: true
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        width: "180px",
      },
      {
        name: "Sujet",
        selector: "subject",
        sortable: true,
        width: "150px",
        wrap: true
      },
      {
        name: "Message",
        selector: "message",
        sortable: true,
        width: "200px",
        wrap: true
      },
      {
        name: "Date",
        selector: "created_at",
        sortable: true,
        width: "230px",
        cell: (d) => <div className="d-flex w-100 align-items-center justify-content-start">
                  <Moment format="DD/MM/YYYY" className="text-center">
                      {d.created_at}
                  </Moment>
                  <div className="text-muted mx-3">
                      ( <Moment fromNow>{d.created_at}</Moment> )
                  </div>
              </div>
      },
      {
        name: "Actions",
        selector: "",
        sortable: true,
        width: "180px",
        cell: row =>
          <div data-tag="allowRowEvents" className="d-flex align-items-center justify-content-arround w-100">
            {/* <div className="d-flex align-items-center justify-content-arround w-100"> */}
              <button
                data-toggle="modal"
                data-target="#Contact-details"
                className="btn btn-icon btn-outline-primary btn-sm my-2 mx-3"
                onClick={() => {
                    getContactById(row.id)
                }}
              >
                <i className="feather icon-eye" id={row.id}></i>
              </button>
              <button
            type="button"
            className="btn btn-icon btn-outline-danger mx-3 my-2"
            id={row.id}
            onClick={deleteContact}
          >
            <i
              className="far fa-trash-alt"
              id={row.id}
            ></i>
          </button>
            {/* </div> */}
          </div>,
      },

  ]);
  const tableData = {
    columns,
    data: contacts
  };
 
  useEffect(() => {
    setLoading(true)
    ContactService.getContacts().then((res) => {
      setContacts(res.data.results);
      setLoading(false)

    }) .catch(function (error) {
      if (!error.response) {
        NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
      }
      else if (error.response.status === 403) {
        setLoading(false)
        error.response.data.error || error.response.data.message ?
          NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
          :
          NotificationManager.warning("Nous N'avons pas pus recharger la liste des contacts", "", 2000)

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.response.status === 401) {
        setLoading(false)
        NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
        setTimeout(() => {
          AuthService.logout()
        }, 500);
      }
    });
  }, []);

  
  const getContactById = (id) => {
    ContactService.getContactById(id)
      .then(res => {
        console.log(res.data.result[0])
        setContact(res.data.result[0])
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        }
        else if (error.response.status === 403) {
          setLoading(false)
          error.response.data.error || error.response.data.message ?
            NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
            :
            NotificationManager.warning("Nous N'avons pas pus recharger la liste des contacts", "", 2000)

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false)
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout()
          }, 500);
        }
      });
  }

  const deleteContact= (event) => {
    const token = sessionStorage.getItem("token");
    const contactId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes Vous sur?",
        text: "Vous ne pourrez plus voir cet abonne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez!",
        cancelButtonText: "Non, annullez!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
            ContactService.deleteContact(contactId, token)
            .then((result) => {
              setTimeout(() => {
                ContactService.getContacts().then(async (res) => {
                  setContacts(res.data.results);
                  setLoading(false);
                });
              }, 300);

              swalWithBootstrapButtons.fire(
                "Supprime!",
                "Le contact est supprime",
                "success"
              );
            })
            .catch(function (error) {
              if (!error.response) {
                swalWithBootstrapButtons.fire(
                    "Annuller",
                    "Votre session est expiree, veuillez vous reconnectez",
                    "erreur"
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? swalWithBootstrapButtons.fire(
                      "Annuller",
                      error.response.data.error,
                      "error"
                    )
                  : swalWithBootstrapButtons.fire(
                      "Annuller",
                      "Nous n'avons pas pus supprimmer le contact",
                      "error"
                    );
              } else if (error.response.status === 401) {
                setLoading(false);
                swalWithBootstrapButtons.fire(
                    "Annuller",
                    "Votre session est expiree, veuillez vous reconnectez",
                    "erreur"
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annuller",
            "Votre contact est encore la",
            "erreur"
          );
        }
      });
  };


  return (
    <>
      <WichPage pageTitele="Abonnés" pageLocation="abonnes" />
      <div className="row">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5>Les messages des clients</h5>
            </div>

            <div className="card-body">
            {!loading ?
                <div className="myaccount-content">
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      columns={columns}
                      data={contacts}
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                      selectableRows = {true}
                    />
                  </DataTableExtensions>

                </div>
                :
                <ClipLoader color={"#000000"} loading={loading} />}
                
            </div>
          </div>
        </div>
         {/*  START Answer contact MODAL */}

      <div
        className="modal fade"
        id="Contact-details"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> Message </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="my-3">
                    <label>Client :  {contact.first_name} </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="my-3">
                    <label>Email :  {contact.email}</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="my-3">
                    <label>Telephone :  {contact.phone}</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="my-3">
                    <label>Subject :  {contact.subject}</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="my-3">
                    <label>Message :  {contact.message}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  END Answer contact MODAL */}
      </div>
    </>
  );
};

export default Contacts;
