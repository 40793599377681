import React from 'react'
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import TextField from "@material-ui/core/TextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

export default function ColorSizeArea({
    ColorSize,
    dialogOpen,
    handleCloseDialog,
    handleClickOpenDialog,
    handleClickDelete,
    handleClickUpdate,
    CreateNew,
    NewColorSize,
    setNewColorSize,
    defaultImg=null
  }) {
    const [fileError, setfileError] = React.useState(null);

    const getBase64 = (file, cb) => {
      try {
        if (file.length) {
          let reader = new FileReader();
          reader.readAsDataURL(file[0]);
          reader.onload = () => {
            cb(reader.result);
          };
          reader.onerror = function (error) {
          };
        }
      } catch (e) {
        return e?.message || "something went wrong"
      }
    };

    const SaveFile = (e) => {
      setfileError('');
      if (!e?.target?.files?.length)
        setfileError('no file found.');
      else if (!/^image\/(jpg)|(jpeg)|(png)$/.test(e?.target?.files[0].type))
        setfileError('only images type jpg, jpeg, png are accepted !!.');
      else if (e?.target?.files[0].size > 16500000) // 10MB 
        setfileError('maximum images size is 10MB !!.');
      else
        getBase64(e.target.files, (result) => {
          setNewColorSize((old) => {
            return {...old, image : result};
          })
        })
    }

    React.useEffect(() => {
      if (defaultImg) {
        console.log(defaultImg);
        typeof(defaultImg) === 'object' ?
        getBase64([defaultImg], (result) => {
          setNewColorSize((old) => ({...old, image : result}))
        }) :
        setNewColorSize((old) => ({...old, image : defaultImg}))
      }
    }, [defaultImg]);

    return (
      <div className="col-sm-12 px-5 py-2 col-md-6" style={{padding : 0, overflow : 'auto'}}>
          <div className="head">
          <span style={{ color: "rgba(0, 0, 0, 0.6)" }} className="mb-2">
              {'Color & Size and Quantity'}
          </span>
          <IconButton
              aria-label="addColor"
              className="mx-3"
              onClick={handleClickOpenDialog}
          >
              <AddCircleOutlineRoundedIcon />
          </IconButton>
          </div>
          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"md"}
          >
          <DialogTitle id="alert-dialog-title">
              {"Add Color & Size"}
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
              <div className="row m-3 w-100">
                <div className="col-12 px-5 py-2">
                  <label for="exampleColorInput" className="form-label">
                    Color Image:
                  </label>
                  <input type={'file'} onChange={SaveFile} placeholder={'Select image'}/>
                  <p style={{fontSize : 13, color : 'red', fontWeight : 'bold'}}>{fileError}</p>
                  {NewColorSize?.image ? <img style={{width : 200, height : 200}}
                  src={!/^data/.test(NewColorSize?.image) ? `${window.API_URL}${NewColorSize?.image}` : `${NewColorSize?.image}`}
                  alt={'color image'}/> : <></>}
                </div>
                <div className="col-12 px-5 py-2">
                  <TextField
                    className="w-100"
                    id="standard-basic"
                    label="Color Name"
                    placeholder={'black, yellow, ...'}
                    variant="standard"
                    value={NewColorSize.color}
                    onChange={(e) => setNewColorSize({...NewColorSize, color : e.target.value})}
                  />
                </div>
                <div className="col-12 px-5 py-2 d-flex align-items-center">
                  <label for="exampleColorInput" className="form-label">
                    Color Code:
                  </label>
                  <input
                    type="color"
                    // className="form-control mx-5 form-control-color"
                    id="exampleColorInput"
                    value={NewColorSize.colorCode}
                    onChange={(e) => setNewColorSize({...NewColorSize, colorCode : e.target.value})}
                    title="Choose your color"
                  />
                </div>
                <div className="col-12 px-5 py-2">
                  <TextField
                    className="w-100"
                    id="standard-basic"
                    label="Size Name"
                    variant="standard"
                    placeholder={'XS,S,M,L,XL,XXL,...'}
                    value={NewColorSize.size}
                    onChange={(e) => setNewColorSize({...NewColorSize, size : e.target.value})}
  
                  />
                </div>
                <div className="col-12 px-5 py-2">
                  <TextField
                    className="w-100"
                    id="standard-basic"
                    label="Quantity Name"
                    type={'number'}
                    variant="standard"
                    value={NewColorSize.quantity}
                    onChange={(e) => setNewColorSize({...NewColorSize, quantity : e.target.value})}
                  />
                </div>
              </div>
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={() => {
                CreateNew();
                handleCloseDialog()
              }} autoFocus>
              Save
              </Button>
          </DialogActions>
          </Dialog>
          {/* End location dialog */}
          <table className={'table'}>
            <thead>
                <tr>
                <th scope="col">Image</th>
                <th scope="col">Color</th>
                <th scope="col">Code</th>
                <th scope="col">Size</th>
                <th scope="col">Quantity</th>
                <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
            {ColorSize?.map((value, index) => (
                <tr key={index}>
                  <th>
                  <img style={{width : 70, height : 70}}
                  src={!/^data/.test(value?.image) ? `${window.API_URL}${value?.image}` : `${value?.image}`}
                  alt={'color image'}/>
                  </th>
                  <th>
                    {value.color}
                  </th>
                  <th>
                    <div style={{width : 40, height : 40, backgroundColor : value.colorCode, borderRadius : 30}}/>
                  </th>
                  <th>{value.size}</th>
                  <th>
                    {value.quantity}
                  </th>
                  <th >
                    <IconButton
                    aria-label="addColor"
                    className="mx-3"
                    onClick={(e) => handleClickDelete(index)}
                      >
                      {<DeleteIcon />}
                    </IconButton>
                    <IconButton
                    aria-label="addColor"
                    className="mx-3"
                    onClick={(e) => handleClickUpdate(value, index)}
                      >
                      {<EditIcon />}
                    </IconButton>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
      </div>
    )
};