import WichPage from "../../inc/helpfulComponents/WichPage";
import { useEffect, useState } from "react";
import ProductService from "../../../services/product.service";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { ClipLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const ProductInSold = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [prdInSolde, setPrdInSolde] = useState([]);
  const [solde_id, setSoldID] = useState(null);
  const [pourcentage, setPourcentage] = useState(null);
  const [date_debutsolde, setDateDebut] = useState(null);
  const [date_finsolde, setDateFin] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [promo_jour, setPromoJr] = useState(false);
  const [id_product, setid] = useState(null);
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    ProductService.allProductInSolde()

      .then((res) => {
        console.log(res.data.results);
        setPrdInSolde(res.data.results[0]);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning("Could not get products", "", 2000);

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }, []);

  const renderProductImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + "products/" + row.image.split(";")[0]}
        alt={row.image.split(";")[0]}
        width={100}
      />
    );
  };

  const renderActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <Link
          to={"/DetailsProduit/" + row.id}
          className="btn btn-icon btn-outline-primary"
        >
          <i className="feather icon-eye" />
        </Link>
        <button
          id={row.soldeid}
          data-toggle="modal"
          data-target="#modal-addToPromoJR"
          className="btn btn-icon btn-outline-warning my-2"
          onClick={ajouterProductToPromoJr}
        >
          <i className="fas fa-percent" id={row.soldeid}></i>
        </button>
        <button
          data-toggle="modal"
          data-target="#modal-edit"
          className="btn btn-icon btn-outline-success"
          onClick={() => {
            setSoldID(row.soldeid);
            setPourcentage(row.pourcentage);
            setDateDebut(row.date_debutsolde);
            setDateFin(row.date_finsolde);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          id={row.soldeid}
          onClick={deleteSolde}
        >
          <i className="far fa-trash-alt" id={row.soldeid}></i>
        </button>
      </div>
    );
  };

  const renderStartDate = (row) => {
    return (
      <div className="d-flex w-100 align-items-center justify-content-start">
        <Moment format="D MMM YYYY" withTitle>
          {row.date_debutsolde}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{row.date_debutsolde}</Moment> )
        </div>
      </div>
    );
  };
  const renderEndDate = (row) => {
    return (
      <div className="d-flex w-100 align-items-center justify-content-start">
        <Moment format="D MMM YYYY" withTitle>
          {row.date_finsolde}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{row.date_finsolde}</Moment> )
        </div>
      </div>
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "id", width: "90px" },
    {
      selector: "image",
      name: "Image",
      width: "150px",
      cellExport : (row) => row.images,
      cell: (row) => renderProductImage(row),
    },
    {
      selector: "name",
      name: "Titre",
      width: "180px",
    },
    {
      selector: "date_debutsolde",
      name: "Date Debut Solde",
      width: "240px",
      cell: (row) => renderStartDate(row),
    },
    {
      selector: "date_finsolde",
      name: "Date Fin Solde",
      width: "240px",
      cell: (row) => renderEndDate(row),
    },
    {
      selector: "pourcentage",
      name: "Remise (%)",
      width: "100px",
    },
    {
      name: "Action",
      width: "240px",
      cellExport : (row) => '',
      cell: (row) => renderActions(row),
    },
  ]);
  const tableData = {
    columns,
    data: prdInSolde,
  };

  const ajouterProductToPromoJr = (event) => {
    const userId = sessionStorage.getItem("id_user");
    console.log(userId);
    setLoading(true);
    // const userId = sessionStorage.getItem("id_user");
    var product_insold_id = event.target.id;
    const data = { id_solde: product_insold_id };
    console.log(data);
    ProductService.verifierPromoJr(product_insold_id)
      .then((success) => {
        ProductService.addProductToPromoJr(data)
          .then((res) => {
            setDisabled(true);
            NotificationManager.success(
              "produit ajouté avec succès en promo du jour",
              "",
              2000
            );
            setLoading(false);
          })
          .catch(function (error) {
            if (!error.response) {
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
            } else if (error.response.status === 403) {
              setLoading(false);
              error.response.data.error
                ? NotificationManager.warning(
                    error.response.data.error,
                    "",
                    2000
                  )
                : NotificationManager.warning(
                    "Impossible d'ajouter le produit en promo du jour",
                    "",
                    2000
                  );

              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            } else if (error.response.status === 401) {
              setLoading(false);
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
              setTimeout(() => {
                AuthService.logout();
              }, 500);
            }
          });
      })
      .catch(function (error) {
        swalWithBootstrapButtons
          .fire({
            title: "Attention !",
            text: "Ce produit existe deja en promo du jour!",
            icon: "warning",
            showCancelButton: false,
            reverseButtons: true,
          })
          .then((result) => {
            setLoading(false);
          });
      });
  };

  const deleteSolde = (event) => {
    const token = sessionStorage.getItem("token");
    const productSoldeId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "Vous ne pourrez pas revenir sur ce produit !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez-le",
        cancelButtonText: "Non, annulez !",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          ProductService.removeProductSolde(productSoldeId, token)
            .then((result) => {
              setTimeout(() => {
                ProductService.allProductInSolde().then(async (res) => {
                  setPrdInSolde(res.data.results[0]);
                  setLoading(false);
                });
              }, 300);

              swalWithBootstrapButtons.fire(
                "Supprimé !",
                "Le produit a été supprimé.",
                "success"
              );
            })
            .catch(function (error) {
              if (!error.response) {
                swalWithBootstrapButtons.fire(
                  "Annulé",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "error"
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? swalWithBootstrapButtons.fire(
                      "Annulé",
                      error.response.data.error,
                      "error"
                    )
                  : swalWithBootstrapButtons.fire(
                      "Annulé",
                      "Impossible de supprimer ce produit",
                      "error"
                    );

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.response.status === 401) {
                setLoading(false);
                swalWithBootstrapButtons.fire(
                  "Annulé",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "error"
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "Le produit n'a pas été supprimé.",
            "error"
          );
        }
      });
  };

  // Update PRODUCT SOLDE

  const updateProductSolde = () => {
    setLoading(true);
    const formSolde = {
      id: solde_id,
      pourcentage: pourcentage,
      date_debutsolde: date_debutsolde,
      date_finsolde: date_finsolde,
    };

    if (pourcentage === "" || date_debutsolde === "" || date_finsolde === "") {
      NotificationManager.warning("Please check all the inputs", "", 2000);
      setLoading(false);
    } else {
      ProductService.updateProductSolde(formSolde)
        .then((res) => {
          setDisabled(true);
          NotificationManager.success(
            "produit solde mis à jour avec succès",
            "",
            2000
          );
          ProductService.allProductInSolde().then(async (res) => {
            setPrdInSolde(res.data.results[0]);
            setLoading(false);
          });
          setLoading(false);
        })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          } else if (error.response.status === 403) {
            setLoading(false);
            error.response.data.error
              ? NotificationManager.warning(error.response.data.error, "", 2000)
              : NotificationManager.warning(
                  "Impossible de mettre à jour le solde",
                  "",
                  2000
                );

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false);
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout();
            }, 500);
          }
        });
    }
  };

  return (
    <>
      <WichPage
        pageTitle="Produits en solde"
        pageLocation="produits en solde"
      />
      <div className="row">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5>Produit en Sold</h5>
              <div className="card-header-right">
                <h5>Promo du jour</h5>
                <Link
                  to="/promodujour"
                  className="btn btn-icon btn-outline-warning mr-5"
                >
                  <i className="fas fa-eye" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
        {/*  START Update PRODUCT TO SOLDE MODAL */}

        <div
          className="modal fade"
          id="modal-edit"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Modifier Le Solde</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <TextField
                      id="Name"
                      label="Pourcentage"
                      className="w-100"
                      value={pourcentage}
                      onChange={(e) => setPourcentage(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      id="DateD"
                      label="DateDebut"
                      className="w-100"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      value={date_debutsolde}
                      onChange={(e) => setDateDebut(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      id="DateF"
                      label="DateFin"
                      className="w-100"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      value={date_finsolde}
                      onChange={(e) => setDateFin(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-12 text-right mt-5">
                    {/* <button
                      className="btn btn-icon btn-outline-primary"
                      onClick={ajouterProductToSolde}
                    >
                      <i className="feather icon-save" />
                    </button> */}
                    {!loading ? (
                      <button
                        type="button"
                        className="btn btn-icon btn-outline-primary"
                        onClick={updateProductSolde}
                        data-dismiss="modal"
                        // disabled={disabled}
                      >
                        <i className="feather icon-save" />
                      </button>
                    ) : (
                      <ClipLoader color={color} loading={loading} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  END UPDATE PRODUCT  SOLDE */}
      </div>
    </>
  );
};

export default ProductInSold;
