import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Please don't change this 2 variables  thank you
// window.IMAGE_API_URL = "http://localhost:3001/api/uploads/images/";
// window.API_URL = "http://localhost:3001/api";

window.IMAGE_API_URL = "https://healthcarefacilitiesconsult.com/api/uploads/images/";
window.API_URL = "https://healthcarefacilitiesconsult.com/api";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
