import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromHTML, convertToHTML } from "draft-convert";
import WichPage from "../../inc/helpfulComponents/WichPage";
import { useEffect, useState } from "react";
import BlogService from "../../../services/blog.service";
import DOMPurify from "dompurify";
import { useDropzone } from "react-dropzone";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { makeStyles, TextField } from "@material-ui/core";
import "./editorStyles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const UpdateBlog = () => {
  const id_blog = useParams().id;
  const [blog, setBlog] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [description, setDescription] = useState(blog.description || "");
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: () => setSeconImage(""),
  });
  const [seconImage, setSeconImage] = useState("");
  const copy = async () => {
    await navigator.clipboard.writeText(seconImage);
    NotificationManager.success("copied");
  };
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {!seconImage ? (
        <button
          className="btn btn-icon btn-outline-primary"
          onClick={() => saveAndGedEmbedingLink(file)}
        >
          <i className="far fa-save"></i>
        </button>
      ) : (
        <button className="btn btn-icon btn-outline-primary" onClick={copy}>
          <i className="far fa-copy" />
        </button>
      )}
    </li>
  ));
  const saveAndGedEmbedingLink = (file) => {
    const data = new FormData();
    data.append("image", file);
    BlogService.importImage(data).then((res) => {
      // console.log(res.data);
      setSeconImage(window.IMAGE_API_URL + "blogs/" + res.data.filename);
    });
  };

  useEffect(() => {
    BlogService.blogById(id_blog)
      .then((res) => {
        setBlog(res.data.result[0]);
        setDescription(res.data.result[0].description);
        setEditorState(
          EditorState.createWithContent(
            convertFromHTML(res.data.result[0].contenue)
          )
        );
        // console.log(res.data.result[0]);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        NotificationManager.error(resMessage);
      });
  }, []);
  const html = convertToHTML({
    styleToHTML: (style) => {
      if (style === "BOLD") {
        return <span style={{ fontWeight: "bold" }} />;
      }
      if (style === "ITALIC") {
        return <span style={{ fontStyle: "italic" }} />;
      }
    },
    blockToHTML: (block) => {
      const type = block.type;
      if (type === "PARAGRAPH") {
        return <p />;
      }
      if (type === "code-block") {
        return <pre />;
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === "LINK") {
        return <a href={entity.data.url}>{originalText}</a>;
      }
      if (entity.type === "IMAGE") {
        // console.log(entity);
        let url = entity.data.src;
        return {
          start: '<img width="800px" src=\'' + url + "'",
          end: "</img>",
        };
      }
      return originalText;
    },
  })(editorState.getCurrentContent());
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = async () => {
    setLoading(true);
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    await setConvertedContent(currentContentAsHTML);
    setLoading(false);
  };
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  useEffect(() => {}, [editorState]);
  const handelSubmit = async () => {
    // console.log(convertedContent);
    if (description && convertedContent && blog.id) {
      const data = {
        id: blog.id,
        titre: blog.titre,
        description: description,
        id_categorieBlg: blog.blog_category_id,
        contenue: convertedContent,
      };
      // data.append("redacteur", blog.redacteur);
      setLoading(true);
      BlogService.updateBlogContent(data, blog.id_redacteur)
        .then((res) => {
          Swal.fire("blog est modifié");
          setLoading(false);
          // setEditorState(() => EditorState.createEmpty());
        })
        .catch((error) => {
          setLoading(false);
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.response.data;
          error.toString();
          Swal.fire(resMessage);
        });
      if (!image) return;
    }

    if (image && blog.id) {
      const data = new FormData();
      data.append("image", image);
      data.append("id", blog.id);
      setLoading(true);
      BlogService.updateBlogImage(data, blog.id_redacteur)
        .then((res) => {
          Swal.fire("L'image du blog est modifié");
          // setEditorState(() => EditorState.createEmpty());
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          Swal.fire(resMessage);
        });
      return;
    }
    Swal.fire("Veuillez remplir toutes les champs");
  };

  const classes = useStyles();
  return (
    <>
      <WichPage pageTitle="Édition de blogs" pageLocation="blogs/edtionBlog" />
      <div className="row">
        <div className="col-sm-12">
          {blog.id ? (
            <div className="card">
              <div className="card-header">
                <h5>Édition de blog</h5>
              </div>
              <div className="card-body">
                <form className={classes.root} noValidate autoComplete="off">
                  <div className="row">
                    <div className="col-sm-6">
                      <TextField
                        id="title"
                        label="Titre du blog"
                        variant="outlined"
                        className="w-100"
                        multiline
                        maxRows={4}
                        disabled
                        value={blog.titre}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextField
                        id="autName"
                        label="Nom de l'auteur"
                        disabled
                        className="w-100"
                        variant="outlined"
                        multiline
                        maxRows={4}
                        value={blog.redacteur}
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        id="desc"
                        label="Description"
                        className="w-100"
                        variant="outlined"
                        multiline
                        maxRows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6 p-3">
                      <div className="form-group">
                        <label htmlFor="Category">Catégorie</label>
                        <select className="form-control" id="Category">
                          <option value={blog.blog_category_id}>
                            {blog.category_blog}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-6 p-3">
                      <div className="form-group fill">
                        <label htmlFor="image">Images de produit</label>
                        <input
                          type="file"
                          className="form-control"
                          id="image"
                          onChange={(e) => {
                            // console.log(e.target.files);
                            setImage(
                              e.target.files[0] ? e.target.files[0] : ""
                            );
                            // console.log(image);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div className="p-5 text-center">
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    style={{ border: "1px dashed black", borderRadius: "10px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <input {...getInputProps()} />
                    <p className="mt-2">
                      Faites glisser et déposez les fichiers ici, ou appuyez
                      pour sélectionner des fichiers
                    </p>
                  </div>
                  <aside className="mt-4">
                    <ul style={{ listStyle: "none" }}>{files}</ul>
                    <h5 onClick={copy}> {seconImage}</h5>
                  </aside>
                </div>
                <div
                  style={{
                    border: "1px solid black",
                    padding: "2px",
                    minHeight: "400px",
                  }}
                >
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    toolbarClassName="toolbar-class"
                  />
                </div>

                <div className="m-4">
                  <button
                    className="btn btn-warning"
                    onClick={() => setPreview(!preview)}
                  >
                    voir
                  </button>
                  {preview && (
                    <div>
                      {loading ? (
                        <ClipLoader />
                      ) : (
                        <div>
                          <h1>{blog.titre}</h1>
                          {image && (
                            <img
                              width="100%"
                              src={URL.createObjectURL(image)}
                              alt=" "
                            />
                          )}
                          <div
                            className="m-5"
                            dangerouslySetInnerHTML={createMarkup(html)}
                          ></div>
                        </div>
                      )}{" "}
                    </div>
                  )}
                </div>
                <div className="text-center m-5">
                  {loading ? (
                    <ClipLoader color={"#000000"} loading={loading} />
                  ) : (
                    <button className="btn btn-primary" onClick={handelSubmit}>
                      Sauvegarder les modifications
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <ClipLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateBlog;
