import React, { useEffect, useState, useRef } from "react";
import WichPage from "../../inc/helpfulComponents/WichPage";
// import dataTableScripts from "../../inc/scripts/dataTableScripts";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";
import ReclamationService from "../../../services/reclamation.service";
import { NotificationManager } from "react-notifications";
import AuthService from "../../../services/auth.service";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import { io } from 'socket.io-client'
import axios from "axios";
import Swal from "sweetalert2";

const Complaints = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [loading, setLoading] = useState(false)
  const [loadingReponse, setLoadingReponse] = useState(false)
  const [loadingReclam, setLoadingReclam] = useState(false)
  const [reclamations, setReclamations] = useState([])
  const [reclamation, setReclamation] = useState({})
  const [reponse, setReponse] = useState("")
  const [socket, setSocket] = useState(io(window.API_URL))
  const [status, setStatus] = useState()
  const [columns, setColumns] = useState([


    {
      name: "Numéro de réclamation",
      selector: "id",
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      selector: "statut",
      sortable: true,
      width: "100px",
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      width: "100px",
    },
    {
      name: "Client",
      // selector: "statut",
      sortable: true,
      cell: (d) => <span>{d.username + " " + d.usersurname}</span>,
      width: "auto",
      wrap: true,
    },
    {
      name: "Titre",
      selector: "titre",
      sortable: true,
      width: "auto",
    },
    {
      name: "Message",
      selector: "description",
      sortable: true,
      width: "auto",
      wrap: true,
    },
    {
      name: "Reponce",
      selector: "reponces_admin",
      sortable: true,
      width: "auto",
      wrap: true,
    },
    {
      name: "Actions",
      selector: "",
      sortable: true,
      width: "180px",
      cell: (row) => (
        <div data-tag="allowRowEvents" className="w-100">
          <div className="d-flex align-items-center justify-content-arround">
            <button
              data-toggle="modal"
              data-target="#answer-reclamation"
              className="btn btn-icon btn-outline-primary btn-sm my-2 mx-3"
              onClick={() => {
                getReclamationById(row.id);
              }}
            >
              <i className="fas fa-edit" id={row.id}></i>
            </button>
            <button
              type="button"
              className="btn btn-icon btn-outline-danger my-2 mx-3"
              id={row.id}
              onClick={deleteReclamation}
            >
              <i className="far fa-trash-alt" id={row.id}></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);
  const tableData = {
    columns,
    data: reclamations,
  };

  const getReclamationById = (id) => {
    setReponse("");
    ReclamationService.getReclamationById(id)
      .then((res) => {
        console.log(res.data.result[0]);
        setReclamation(res.data.result[0]);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning("Impossible de charger les réclamations", "", 2000);

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  const sendReclamationReponse = () => {
    const data = {
      id: reclamation.id,
      statut: status,
      reponces_admin: reponse,
      email: reclamation.useremail,
    };
    ReclamationService.AddAnswerReclamation(data)
      .then((res) => {
        NotificationManager.success("Message envoyé avec succès", "", 4000);
        setLoading(true);
        ReclamationService.getReclamations()
          .then((res) => {
            setReclamations(res.data.results);
            setLoading(false);
          })
          .catch(function (error) {
            if (!error.response) {
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
            } else if (error.response.status === 403) {
              setLoading(false);
              error.response.data.error || error.response.data.message
                ? NotificationManager.warning(
                    error.response.data.error
                      ? error.response.data.error
                      : error.response.data.message,
                    "",
                    2000
                  )
                : NotificationManager.warning(
                    "Impossible de charger les réclamations",
                    "",
                    2000
                  );

              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            } else if (error.response.status === 401) {
              setLoading(false);
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
              setTimeout(() => {
                AuthService.logout();
              }, 500);
            }
          });
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Impossible d'envoyer la réclamation",
                "",
                2000
              );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });

  }


  socket.on("send-reclamation", (user, status) => {
    setLoading(true)
    ReclamationService.getReclamations()
      .then(res => {
        setReclamations(res.data.results)
        setLoading(false)
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        }
        else if (error.response.status === 403) {
          setLoading(false)
          error.response.data.error || error.response.data.message ?
            NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
            :
            NotificationManager.warning("Impossible de charger les réclamations", "", 2000)

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false)
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout()
          }, 500);
        }
      });
  })

  useEffect(() => {
    setLoading(true);
    ReclamationService.getReclamations()
      .then((res) => {
        setReclamations(res.data.results);
        setLoading(false);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Impossible de charger les réclamations",
                "",
                2000
              );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }, []);

  const deleteReclamation = (event) => {
    const token = sessionStorage.getItem("token");
    const reclamationId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes Vous sur?",
        text: "Vos ne verrez plus cette reclamation!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, Supprimer!",
        cancelButtonText: "Non, Annuller!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          ReclamationService.deleteReclamation(reclamationId, token)
            .then((result) => {
              setTimeout(() => {
                ReclamationService.getReclamations().then(async (res) => {
                  setReclamations(res.data.results);
                  setLoading(false);
                });
              }, 300);

              swalWithBootstrapButtons.fire(
                "Supprimer!",
                "La reclamation a été supprimée.",
                "success"
              );
            })
            .catch(function (error) {
              if (!error.response) {
                swalWithBootstrapButtons.fire(
                  "Annuller",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? swalWithBootstrapButtons.fire(
                      "Annuller",
                      error.response.data.error,
                      "erreur"
                    )
                  : swalWithBootstrapButtons.fire(
                      "Annuller",
                      "La suppression de cette reclamation a été annulléee ",
                      "error"
                    );

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.response.status === 401) {
                setLoading(false);
                swalWithBootstrapButtons.fire(
                  "Annulé",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annuller",
            "Votre reclamation n'a pas été supprimée",
            "erreur"
          );
        }
      });
  };

  return (
    <>
      <WichPage pageTitle="Réclamations" pageLocation="reclamations" />

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5>Reclamations</h5>
            </div>
            <div className="card-body shadow border-0">
              {!loading ? (
                <div className="myaccount-content">
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      columns={columns}
                      data={reclamations}
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </div>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/*  START Answer Reclamation MODAL */}

      <div
        className="modal fade"
        id="answer-reclamation"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Réclamation</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {
                !loadingReclam ?

                  <div className="row">
                    <div className="col-12">
                      <div className="my-3">
                        <label>Titre :  {reclamation.titre}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="my-3">
                        <TextField
                          id="status"
                          className="w-100"
                          select
                          label="Status"
                          value={status}
                          onChange={(e) => (setStatus(e.target.value))}

                        >
                            <MenuItem key={1} value={"Open"}>
                              Open
                            </MenuItem>
                            <MenuItem key={2} value={"Closed"}>
                              Closed
                            </MenuItem>
                            <MenuItem key={2} value={"Pending"}>
                            Pending
                            </MenuItem>
                        </TextField>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="my-3">
                        <label>Client :  {reclamation.username} {reclamation.usersurname}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="my-3">
                        <label>Email :  {reclamation.useremail}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="my-3">
                        <label>Message :  {reclamation.description}</label>
                      </div>
                    </div>
                    {
                      reclamation.image ?
                        <div className="col-12">
                          <div className="my-3 d-flex flex-column">
                            <div className="d-flex">
                              <label>Image :</label>
                              <div className="download">

                              </div>

                            </div>
                            <a href={window.IMAGE_API_URL + "reclamations/" + reclamation.image} target="_blank">
                              <img
                                className="mx-auto"
                                src={window.IMAGE_API_URL + "reclamations/" + reclamation.image}
                                alt="Reclamation Image"
                                width={"300px"}
                              />
                            </a>
                          </div>
                        </div>
                        :
                        null
                    }
                    <div className="col-12">
                      <div className="my-3">
                        {
                          reclamation.reponces_admin ?

                            <label className="text-wrap">Reponse : {reclamation.reponces_admin} </label>

                            :
                            <div className="d-flex flex-column">
                              {/* <label>Reponse : </label> */}
                              <div className="col-12">
                                <TextField
                                  id="reponse"
                                  label="Reponse"
                                  className="w-100"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  multiline
                                  maxRows={5}
                                  type="text"
                                  value={reponse}
                                  onChange={(e) => setReponse(e.target.value)}
                                />
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                    <div className="col-sm-12 text-right mt-5">
                      {
                        !reclamation.reponces_admin ?
                          loadingReponse ?
                            <ClipLoader color={"#000000"} loading={loading} />
                            :
                            <button
                              className="btn btn-icon btn-outline-primary"
                              onClick={sendReclamationReponse}
                            // data-dismiss="modal"
                            >
                              <i className="feather icon-save" />
                            </button>
                          : null
                      }
                    </div>
                  </div>
                  : <ClipLoader color={"#000000"} loading={loading} />
              }
            </div>
          </div>
        </div>
      </div>

      {/*  END Answer Reclamation MODAL */}
    </>
  );
};

export default Complaints;
