import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import DashboardService from "../../../services/dashboard.service";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";

const MessagesCard = () => {

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [contacts, setContacts] = useState([]);

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    //Lasst Messages
    DashboardService.getLastMessages().then((res) => {
      setContacts(res.data.results);
      setLoading(false)
      // console.log(res.data.results)
    })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        }
        else if (error.response.status === 403) {
          setLoading(false)
          error.response.data.error || error.response.data.message ?
            NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
            :
            NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false)
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout()
          }, 500);
        }
      });
  }, []);

  return (
    <div className="card new-cust-card">
      <div className="card-header">
        <h5>Messages</h5>

      </div>
      <div
        className="cust-scroll"
        style={{ height: "415px", position: "relative" }}
      >
        <div className="card-body p-b-0">
          {
            contacts.map((contact, index) => (
              <div key={index} className="align-middle mb-2 ">
                <div className="d-inline-block w-100">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex align-items-center w-100 justify-content-between px-2 ">
                        <div>
                          <h6>{contact.first_name}</h6>
                        </div>
                        {/* <div className="d-flex w-100 align-items-center " style={{ marginLeft: "120px" }}> */}
                        <div className="d-flex" >
                          <Moment format="DD/MM/YYYY" className="">
                            {contact.created_at}
                          </Moment>
                          <div className="text-muted mx-3">
                            ( <Moment fromNow>{contact.created_at}</Moment> )
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <p className="ml-3 my-2">{contact.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MessagesCard;
