/* eslint-disable no-unused-vars */
// import moment from "moment";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import BlogService from "../../../services/blog.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";
// import NotificationManager from "react-notifications/lib/NotificationManager";
import Swal from "sweetalert2";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const deletBlog = (id) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le !",
    }).then((result) => {
      if (result.isConfirmed) {
        BlogService.deleteBlog(id).then(() => {
          setLoading(true);
          Swal.fire("Supprimé !", "Votre fichier a été supprimé.", "success");
          BlogService.allBlogs()
            .then((res) => {
              setBlogs(res.data.results[0]);
              setLoading(false);
            })
            .catch((err) => console.log(err));
        });
      }
    });
  };
  const renderBlogActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <Link
          to={`/blogDetails/${row.id}`}
          className="btn btn-icon btn-outline-primary"
        >
          <i className="feather icon-eye" />
        </Link>
        <Link
          to={`/updateBlog/${row.id}`}
          className="btn btn-icon btn-outline-success"
        >
          <i className="fas fa-edit"></i>
        </Link>
        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          onClick={() => deletBlog(row.id)}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
    );
  };

  const renderBlogDate = (row) => {
    return (
      <div className="d-flex w-100 align-items-center justify-content-start">
        <Moment format="D MMM YYYY" withTitle>
          {row.created_at}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{row.created_at}</Moment> )
        </div>
      </div>
    );
  };

  const renderBlogImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + "blogs/" + row.image.split(";")[0]}
        alt={row.image.split(";")[0]}
        width={150}
      />
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "N° Blog", width: "70px" },
    {
      selector: "image",
      name: "Image",
      width: "190px",

      sortable: false,
      disableClickEventBubbling: true,
      cell: (row) => renderBlogImage(row),
    },
    {
      selector: "titre",
      name: "Titre",
      width: "150px",
      wrap: true,
    },

    {
      selector: "redacteur",
      name: "Auteur",
      width: "190px",
      wrap: true,
    },
    {
      selector: "catblog",
      name: "Catégorie",
      width: "190px",
      wrap: true,
    },
    {
      selector: "created_at",
      name: "Date d'ajout",
      type: "date",
      width: "350",

      cell: (row) => renderBlogDate(row),
    },
    {
      name: "Action",
      // type: 'number',
      width: "200px",
      cell: (row) => renderBlogActions(row),
    },
  ]);
  const tableData = {
    columns,
    data: blogs,
  };

  useEffect(() => {
    BlogService.allBlogs()
      .then((res) => {
        setBlogs(res.data.results[0]);
        // console.log(res.data.results);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <WichPage pageTitle="Blogs" pageLocation="blogs" />

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow-none">
            <div className="card-header">
              <h5>Blogs</h5>
              <div className="card-header-right">
                <h5 className="m-3">Ajouter un blog</h5>
                <Link
                  to="/editBlog"
                  className="btn btn-icon btn-outline-primary"
                >
                  <i className="fas fa-plus-circle"></i>
                </Link>
              </div>
            </div>
            <div className="card-body shadow border-0">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
              {/* <div className="table-responsive">
                <table className="table table-bordered table-striped mb-0">
                  <thead>
                    <tr>
                      <th className="border-top-0">Titre</th>
                      <th className="border-top-0">Auteur</th>
                      <th className="border-top-0">Catégorie</th>
                      <th className="border-top-0">Date de création</th>
                      <th className="border-top-0" width="15%">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    {blogs.map((blog, index) => (
                      <tr key={index}>
                        <td>{blog.titre}</td>
                        <td>{blog.redacteur}</td>
                        <td>{blog.id_categorieBlg}</td>
                        <td>
                          {moment(blog.created_at).fromNow()} :{" "}
                          {moment(blog.created_at).format("YYYY/MM/DD")}{" "}
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-around">
                            <Link
                              to="/blogsDetails"
                              className="btn btn-icon btn-outline-primary"
                            >
                              <i className="feather icon-eye" />
                            </Link>
                            <button className="btn btn-icon btn-outline-primary">
                              <i className="fas fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-icon btn-outline-danger"
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
