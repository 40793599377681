import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    UpdateAchat,
    ReadAchat,
    DeleteAchat
} from "../../../services/achat_maintenant";
import WichPage from "../../inc/helpfulComponents/WichPage";
import Moment from "react-moment";
import NotificationManager from "react-notifications/lib/NotificationManager";
import "react-notifications/lib/notifications.css";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

export default function ListOrders(props) {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [data, setdata] = useState([]);
  const [FilterdData, setFilterdData] = useState([]);
  const [loading, setLoading] = useState(false);

  const renderActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <Link
          to={`/achat-imediat/${row.id}`}
          className="btn btn-icon btn-outline-success mx-3 my-2"
        >
          <i className="fas fa-eye" id={row.id}></i>
        </Link>
        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          id={row.id}
          onClick={deleteDemande}
        >
          <i className="far fa-trash-alt" id={row.id} />
        </button>
      </div>
    );
  };

  const renderDate = (row) => {
    return (
      <div className="d-flex w-100 align-items-center justify-content-center">
        <Moment format="DD/MM/YYYY" className="text-center">
          {row.created_at}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{row.created_at}</Moment> )
        </div>
      </div>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
        selector: "product_image",
        name: "product image",
        width : "200px",
        cell : (row) => <div style={{padding : 4, }}><img  style={{width : '100%', height : '100%', borderRadius : 5}} alt={"product image"} src={`${window.IMAGE_API_URL}products/${row.product_image?.split(';')[0]}`}/> </div>
    },
    {
        selector: "id",
        name: "N°ID"
    },
    {
        selector: "firstName",
        name: "Nom",
        wrap : true
    },
    {
        selector: "lastName",
        name: "Prenom",
        wrap : true
    },
    {
        selector: "phone",
        name: "phone",
        width: "150px",
        wrap : true
    },
    {
        selector: "email",
        name: "email",
        width: "150px",
        wrap : true
    },
    {
        selector: "status",
        name: "status",
        width: "150px",
        wrap : true,
        cell : (row) => <b style={{color : row.status === 'pending' ? '#d97021' : '#11a624'}}>{row.status}</b>
    },
    {
      selector: "created_at",
      name: "Date de commande",
      width: "300px",
      cell: (row) => renderDate(row),
    },

    {
      name: "Action",
      width: "200px",
      sortable: false,
      cell: (row) => renderActions(row),
    },
  ]);

  const tableData = {
    columns,
    data: FilterdData,
  };

  const LoadOrders = () => {
    setLoading(true);
    ReadAchat()
        .then((res) => {
            setdata(res.data.data);
            setFilterdData(res.data.data);
            setLoading(false);
        })
        .catch(function (error) {
        setLoading(false);
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403 || error.response.status === 400) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Impossible de charger les devis",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }

  useEffect(() => LoadOrders(), []);

  const deleteDemande = (event) => {
    if (props.match.params.status === 'cancelled')
        return swalWithBootstrapButtons.fire(
        "already cancelled !!",
        "",
        "info"
        );
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure ?",
        text: "Once you delete this order, you lose it forever !!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete it",
        cancelButtonText: "No, Cancel",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteAchat({
              id : event.target.id
          }).then((res) => {
            setdata((old) => old.filter((item) => item.id != event.target.id))  
            swalWithBootstrapButtons.fire(
              "Deleted !!",
              "Data deleted successfully !!.",
              "success"
            );
          }).catch((e) => 
            swalWithBootstrapButtons.fire(
            "error !!",
            e?.response?.data?.error || e?.response?.data?.message || "something went wrong while deleting the data !!",
            "error"
          ));
            return true;
        }
      })
      .catch(function (error) {
        setLoading(false);
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403 || error.response.status === 400) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Impossible de supprimer order",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  const [FilterData, setFilterData] = useState({
    status : ''
  });

  useEffect(() =>
    FilterData.status !== '' ? setFilterdData((old) => data.filter((val, ind) => val?.status === FilterData.status)) : setFilterdData(data)
  , [FilterData]);

  return (
    <>
      <WichPage
        pageTitle="List orders"
        pageLocation="orders"
      />
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>List orders</h5>
            </div>
            <div style={{
              width: 100,
              display : 'flex',
              flexDirection : 'row',
              justifyContent : 'space-around',
              width : '100%',
              paddingTop : 10
            }}>
              <div></div>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={FilterData.status}
                    label="status"
                    onChange={(event) => setFilterData({...FilterData, status : (event.target.value || '')})}
                  >
                    <MenuItem value={''}>All</MenuItem>
                    <MenuItem value={'pending'}>pending</MenuItem>
                    <MenuItem value={'confirmed'}>confirmee</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="card-body">
              <DataTableExtensions {...tableData}>
                <DataTable
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  highlightOnHover
                  progressPending={loading}
                  pagination
                />
              </DataTableExtensions>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};