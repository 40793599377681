import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WichPage from "../../inc/helpfulComponents/WichPage";
import { ClipLoader } from "react-spinners";
import Moment from 'react-moment';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import Newarrival from "../../../services/newarrival.service";


const ProductDetails = () => {
    const params = useParams();
    const [product, setproduct] = useState({});
    const [loading, setLoading] = useState(false)
    const [color, setColor] = useState("#00000");
    const [items, setItems] = useState([])
    const [rest, setRest] = useState(0)

    useEffect(() => {
        setLoading(true)
        Newarrival.getNewArrivalProductById(params.id)
            .then(async (res) => {
                const data = res.data.result[0]
                await setproduct(data)
                const listImages = await data.image.substring(0, data.image.length - 1).split(';')
                listImages.map(async item => {
                    setItems(items => [...items, { src: `${window.IMAGE_API_URL}products/${item}` }]);
                })
                setRest(parseFloat(data.quantite - (data.quantite / 100) * data.count))
                await setLoading(false)
            })

    }, []);


    return (
        <div>
            <WichPage
                pageTitle="Details du produit"
                pageLocation="produits/detailsProduit"
            />
            <div className="row">
                {/* customar project  start */}
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {console.log(items)}
                            <div className="row">
                                <div className="col-lg-5 col-sm-12">
                                    {items.length > 0 ?
                                        <Carousel images={items} style={{ height: 500, width: "100%" }} />
                                        :
                                        <ClipLoader color={color} loading={loading} />
                                    }
                                </div>
                                <div className="col-lg-7 col-sm-12">
                                    <div className="pl-lg-4">
                                        <h5 className="text-muted">
                                            Catégorie : {product.category} {product.sub_category ? ' - ' + product.sub_category : ''} {product.familly ? ' - ' + product.familly : ''}
                                        </h5>
                                        <h3 className="mt-0">
                                            {product.name}
                                            <a href="#!" className="text-muted">
                                                <i className="mdi mdi-square-edit-outline ml-2" />
                                            </a>
                                        </h3>
                                        <p className="mb-1">
                                            Date d'ajout:
                                            <span className="ml-2">
                                                <Moment format="YYYY/MM/DD">
                                                    {product.created_at}
                                                </Moment>
                                            </span>
                                        </p>
                                        <div className="mt-4 d-flex align-items-center">
                                            <h5>Prix:</h5>
                                            <h3 className="ml-2">
                                                {parseFloat(product.tarif).toFixed(2)} MAD
                                            </h3>
                                        </div>
                                        <div className="mt-4">
                                            <h4>Description:</h4>
                                            <p>{product.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="mt-4"></div>

                                    <div className="w-100">
                                        <div className="row mb-2">
                                            <div className="col-4">Nom</div>
                                            <div className="col-8">{product.name}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-4">Categorie</div>
                                            <div className="col-8">{product.category}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-4">Sous-categorie</div>
                                            <div className="col-8">{product.sub_category}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-4">Famille</div>
                                            <div className="col-8">{product.familly}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-4">Largeur</div>
                                            <div className="col-8">{product.largeur} cm</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-4">Longueur</div>
                                            <div className="col-8">{product.largeur} cm</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-4">Volume</div>
                                            <div className="col-8">{product.volume} m<sup>3</sup> (mL) </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-4">Surface</div>
                                            <div className="col-8">{product.surface} m<sup>2</sup></div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-4">Hauteur</div>
                                            <div className="col-8">{product.hauteur} cm</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="mt-4">
                                        <div className="w-100">
                                            <div className="row mb-2">
                                                <div className="col-4">Reference</div>
                                                <div className="col-8">{product.reference}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-4">Designation</div>
                                                <div className="col-8">{product.designation}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-4">Caracteristiques</div>
                                                <div className="col-8">{product.caracteristique}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-4">Marque</div>
                                                <div className="col-8">{product.marque}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-4">Info supp</div>
                                                <div className="col-8">{product.info_supp}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* customar project  end */}
            </div>
        </div>
    );
};

export default ProductDetails;
