/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState, useRef } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import AdminService from "../../../services/admin.service";
import BlogService from "../../../services/blog.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
// import MyBlogs from "./MyBlogs";
// import PersonalDetails from "./PersonalDetails";
import { NotificationManager } from "react-notifications";
import AuthService from "../../../services/auth.service";
import { ClipLoader } from "react-spinners";
import PasswordUpdate from "./PasswordUpdate";


const Profil = () => {
  const [acountInf, setAcountInf] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false)
  const [color, setColor] = useState("#00000");
  const form = useRef(null);
  function getExtension(filename) {
    try {
      var parts = filename.split('.');
      return parts[parts.length - 1];
    } catch (error) {
      return ''
    }
  }

  function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
        return true
      case 'jpeg':
        return true
      case 'png':
        return true
      default:
        return false
    }

  }

  const fdToJson = (fd) => {
    let obj = {};
    for (let key of fd.keys()) {
      obj[key] = fd.get(key);
    }
    return obj;
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const data = fdToJson(new FormData(form.current));
    console.log(data)
    AdminService.updatePersonalInfo(data)
      .then((res) => {
        loadAdmin()
        NotificationManager.success(
          "Mise à jour du profil réussie",
          "",
          2000
        );
      })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            2000
          );
        } else if (error.response.status === 403) {
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
              "Impossible de mettre à jour les données d'admin",
              "",
              2000
            );

        } else if (error.response.status === 401) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  const updateAvatar = (event) => {
    if (!event.target.files || !isImage(event.target.files[0].name)) {
      NotificationManager.error(
        "Le fichier importé n'est pas une image",
        "",
        2000
      );
    } else {
      const image = event.target.files[0] ? event.target.files[0] : null
      console.log(image)
      const formData = new FormData();
      formData.append("id", acountInf.id)
      formData.append("avatar", image)
      setLoading(true)
      AdminService.updateAvatar(formData)
        .then(res => {
          setLoading(false)
          loadAdmin()
          sessionStorage.setItem("avatar", acountInf.avatar)
          NotificationManager.success(
            "Avatar mis à jour avec succès",
            "",
            2000
          );
        })
        .catch(function (error) {
          // console.log(error)
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              2000
            );
          } else if (error.response.status === 403) {
            error.response.data.error
              ? NotificationManager.warning(error.response.data.error, "", 2000)
              : NotificationManager.warning(
                "Impossible de mettre à jour l'avatar de l'admin",
                "",
                2000
              );

          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout();
            }, 500);
          }
        });
    }
  };


  const loadAdmin = () => {
    AdminService.adminAcount().then((res) => {
      setLoading(false)
      setAcountInf(res.data.result[0]);

    }).catch(function (error) {
      // console.log(error)
      if (!error.response) {
        NotificationManager.error(
          "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
      } else if (error.response.status === 403) {
        error.response.data.error
          ? NotificationManager.warning(error.response.data.error, "", 2000)
          : NotificationManager.warning(
            "Impossible de charger les informations d'admin",
            "",
            2000
          );

      } else if (error.response.status === 401) {
        NotificationManager.error(
          "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
        setTimeout(() => {
          AuthService.logout();
        }, 500);
      }
    });
  }
  const loadBlogs = () => {
    BlogService.getBlogByAdmin().then((res) => {
      setLoading(false)
      console.log(res.data.results)
      setBlogs(res.data.results);

    }).catch(function (error) {
      // console.log(error)
      if (!error.response) {
        NotificationManager.error(
          "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
      } else if (error.response.status === 403) {
        error.response.data.error
          ? NotificationManager.warning(error.response.data.error, "", 2000)
          : NotificationManager.warning(
            "Impossible de charger les blogs",
            "",
            2000
          );

      } else if (error.response.status === 401) {
        NotificationManager.error(
          "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
        setTimeout(() => {
          AuthService.logout();
        }, 500);
      }
    });
  }

  useEffect(() => {
    setLoading(true)
    loadAdmin()
    loadBlogs()
  }, []);
  return (
    <>
      <WichPage pageTitle="Profil" pageLocation="profil" />
      <div className="user-profile user-card mb-4">
        <div className="card-body py-0">
          <div className="user-about-block m-0">
            <div className="row">
              <div className="col-md-4 text-center mt-n5">
                <div className="change-profile text-center">
                  {
                    loading ? <ClipLoader color={color} loading={loading} />
                      :

                      <div className="dropdown w-auto d-inline-block">
                        <Link
                          to="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="profile-dp">
                            <div className="position-relative d-inline-block">
                              <img
                                className="img-radius img-fluid"
                                src={window.IMAGE_API_URL + "avatar/" + acountInf.avatar}
                                alt="User image"
                                style={{ width: "100px", height: "100px" }}

                              />
                            </div>
                            <div className="overlay">
                              <span>change</span>
                            </div>
                          </div>
                          {/* <div className="certificated-badge">
                        <i className="fas fa-certificate text-c-blue bg-icon" />
                        <i className="fas fa-check front-icon text-white" />
                      </div> */}
                        </Link>
                        <div className="dropdown-menu">
                          <label className="dropdown-item" for="logo">
                            <i className="feather icon-upload-cloud mr-2" />
                            Modifier Avatar
                          </label>
                          <input type="file" id="logo" onChange={updateAvatar} className="d-none" />
                        </div>
                      </div>
                  }
                </div>
                <h5 className="mb-1">{acountInf.nom} {acountInf.prenom}</h5>
                <p className="mb-2 text-muted">{acountInf.role}</p>
              </div>
              <div className="col-md-8 mt-md-4">
                <div className="row">
                  <div className="col-md-6">
                    <div className="clearfix" />
                    <span
                      className="mb-1 text-muted d-flex align-items-end text-h-primary"
                    >
                      <i className="feather icon-mail mr-2 f-18" />
                      {acountInf.email || "admin email"}
                    </span>

                  </div>
                  <div className="col-md-6">
                    <div className="media">
                      <i className="fas fa-address-card mr-2" />
                      <div className="media-body">
                        <p >Description</p>
                        <p className="mb-0 text-muted">
                          {acountInf.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <ul
                  className="nav nav-tabs profile-tabs nav-fill"
                  id="myTab"
                  role="tablist"
                >

                  <li className="nav-item">
                    <a
                      className="nav-link text-reset active"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="true"
                    >
                      <i className="feather icon-user mr-2" />
                      Les paramètres du profil
                    </a>
                  </li>
                  {
                    acountInf.role === "Admin" || acountInf.role === "Redacteur" ?
                      <li className="nav-item">
                        <a
                          className="nav-link text-reset"
                          id="home-tab"
                          data-toggle="tab"
                          href="#home"
                          role="tab"
                          aria-controls="home"
                          aria-selected="false"
                        >
                          <i className="feather icon-home mr-2" />
                          Blogs
                        </a>
                      </li> : null
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 ">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="card shadow-none">
                <div className="card-header">
                  <h5>Blogs</h5>
                  <div className="card-header-right">
                    <h5 className="m-3">Ajouter un blog</h5>
                    <Link to="/editBlog" className="btn btn-icon btn-outline-primary">
                      <i className="fas fa-plus-circle"></i>
                    </Link>
                  </div>
                </div>
                <div className="card-body shadow border-0">
                  {/* {blogs.length} */}
                  <div className="row">
                    {
                      blogs.map((blog) => (
                        <div className="col-4">
                          <div className="card">
                            <div className="card-header">
                              <h5 className="font-weight-normal">
                                <a href={"/blogDetails/"+blog.id} className="text-h-primary text-reset">
                                  <b className="font-weight-bolder">{acountInf.nom} {acountInf.prenom}</b>
                                </a>
                              </h5>
                              <p className="mb-0 text-muted">
                                <div className="d-flex w-100 align-items-center justify-content-start">
                                  <Moment format="DD/MM/YYYY" className="text-center">
                                    {blog.created_at}
                                  </Moment>
                                  <div className="text-muted mx-3">
                                  <Moment fromNow>{blog.created_at}</Moment> 
                                  </div>
                                </div>
                              </p>
                            </div>
                            <img
                                className="img-fluid"
                                src={window.IMAGE_API_URL + "blogs/" + blog.image}
                                alt={blog.image}
                                // style={{ width: "100px", height: "100px" }}

                              />
                            {/* <img
                              src=
                              alt=""
                              className="img-fluid"
                            /> */}
                            <div className="card-body">
                              <a href="#!" className="text-h-primary">
                                <h6>{blog.titre}</h6>
                              </a>
                              <p className="text-muted mb-0">
                                {blog.description}
                              </p>
                            </div>
                            <div className="card-body border-top border-bottom">
                              <ul className="list-inline m-0">
                                <li className="list-inline-item">
                                  <span className="text-muted text-h-danger">
                                    {/* <i className="feather icon-eye mr-2" /> */}
                                    {blog.rates} Note Moyenne
                                  </span>
                                </li>
                                {/* <li className="list-inline-item">
                                  <span className="text-muted text-h-primary">
                                    <i className="feather icon-message-square mr-2" />
                                    Comment
                                  </span>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade show active"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {acountInf && <>
                <div className="card">
                  <div className="card-body d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Détails personnels</h5>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm rounded m-0 float-right"
                      data-toggle="collapse"
                      data-target=".pro-det-edit"
                      aria-expanded="false"
                      aria-controls="pro-det-edit-1 pro-det-edit-2"
                    >
                      <i className="fas fa-user-edit" />
                    </button>
                  </div>
                  <div
                    className="card-body border-top pro-det-edit collapse show"
                    id="pro-det-edit-1"
                  >
                    <div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Nom
                        </label>
                        <div className="col-sm-9">{acountInf.nom}</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Prenom
                        </label>
                        <div className="col-sm-9">{acountInf.prenom}</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Email
                        </label>
                        <div className="col-sm-9">{acountInf.email}</div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Description
                        </label>
                        <div className="col-sm-9">
                          <p className="mb-0 text-muted">{acountInf.description}</p>
                        </div>
                      </div>
                      <h4>Social media</h4>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Facebook
                        </label>
                        <div className="col-sm-9">
                          <p className="mb-0 text-muted">{acountInf.fb}</p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Instagram
                        </label>
                        <div className="col-sm-9">
                          <p className="mb-0 text-muted">{acountInf.insta}</p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Twitter
                        </label>
                        <div className="col-sm-9">
                          <p className="mb-0 text-muted">{acountInf.twitter}</p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Linked In
                        </label>
                        <div className="col-sm-9">
                          <p className="mb-0 text-muted">{acountInf.linkedin}</p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Youtube
                        </label>
                        <div className="col-sm-9">
                          <p className="mb-0 text-muted">{acountInf.youtube}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body border-top pro-det-edit collapse "
                    id="pro-det-edit-2"
                  >
                    <form onSubmit={handelSubmit} ref={form}>
                      <input name="id" type="type" className="d-none" defaultValue={acountInf.id} />
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Email
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={acountInf.email}
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Nom
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={acountInf.nom}
                            name="nom"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Prenom
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={acountInf.prenom}
                            name="prenom"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Description
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            name="description"
                            className="form-control"
                            defaultValue={acountInf.description}
                          />
                        </div>
                      </div>
                      <h4>Social media</h4>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Facebook
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="url"
                            className="form-control"
                            defaultValue={acountInf.fb}
                            name="fb"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Instagram
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="url"
                            className="form-control"
                            defaultValue={acountInf.insta}
                            name="insta"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Twitter
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="url"
                            className="form-control"
                            defaultValue={acountInf.twitter}
                            name="twitter"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          Linked In
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="url"
                            className="form-control"
                            defaultValue={acountInf.linkedin}
                            name="linkedin"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label font-weight-bolder">
                          youtube
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="url"
                            className="form-control"
                            defaultValue={acountInf.youtube}
                            name="youtube"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-9 text-right">
                          <button
                            type="submit"
                            className="btn btn-icon btn-outline-primary"
                          >
                            <i className="feather icon-save" />
                          </button>
                        </div>
                        <label className="col-sm-3 col-form-label" />
                      </div>


                    </form>
                  </div>
                </div>
                <PasswordUpdate />
              </>}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Profil;
