import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

const user = AuthService.getCurrentUser();

export const getOrders = ({
    pageId=0,
    npage=20,
    status=""
}) => {
  return axios.get(`${window.API_URL}/${user.role.toLowerCase()}/order`, {
      ...authHeader(),
      params : {
          pageId,
          npage,
          status
      }
  });
};

export const getOrderDetail = ({
    order_id
}) => {
  return axios.get(`${window.API_URL}/${user.role.toLowerCase()}/order/details`, {
      ...authHeader(),
      params : {
        order_id
      }
  });
};

export const UpdateOrder = ({
    order_id,
    status
}) => {
  return axios.put(`${window.API_URL}/${user.role.toLowerCase()}/order`, 
  {
    order_id,
    status
  },
  authHeader());
};