import React, { useEffect, useState } from "react";
import WichPage from "../../inc/helpfulComponents/WichPage";

import AuthService from "../../../services/auth.service";
import RedacteurService from "../../../services/redacteur.service";
import { ClipLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

const Bloggers = () => {



  const [blogers, setBlogers] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    setLoading(true)
    RedacteurService.getAllRedacteurs().then((res) => {
      setBlogers(res.data.results);
      console.log(res.data.results)
      setLoading(false)
    })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        }
        else if (error.response.status === 403) {
          setLoading(false)
          error.response.data.error ?
            NotificationManager.warning(error.response.data.error, "", 2000)
            :
            NotificationManager.warning("Nous n'avons pas pu charger la liste des bloggers", "", 2000)
        } else if (error.response.status === 401) {
          setLoading(false)
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout()
          }, 500);
        }
      });

  }, []);

  return (
    <>
      <WichPage pageTitle="Rédacteurs" pageLocation="redacteurs" />
      <div className="row">
        {
          loading ?
            <ClipLoader loading={loading} />
            :
            blogers.map((item, key)=>(            
            <div key={key} className="col-xl-4 col-md-6">
              <div className="card user-card user-card-3 social-hover support-bar1">
                <div className="card-body">
                  <div className="text-center">
                    <img
                      className="img-fluid"
                      src={window.IMAGE_API_URL + `avatar/` + item.avatar}
                      alt={item.avatar}
                      width={150}
                      style={{ borderRadius: '50%',height:"150px" }}
                    />
                    <h3 className="mb-1 mt-3 f-w-400">{item.nom} {item.prenom}</h3>
                    <p className="mb-3 text-muted">{item.description}</p>
                    <ul className="list-unstyled f-20 mb-0 social-top-link">
                    {item.fb ?
                      <li className="list-item">
                        <a href={item.fb} className="text-facebook" target="_blank">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      : null }
                    {item.insta ?
                      <li className="list-item">
                        <a href={item.insta} className="text-instagrame" target="_blank">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      : null }
                    {item.twitter ? 
                      <li className="list-item">
                        <a href={item.twitter} className="text-twitter" target="_blank">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    : null }
                    {item.v_linkedin ? 
                      <li className="list-item">
                        <a href={item.linkedin} className="text-linkedin" target="_blank">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                    : null}
                    {item.youtube ? 
                      <li className="list-item">
                        <a href={item.youtube} className="text-youtube" target="_blank">
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                    : null}
                    </ul>
                  </div>
                </div>
                <div className="card-footer bg-light">
                  <div className="row text-center">
                    <div className="col">
                      <h6 className="mb-1">email</h6>
                      <p className="mb-0">{item.email}</p>
                    </div>
                    <div className="col">
                      <h5 className="mb-1">{item.nbrblogs}</h5>
                      <p className="mb-0">Blogs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ))
        }

      </div>
    </>
  );
};

export default Bloggers;
