import axios from "axios";
import authHeader from "./auth-header";

const role = sessionStorage.getItem("role") || '';

export const UpdateAchat = (data) => {
    return axios.patch(window.API_URL + `/${role.toLowerCase()}/achat-maintenant`, data, authHeader());
};

export const ReadAchat = () => {
    return axios.get(window.API_URL + `/${role.toLowerCase()}/achat-maintenant`, {
        headers : authHeader().headers
    });
};

export const ReadAchatDetails = (id) => {
    return axios.get(window.API_URL + `/${role.toLowerCase()}/achat-maintenant/details`, {
        params : {
            id
        },
        headers : authHeader().headers
    });
};

export const DeleteAchat = (data) => {
    return axios.delete(window.API_URL + `/${role.toLowerCase()}/achat-maintenant`,
    {
        data,
        headers : authHeader()?.headers
    });
};