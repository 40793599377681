import React, { useEffect, useState, useRef } from "react";
import ClinetService from "../../../services/client.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
import dataTableScripts from "../../inc/scripts/dataTableScripts";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import AuthService from "../../../services/auth.service";
import TextField from "@material-ui/core/TextField";
import Moment from "react-moment";
import MenuItem from "@material-ui/core/MenuItem";
import withReactContent from "sweetalert2-react-content";
import { ClipLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const MySwal = withReactContent(Swal);

const Clients = () => {
  const form = useRef(null);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState();
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [client, setClient] = useState({});
  const [update, setUpdate] = useState(false);

  const [isPro, setIsPro] = useState(false);
  const [isClinic, setIsClinic] = useState(false);

  const [avatarFile, setAvatarFile] = useState();
  const [fName, setFName] = useState(null);
  const [id, setId] = useState(null);
  const [sName, setSName] = useState();
  const [gender, setGender] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [adresse, setAdresse] = useState();
  const [civ, setCiv] = useState();
  const [is_pro, setIs_Pro] = useState();
  const [category, setCategory] = useState();
  const [CNSS, setCNSS] = useState();
  const [IDF, setIDF] = useState();
  const [TP, setTP] = useState();
  const [RC, setRC] = useState();
  const [ice, setice] = useState();
  const [nameDrgerant, setNameDrgerant] = useState();
  const [personne_contact, setPersonne_contact] = useState();
  const [raison_sociale, setRaison_sociale] = useState();
  const [siege_social, setSiege_social] = useState();
  const [active, setActive] = useState();
  const [formdata, setformdata] = useState();

  const handelSubmit = (e) => {
    e.preventDefault();
    // setLoadingSubmit(true)

    const data = new FormData(form.current);
    const simpleData = Object.fromEntries(data.entries());
    console.log(simpleData);
    // if (simpleData.avatar.size > 0) {
    //   setLoadingSubmit(true);
    //   // Update User Avatar
    //   ClinetService.updateClientAvatar(data)
    //     .then((res) => {
    //       setLoadingSubmit(false);
    //       NotificationManager.success(
    //         "L'avatar du client est modifié avec succee",
    //         "",
    //         2000
    //       );
    //     })
    //     .catch(function (error) {
    //       setLoadingSubmit(false);
    //       // console.log(error)
    //       if (!error.response) {
    //         NotificationManager.error(
    //           "Votre session est expiree, veuillez vous reconnectez",
    //           "",
    //           4000
    //         );
    //       } else if (error.response.status === 403) {
    //         setLoading(false);
    //         error.response.data.error
    //           ? NotificationManager.warning(error.response.data.error, "", 2000)
    //           : NotificationManager.warning(
    //               "Could not update client password",
    //               "",
    //               2000
    //             );

    //         // console.log(error.response.data);
    //         // console.log(error.response.status);
    //         // console.log(error.response.headers);
    //       } else if (error.response.status === 401) {
    //         setLoading(false);
    //         NotificationManager.error(
    //           "Votre session est expiree, veuillez vous reconnectez",
    //           "",
    //           4000
    //         );
    //         setTimeout(() => {
    //           AuthService.logout();
    //         }, 500);
    //       }
    //     });
    // }

    setLoadingSubmit(true);
    // Update User Avatar
    ClinetService.updateClient(simpleData)
      .then((res) => {
        NotificationManager.success(
          "Le client est modifié avec succee",
          "",
          2000
        );
        setLoadingSubmit(false);
        setTimeout(() => {
          window.location.href = "/clients";
        }, 500);
      })
      .catch(function (error) {
        setLoadingSubmit(false);
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
              error.response.data.error
                ? error.response.data.error
                : error.response.data.message,
              "",
              2000
            )
            : NotificationManager.warning(
              "Impossible de mettre à jour l'avatar du client",
              "",
              2000
            );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  let changePwSwal = {
    title: "Modifier le mot de passe?",
    focusConfirm: false,
    html: `
    <input class="swal2-input" id="password" type="password" placeholder="Enter your new password..." /><br />
    <input class="swal2-input" id="confirmPassword" type="password" placeholder="Confirm your new password..." />
  `,
    type: "warning",
    showCancelButton: true,
    cancelButtonColor: "grey",
    confirmButtonText: "Modifier",
    allowOutsideClick: false,
    preConfirm: () => ({
      password: document.getElementById("password").value,
      passwordConfirm: document.getElementById("confirmPassword").value,
      id: id,
    }),
  };

  const handleResetPassword = () => {
    const resetPw = async () => {
      const swalval = await MySwal.fire(changePwSwal);
      let v = (swalval && swalval.value) || swalval.dismiss;
      if ((v && v.id && v.password && v.passwordConfirm) || v === "cancel") {
        if (v.password !== v.passwordConfirm) {
          await MySwal.fire({
            type: "error",
            title: "Les mots de passe ne correspondent pas!",
          });
          resetPw();
        } else if (v !== "cancel") {
          setformdata(swalval);
          // console.log(swalval.value)
          ClinetService.updateClientPassword(swalval.value)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Succès!",
                "Le mot de passe a été modifié avec succès.",
                "success"
              );
            })
            .catch(function (error) {
              setLoadingSubmit(false);
              // console.log(error)
              if (!error.response) {
                NotificationManager.error(
                  "Votre session est expiree, veuillez vous reconnectez",
                  "",
                  4000
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error || error.response.data.message
                  ? NotificationManager.warning(
                    error.response.data.error
                      ? error.response.data.error
                      : error.response.data.message,
                    "",
                    2000
                  )
                  : NotificationManager.warning(
                    "Impossible de mettre à jour le mot de passe client",
                    "",
                    2000
                  );

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.response.status === 401) {
                setLoading(false);
                NotificationManager.error(
                  "Votre session est expiree, veuillez vous reconnectez",
                  "",
                  4000
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        }
      } else {
        await MySwal.fire({
          type: "error",
          title: "Tous les champs sont requis!!",
        });
        resetPw();
      }
    };

    resetPw();
  };

  const renderAction = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <button
          data-toggle="modal"
          data-target="#modal-detail"
          className="btn btn-icon btn-outline-primary"
          id={row.id}
          onClick={getClientDetail}
        >
          <i className="feather icon-eye" id={row.id} />
        </button>
        <button
          data-toggle="modal"
          data-target="#modal-detail"
          className="btn btn-icon btn-outline-success my-2"
          id={row.id}
          onClick={getClientUpdate}
        >
          <i className="fas fa-edit" id={row.id}></i>
        </button>

        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          id={row.id}
          onClick={deleteClient}
        >
          <i className="far fa-trash-alt" id={row.id}></i>
        </button>
      </div>
    );
  };

  const [columns, setColumns] = useState([
    {
      name: "Numero Client",
      selector: "id",
      sortable: true,
      width:"75px"
    },
    {
      selector: "civ",
      name: "Civilité",
      width: "60px",
      sortable: false,
    },
    {
      selector: "gender",
      name: "Genre",
      width: "120px",
    },
    {
      selector: "name",
      name: "Nom",
      width: "auto",
      sortable: false,
      wrap: true
    },
    {
      selector: "surname",
      name: "Prenom",
      width: "130px",
      sortable: false,
      wrap: true
    },
    {
      selector: "email",
      name: "Email",
      width: "190px",
      sortable: false,
      wrap: true
    },
    {
      selector: "phone",
      name: "Téléphone",
      width: "130px",
      sortable: false,
    },
    {
      selector: "adresse",
      name: "Adresse",
      width: "auto",
      sortable: false,
      wrap: true
    },
    {
      // selector: "action",
      name: "Action",
      // type: 'number',
      width: "200px",
      cell: (row) => renderAction(row),
    },
  ]);

  const tableData = {
    columns,
    data: clients,
  };

  useEffect(() => {
    setLoading(true);
    ClinetService.allClients()
      .then((res) => {
        setClients(res.data.results);
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
              "Impossible de charger la liste des clients",
              "",
              2000
            );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });

    // dataTableScripts.mountScripts();

    // return () => {
    //   dataTableScripts.unMountScripts();
    // };
  }, []);

  const getDate = (timestamp) => {
    var date = new Date(timestamp);

    return (
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds()
    );
  };

  const getClientDetail = (event) => {
    setLoadingData(true);
    const clientId = event.target.id;
    setUpdate(false);
    ClinetService.getClientById(clientId)
      .then((res) => {
        setLoadingData(false);
        console.log(res.data.result[0]);
        setClient(res.data.result[0]);

        setFName(res.data.result[0].name);
        setSName(res.data.result[0].surname);
        setEmail(res.data.result[0].email);
        setGender(res.data.result[0].gender);
        setPhone(res.data.result[0].phone);
        setAdresse(res.data.result[0].adresse);
        setCiv(res.data.result[0].civ);
        setIs_Pro(res.data.result[0].is_pro);
        setCategory(res.data.result[0].category);
        setCNSS(res.data.result[0].CNSS);
        setIDF(res.data.result[0].IDF);
        setTP(res.data.result[0].TP);
        setRC(res.data.result[0].RC);
        setice(res.data.result[0].ice);
        setNameDrgerant(res.data.result[0].nameDrgerant);
        setPersonne_contact(res.data.result[0].personne_contact);
        setRaison_sociale(res.data.result[0].raison_sociale);
        setSiege_social(res.data.result[0].siege_social);
        setActive(res.data.result[0].active);
      })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
              "Could not load client list",
              "",
              2000
            );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };
  const getClientUpdate = (event) => {
    setLoadingData(true);
    setUpdate(true);
    const clientId = event.target.id;

    ClinetService.getClientById(clientId)
      .then((res) => {
        setLoadingData(false);
        console.log(res.data.result[0]);
        setClient(res.data.result[0]);
        setIsPro(res.data.result[0].is_pro);
        setIsClinic(res.data.result[0].category === "clinique");
        setGender(res.data.result[0].gender);
        setFName(res.data.result[0].name);
        setId(res.data.result[0].id);
        setSName(res.data.result[0].surname);
        setEmail(res.data.result[0].email);
        setPhone(res.data.result[0].phone);
        setAdresse(res.data.result[0].adresse);
        setCiv(res.data.result[0].civ);
        setIs_Pro(res.data.result[0].is_pro);
        setCategory(res.data.result[0].category);
        setCNSS(res.data.result[0].CNSS);
        setIDF(res.data.result[0].IDF);
        setTP(res.data.result[0].TP);
        setRC(res.data.result[0].RC);
        setice(res.data.result[0].ice);
        setNameDrgerant(res.data.result[0].nameDrgerant);
        setPersonne_contact(res.data.result[0].personne_contact);
        setRaison_sociale(res.data.result[0].raison_sociale);
        setSiege_social(res.data.result[0].siege_social);
        setActive(res.data.result[0].active);
      })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
              "Impossible de charger les informations client",
              "",
              2000
            );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  const deleteClient = (event) => {
    const clientId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "Vous ne pourrez pas rétablir ce client !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez-le!",
        cancelButtonText: "Non, annuler!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          ClinetService.delteteClient(clientId).then((res) => {
            // console.log(res)
            setTimeout(() => {
              ClinetService.allClients().then((res) => {
                // console.log(res)
                setClients(res.data.results);
              });
            }, 300);
            swalWithBootstrapButtons.fire(
              "Supprimé!",
              "Le client a été supprimé.",
              "success"
            );
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "Le client n'a pas été supprimé.",
            "error"
          );
        }
      })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
              "Impossible de charger la liste des clients",
              "",
              2000
            );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  function getExtension(filename) {
    try {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    } catch (error) {
      return "";
    }
  }

  function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
        return true;
      case "jpeg":
        return true;
      case "png":
        return true;
      default:
        return false;
    }
  }

  const changeHandler = (event) => {
    if (!isImage(event.target.files[0].name)) {
      NotificationManager.error("Avatar doit être une image", "", 2000);
    } else {
      setAvatarFile(event.target.files[0] ? event.target.files[0] : null);
    }
  };

  return (
    <>
      <WichPage pageTitle="Clients" pageLocation="clients" />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center m-l-0">
                <div className="col-sm-6"></div>
              </div>
              <div style={{ height: "70vh", width: "100%" }}>
                {!loading ? (
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                    />
                  </DataTableExtensions>
                ) : (
                  <ClipLoader color={"#000000"} loading={loading} />
                )}
              </div>

              {/* <div className="table-responsive">
                <table
                  id="report-table"
                  className="table table-bordered table-striped mb-0"
                >
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Civilité</th>
                      <th>Nom et Prenom</th>
                      <th>Email</th>
                      <th>Téléphone</th>
                      <th>Sexe</th>
                      <th>Adresse</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients.map((client, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={client.avatar}
                            className="img-fluid img-radius wid-40"
                            alt=""
                          />
                        </td>
                        <td>{client.civ}</td>
                        <td>
                          {client.name} {client.surname}
                        </td>
                        <td>{client.email}</td>
                        <td>{client.phone}</td>
                        <td>{client.gender}</td>
                        <td>{client.adresse}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
        {/* customar project  end */}

        {/* START DETAIL CLIANT MODAL */}
        <div
          className="modal fade"
          id="modal-detail"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Detail Client</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form ref={form} onSubmit={handelSubmit}>
                  {loadingData ? (
                    <ClipLoader color={"#fffff"} loading={loadingData} />
                  ) : (
                    <>
                      <div className="row">
                        {/* <div className="col-12 d-flex align-items-center justify-content-center mb-4 flex-column">
                          <label htmlFor="" className="text-muted">
                            Avatar
                          </label>
                          {client.avatar ? (
                            <img
                              src={
                                window.IMAGE_API_URL + `avatar/` + client.avatar
                              }
                              alt={client.avatar}
                              width={100}
                              height={100}
                              style={{ borderRadius: "50%" }}
                            />
                          ) : (
                            <img
                              src={window.IMAGE_API_URL + "avatar/default.jpg"}
                              alt={"default"}
                              width={100}
                              height={100}
                              style={{ borderRadius: "50%" }}
                            />
                          )}
                        </div> */}
                        {/* {update ? (
                          <div className="col-md-6 col-sm-12 mb-2">
                            <TextField
                              id="avatar"
                              label="avatar"
                              className="w-100 mb-2"
                              name="avatar"
                              type="file"
                              onChange={changeHandler}
                            />
                          </div>
                        ) : null} */}
                        <div className="col-md-6 col-sm-12 mb-2">
                          <TextField
                            id="id"
                            label="ID"
                            className="w-100 mb-2"
                            name="id"
                            placeholder="id"
                            required
                            value={id}
                            inputProps={{ readOnly: true }}
                          />
                        </div>

                        <div className="col-md-6 col-sm-12 mb-2">
                          <TextField
                            id="fName"
                            label="Nom"
                            className="w-100 mb-2"
                            name="name"
                            required
                            value={fName}
                            inputProps={{ readOnly: update ? false : true }}
                            onChange={(e) => setFName(e.target.value)}
                          />
                        </div>

                        <div className="col-md-6 col-sm-12 mb-2">
                          <TextField
                            id="sName"
                            label="Prenom"
                            className="w-100 mb-2"
                            name="surname"
                            value={sName}
                            required
                            inputProps={{ readOnly: update ? false : true }}
                            onChange={(e) => setSName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                          <TextField
                            id="email"
                            label="Email"
                            className="w-100 mb-2"
                            name="email"
                            value={email}
                            inputProps={{ readOnly: update ? false : true }}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                          <TextField
                            id="phone"
                            label="Téléphone"
                            className="w-100 mb-2"
                            name="phone"
                            value={phone}
                            inputProps={{ readOnly: update ? false : true }}
                            required
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                          <TextField
                            id="adresse"
                            label="Adresse"
                            className="w-100 mb-2"
                            name="adresse"
                            value={adresse}
                            multiple
                            inputProps={{ readOnly: update ? false : true }}
                            required
                            onChange={(e) => setAdresse(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                          <div className="form-group">
                            <label className="" htmlFor="civ">
                              Civilité
                            </label>
                            <select
                              className="form-control"
                              id="civ"
                              name="civ"
                              disabled={update ? false : true}
                              onChange={(e) => setCiv(e.target.value)}
                            >
                              <option
                                key="Mr"
                                value="Mr"
                                selected={civ === "Mr" ? true : false}
                              >
                                Mr
                              </option>
                              <option
                                key="Mme"
                                value="Mme"
                                selected={civ === "Mme" ? true : false}
                              >
                                Mme
                              </option>
                              <option
                                key="Mlle"
                                value="Mlle"
                                selected={civ === "Mlle" ? true : false}
                              >
                                Mlle
                              </option>
                              <option
                                key="Dr"
                                value="Dr"
                                selected={civ === "Dr" ? true : false}
                              >
                                Dr
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                          <div className="form-group">
                            <label className="" htmlFor="gender">
                              Genre
                            </label>
                            <select
                              className="form-control"
                              id="gender"
                              name="gender"
                              disabled={update ? false : true}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option
                                key="homme"
                                value="homme"
                                selected={gender === "homme" ? true : false}
                              >
                                Homme
                              </option>
                              <option
                                key="Femme"
                                value="Femme"
                                selected={gender === "Femme" ? true : false}
                              >
                                Femme
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 mb-2">
                          {/* <TextField
                        id="is_pro"
                        label="Professional"
                        className="w-100 mb-2"
                        name="is_pro"
                        value={client.is_pro ? 'Oui' : 'Non'}
                        inputProps={
                          { readOnly: update ? false :  true, }
                        }
                      /> */}
                          <div className="form-group">
                            <label className="" htmlFor="is_pro">
                              Professional
                            </label>
                            <select
                              className="form-control"
                              id="is_pro"
                              name="is_pro"
                              disabled={update ? false : true}
                              onChange={(e) => {
                                e.target.value === "0"
                                  ? setIsPro(false)
                                  : setIsPro(true);
                                setIs_Pro(e.target.value);
                              }}
                            >
                              <option
                                key="0"
                                value="0"
                                selected={
                                  update && !client.is_pro ? true : false
                                }
                              >
                                Non
                              </option>
                              <option
                                key="1"
                                value="1"
                                selected={
                                  update && client.is_pro ? true : false
                                }
                              >
                                Oui
                              </option>
                            </select>
                          </div>
                        </div>
                        {update && isPro ? (
                          <>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <div className="form-group">
                                <label className="" htmlFor="category">
                                  Categorie
                                </label>
                                <select
                                  className="form-control"
                                  id="category"
                                  name="category"
                                  disabled={update ? false : true}
                                  onChange={(e) => setCategory(e.target.value)}
                                >
                                  <option
                                    key="clinique"
                                    value="clinique"
                                    selected={
                                      category === "clinique" ? true : false
                                    }
                                  >
                                    Clinique
                                  </option>
                                  <option
                                    key="medecin"
                                    value="medecin"
                                    selected={
                                      category === "medecin" ? true : false
                                    }
                                  >
                                    Medecin
                                  </option>
                                  <option
                                    key="pharmacie"
                                    value="pharmacie"
                                    selected={
                                      category === "pharmacie" ? true : false
                                    }
                                  >
                                    Pharmacie
                                  </option>
                                  <option
                                    key="laboratoire"
                                    value="laboratoire"
                                    selected={
                                      category === "laboratoire" ? true : false
                                    }
                                  >
                                    Laboratoire
                                  </option>
                                  <option
                                    key="parapharmacie"
                                    value="parapharmacie"
                                    selected={
                                      category === "parapharmacie"
                                        ? true
                                        : false
                                    }
                                  >
                                    Parapharmacie
                                  </option>
                                  <option
                                    key="autre"
                                    value="autre"
                                    selected={
                                      category === "autre" ? true : false
                                    }
                                  >
                                    Autre
                                  </option>
                                </select>
                              </div>


                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="CNSS"
                                label="CNSS"
                                className="w-100 mb-2"
                                name="CNSS"
                                value={CNSS}
                                inputProps={{ readOnly: update ? false : true }}
                                onChange={(e) => setCNSS(e.target.value)}
                              />
                            </div>
                            {
                              category === "clinique" ? 
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="nameDrgerant"
                                label="Nom de gerant"
                                className="w-100 mb-2"
                                name="nameDrgerant"
                                value={nameDrgerant}
                                inputProps={
                                  { readOnly: update ? false : true, }
                                }

                                onChange={(e) => (setNameDrgerant(e.target.value))}
                              />
                            </div> : null
                            }
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="IDF"
                                label="IDF"
                                className="w-100 mb-2"
                                name="IDF"
                                value={IDF}
                                inputProps={{ readOnly: update ? false : true }}
                                onChange={(e) => setIDF(e.target.value)}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="TP"
                                label="TP"
                                className="w-100 mb-2"
                                name="TP"
                                value={TP}
                                inputProps={{ readOnly: update ? false : true }}
                                onChange={(e) => setTP(e.target.value)}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="RC"
                                label="RC"
                                className="w-100 mb-2"
                                name="rc"
                                value={RC}
                                inputProps={{ readOnly: update ? false : true }}
                                onChange={(e) => setRC(e.target.value)}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="ice"
                                label="ice"
                                className="w-100 mb-2"
                                name="ice"
                                value={ice}
                                inputProps={{ readOnly: update ? false : true }}
                                onChange={(e) => setice(e.target.value)}
                              />
                            </div>
                            {/* {isClinic ? (
                              <div className="col-md-6 col-sm-12 mb-2">
                                <TextField
                                  id="nameDrgerant"
                                  label="Nom Dr Gerant"
                                  className="w-100 mb-2"
                                  name="nameDrgerant"
                                  value={nameDrgerant}
                                  inputProps={{
                                    readOnly: update ? false : true,
                                  }}
                                  onChange={(e) =>
                                    setNameDrgerant(e.target.value)
                                  }
                                />
                              </div>
                            ) : null} */}

                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="personne_contact"
                                label="Personne a contacter"
                                className="w-100 mb-2"
                                name="personne_contact"
                                value={personne_contact}
                                inputProps={{ readOnly: update ? false : true }}
                                onChange={(e) =>
                                  setPersonne_contact(e.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="raison_sociale"
                                label="Raison Social"
                                className="w-100 mb-2"
                                name="raison_sociale"
                                value={raison_sociale}
                                inputProps={{ readOnly: update ? false : true }}
                                onChange={(e) =>
                                  setRaison_sociale(e.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="siege_social"
                                label="Siege Social"
                                className="w-100 mb-2"
                                name="siege_social"
                                value={siege_social}
                                inputProps={{ readOnly: update ? false : true }}
                                onChange={(e) =>
                                  setSiege_social(e.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="created_at"
                                label="Date d'inscription"
                                className="w-100 mb-2"
                                name="created_at"
                                value={getDate(client.created_at)}
                                inputProps={{ readOnly: true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="last_login"
                                label="Dernière connexion"
                                className="w-100 mb-2"
                                name="last_login"
                                value={getDate(client.last_login)}
                                inputProps={{ readOnly: true }}
                              />
                            </div>
                          </>
                        ) : client.is_pro && !update ? (
                          <>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="category"
                                label="Categorie"
                                className="w-100 mb-2"
                                name="category"
                                value={category}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="CNSS"
                                label="CNSS"
                                className="w-100 mb-2"
                                name="CNSS"
                                value={CNSS}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="IDF"
                                label="IDF"
                                className="w-100 mb-2"
                                name="IDF"
                                value={IDF}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="TP"
                                label="TP"
                                className="w-100 mb-2"
                                name="TP"
                                value={TP}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="RC"
                                label="RC"
                                className="w-100 mb-2"
                                name="rc"
                                value={RC}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="ice"
                                label="ice"
                                className="w-100 mb-2"
                                name="ice"
                                value={ice}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            {client.category === "clinique" ? (
                              <div className="col-md-6 col-sm-12 mb-2">
                                <TextField
                                  id="nameDrgerant"
                                  label="Nom Dr Gerant"
                                  className="w-100 mb-2"
                                  name="nameDrgerant"
                                  value={nameDrgerant}
                                  inputProps={{
                                    readOnly: update ? false : true,
                                  }}
                                />
                              </div>
                            ) : null}

                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="personne_contact"
                                label="Personne a contacter"
                                className="w-100 mb-2"
                                name="personne_contact"
                                value={personne_contact}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="raison_sociale"
                                label="Raison Social"
                                className="w-100 mb-2"
                                name="raison_sociale"
                                value={raison_sociale}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="siege_social"
                                label="Siege Social"
                                className="w-100 mb-2"
                                name="siege_social"
                                value={siege_social}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="active"
                                label="Active"
                                className="w-100 mb-2"
                                name="active"
                                value={active === "1" ? "Oui" : "Non"}
                                inputProps={{ readOnly: update ? false : true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="created_at"
                                label="Date d'inscription"
                                className="w-100 mb-2"
                                name="created_at"
                                value={getDate(client.created_at)}
                                inputProps={{ readOnly: true }}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <TextField
                                id="last_login"
                                label="Dernière connexion"
                                className="w-100 mb-2"
                                name="last_login"
                                value={getDate(client.last_login)}
                                inputProps={{ readOnly: true }}
                              />
                            </div>
                          </>
                        ) : null}
                        <div className="col-md-6 col-sm-12 mb-2">
                          <div className="form-group">
                            <label className="" htmlFor="active">
                              Active
                            </label>
                            <select
                              className="form-control"
                              id="active"
                              name="active"
                              disabled={update ? false : true}
                              onChange={(e) => {
                                setActive(e.target.value);
                              }}
                            >
                              <option
                                key="0"
                                value="0"
                                selected={active === "0" ? true : false}
                              >
                                Non
                              </option>
                              <option
                                key="1"
                                value="1"
                                selected={active === "1" ? true : false}
                              >
                                Oui
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {update ? (
                    <div className="col-sm-12 text-right m-6">
                      {!loadingSubmit ? (
                        <div className="d-flex align-items-center justify-content-end">
                          <button
                            type="button"
                            className="btn btn-icon btn-outline-warning mx-3"
                            data-dismiss="modal"
                            onClick={handleResetPassword}
                          >
                            <i class="fas fa-lock"></i>
                          </button>
                          <button
                            type="submit"
                            className="btn btn-icon btn-outline-primary mx-3"
                          >
                            <i className="feather icon-save" />
                          </button>
                        </div>
                      ) : (
                        <ClipLoader loading={loadingSubmit} />
                      )}
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* END ADD CLIENT MODAL */}
      </div>
    </>
  );
};

export default Clients;
