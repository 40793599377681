import axios from "axios";

const allCategories = () => {
     return axios.get(window.API_URL + "/categories");
}

const getCategoryById = (id) => {
     return axios.get(`${window.API_URL}/categorie/:${id}`);
}

const role = sessionStorage.getItem('role');

const addCategory = (idAdmin, formData, token) => {
     const config = { headers: { "Authorization": `Bearer ${token}` } }
     // console.log(formData.get("image"))
     const data = formData
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/categorie/${idAdmin}`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/categorie/${idAdmin}`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/categorie/${idAdmin}`
     }
     return axios.post(URL, data, config)
}

const updateCategory = (formData, token) => {
     // return console.log(formData.get("name"), formData.get("image"))
     // var URL = ''
     // const config = { headers: { "Authorization": `Bearer ${token}` } }
     // const data = formData
     // if(formData.get("image"))
     // {

     // }
     // if (role === "Admin") {
     //      URL = `${window.API_URL}/admin/categorie`
     // }
     // else if (role === "Commercial") {
     //      URL = `${window.API_URL}/commercial/categorie`
     // }
     // else if (role === "Moderateur_Commercial") {
     //      URL = `${window.API_URL}/modcommercial/categorie`
     // }
     // return axios.patch(URL, data, config)
}
const deleteCategory = (id, token) => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/categorie/${id}`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/categorie/${id}`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/categorie/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               }
          });

}

const CategoryService = {
     allCategories,
     getCategoryById,
     addCategory,
     updateCategory,
     deleteCategory
}

export default CategoryService;