import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";


const user = AuthService.getCurrentUser()
const role = sessionStorage.getItem('role');


const allAdmins = () => {
    return axios.get(window.API_URL + "/admin/admins", authHeader());
}

const addAdmin = (data) => {
    // console.log(data)
    return axios.post(window.API_URL + "/admin/add_admin", data, authHeader())
}
const allSubscribers = () => {
    return axios.get(window.API_URL + "/admin/subscribers", authHeader())
}

const updateAdminRole = (data) => {
    return axios.patch(`${window.API_URL}/admin/admin_priviligies`, data, authHeader())
}


const updateAvatar = (data) => {

    var URL = ""
    if (role === "Admin") {
        URL = `${window.API_URL}/admin/update_avatradmin`
    }
    else if (role === "Redacteur") {
        URL = `${window.API_URL}/redacteur/update_avatradmin`
    }
    else if (role === "Moderateur") {
        URL = `${window.API_URL}/moderateur/update_avatradmin`
    }
    else if (role === "Commercial") {
        URL = `${window.API_URL}/commercial/update_avatradmin`
    }
    else if (role === "ModCommercial") {
        URL = `${window.API_URL}/modcommercial/update_avatradmin`
    }

    return axios.patch(URL, data, authHeader())
}

const deleteAdmin = (idAdmin) => {
    return axios.delete(`${window.API_URL}/admin/delete_admin/${idAdmin}`, authHeader())
}

const adminAcount = () => {
    var URL = ""
    if (role === "Admin") {
        URL = `${window.API_URL}/admin/admin/${user.id}`
    }
    else if (role === "Redacteur") {
        URL = `${window.API_URL}/redacteur/admin/${user.id}`
    }
    else if (role === "Moderateur") {
        URL = `${window.API_URL}/moderateur/admin/${user.id}`
    }
    else if (role === "Commercial") {
        URL = `${window.API_URL}/commercial/admin/${user.id}`
    }
    else if (role === "ModCommercial") {
        URL = `${window.API_URL}/modcommercial/admin/${user.id}`
    }

    return axios.get(URL, authHeader())
}
const updatePersonalInfo = (data) => {
    var URL = ""
    if (role === "Admin") {
        URL = `${window.API_URL}/admin/update_admin`
    }
    else if (role === "Redacteur") {
        URL = `${window.API_URL}/redacteur/update_admin`
    }
    else if (role === "Moderateur") {
        URL = `${window.API_URL}/moderateur/update_admin`
    }
    else if (role === "Commercial") {
        URL = `${window.API_URL}/commercial/update_admin`
    }
    else if (role === "ModCommercial") {
        URL = `${window.API_URL}/modcommercial/update_admin`
    }
    return axios.patch(URL, data, authHeader())
}

const updatePassword = (data) => {
    return axios.patch(`${window.API_URL}/admin/update_adminpwd/${user.id}`, data, authHeader())
}
const updatePasswordOfAdmin = (data) => {
    return axios.patch(`${window.API_URL}/admin/update_adminpwd/${data.id}`, data, authHeader())
}
const removeSubscriber = (id, token) => {
    var URL = ''
    if (role === "Admin") {
        URL = `${window.API_URL}/admin/subscriber/${id}`
    }
    else if (role === "Moderateur") {
        URL = `${window.API_URL}/moderateur/subscriber/${id}`
    }
    return axios.delete(URL,
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });

}
const removeMultiSubscribers = (data) => {
    var URL = ''
    if (role === "Admin") {
        URL = `${window.API_URL}/admin/multi_subscribers`
    }
    else if (role === "Moderateur") {
        URL = `${window.API_URL}/moderateur/multi_subscribers`
    }
    return axios.post(URL,data, authHeader())

}

const AdminService = {
    allAdmins,
    addAdmin,
    allSubscribers,
    updateAdminRole,
    deleteAdmin,
    adminAcount,
    updatePassword,
    updatePersonalInfo,
    removeSubscriber,
    updateAvatar,
    removeMultiSubscribers,
    updatePasswordOfAdmin
}
export default AdminService;