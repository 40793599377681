import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import ProductService from "../../../services/product.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
// import dataTableScripts from "../../inc/scripts/dataTableScripts";
// import UploadCatalogue from "./UploadCatalogue";
// import { ClipLoader } from "react-spinners";
// import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Moment from "react-moment";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import TextField from "@material-ui/core/TextField";
import { ClipLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button } from "@material-ui/core";
import { useLocation, useHistory} from "react-router-dom";

const Product = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const location = useLocation();
  const history = useHistory();
  const pageId = 
  (() => {
    let id = new URLSearchParams(location.search).get('page_id');
    if (!id || id === '' || !/^[\d]{1,100}$/.test(id) || parseInt(id) === 0)
      return 1;
    return parseInt(id);
  })();
  const [products, setproducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pourcentage, setPourcentage] = useState(null);
  const [date_debutsolde, setDateDebut] = useState(null);
  const [date_finsolde, setDateFin] = useState(null);
  const [id_product, setid] = useState(null);
  const [color, setColor] = useState("#00000");
  const [disabled, setDisabled] = useState(false);

  const renderProductImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + "products/" + row.image.split(";")[0]}
        alt={row.image.split(";")[0]}
        width={130}
      />
    );
  };

  const UpdateMontionSpecial = async (row, mention_speciale) => {
    ProductService.updateProductMentionSpecial({id : row.id, mention_speciale})
    .then((result) => {
      setproducts((old) => old.map(val => val.id === row.id ? {...val, 'mention_spéciale' : mention_speciale} : val))
      NotificationManager.success(
        "Product updated successfully",
        "",
        4000
      );
    })
    .catch(function (error) {
      NotificationManager.error(
        error?.response?.data?.erreur || error?.response?.data?.error || error?.response?.data?.message || error?.message,
        "",
        4000
      );
    });
  }

  const UpdatePlusEtonnant = async (row, plus_etonnants) => {
    ProductService.updateProductPLusett({id : row.id, plus_etonnants})
    .then((result) => {
      setproducts((old) => old.map(val => val.id === row.id ? {...val, 'plus_etonnants' : plus_etonnants} : val))
      NotificationManager.success(
        "Product updated successfully",
        "",
        4000
      );
    })
    .catch(function (error) {
      NotificationManager.error(
        error?.response?.data?.erreur || error?.response?.data?.error || error?.response?.data?.message || error?.message,
        "",
        4000
      );
    });
  }

  const renderproductActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <Link
          to={"/DetailsProduit/" + row.id}
          className="btn btn-icon btn-outline-primary my-2"
        >
          <i className="feather icon-eye" />
        </Link>
        <Link
          to={"/modifierProduit/" + row.id}
          className="btn btn-icon btn-outline-success"
        >
          <i className="feather icon-edit" />
        </Link>
        {/* <Link
          to={"/addtoSolde/" + params.row.id}
          className="btn btn-icon btn-outline-warning"
        >
          <i class="fas fa-dollar-sign"></i>
        </Link> */}
        <button
          id={row.id}
          data-toggle="modal"
          data-target="#modal-addToSolde"
          className="btn btn-icon btn-outline-warning"
          onClick={(e) => setid(e.target.id)}
        >
          <i className="fas fa-dollar-sign" id={row.id}></i>
        </button>
        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          id={row.id}
          onClick={deleteProduct}
        >
          <i className="far fa-trash-alt" id={row.id}></i>
        </button>

        {/* <button
          to="#"
          className="btn btn-icon btn-outline-secondary"
          data-toggle="modal"
          data-target="#cata1"
        >
          <i className="feather icon-upload" />
        </button> */}
        {/* <UploadCatalogue id={product} /> */}
      </div>
    );
  };

  const renderProductDate = (row) => {
    return (
      // <img
      //   src={window.IMAGE_API_URL + 'products/' + params.row.image.split(';')[0]}
      //   alt={params.row.image.split(';')[0]}
      //   width={150}
      // />
      <div className="d-flex w-100 align-items-center justify-content-start">
        <Moment format="D MMM YYYY" withTitle>
          {row.created_at}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{row.created_at}</Moment> )
        </div>
      </div>
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "id", width: "90px" },
    {
      selector: "image",
      name: "Image",
      width: "200px",
      cellExport : (row) => row.images,
      cell: (row) => renderProductImage(row),
    },
    {
      selector: "name",
      name: "Titre",
      width: "180px",
      wrap: true,
    },
    {
      selector: "category",
      name: "Catégorie",
      width: "200px",
      wrap: true,
    },
    {
      selector: "created_at",
      name: "Date d'ajout",
      type: "date",
      width: "250px",

      cell: (row) => renderProductDate(row),
    },
    {
      selector: "count",
      name: "Nombre de ventes",
      // type: 'number',
      width: "auto",
    },
    {
      // selector: "count",
      name: "COVID-19",
      // type: 'number',
      width: "200px",
      cellExport : (row) => '',
      cell : (row) => 
      <Button variant="outlined"
      style={{fontSize : 12, fontWeight : 'bold'}}
      onClick={(e) => UpdateMontionSpecial(row, row['mention_spéciale'] === 'Covid-19' ? '' : 'Covid-19')}
      >{
        row['mention_spéciale'] === 'Covid-19' ? 'remove' : 'add'
      }</Button>
    },
    {
      // selector: "count",
      name: "Plus etonnant",
      // type: 'number',
      width: "200px",
      cellExport : (row) => '',
      cell : (row) => 
      <Button variant="outlined"
      style={{fontSize : 12, fontWeight : 'bold'}}
      onClick={(e) => UpdatePlusEtonnant(row, row['plus_etonnants'] == 1 ? '0' : '1')}
      >{
        row['plus_etonnants'] == 1 ? 'remove' : 'add'
      }</Button>
    },
    {
      // selector: "count",
      name: "Detail Slider",
      width: "200px",
      cellExport : (row) => '',
      cell : (row) => 
      <Link to={`/manage-slider/${row.id}`}>
        <Button variant="outlined"
        style={{fontSize : 12, fontWeight : 'bold'}}
        >{
          'Manage'
        }</Button>
      </Link>
    },
    {
      selector: "action",
      name: "Action",
      // type: 'number',
      width: "250px",
      sortable: false,
      cell: (row) => renderproductActions(row),
      cellExport : (row) => ''
    },
  ]);
  const tableData = {
    columns,
    data: products,
  };

  const deleteProduct = (event) => {
    const token = sessionStorage.getItem("token");
    const productId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "Vous ne pourrez pas revenir sur ce produit !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez-le",
        cancelButtonText: "Non, annulez !",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          ProductService.deleteProduct(productId, token)
            .then((result) => {
              setTimeout(() => {
                ProductService.allProduct().then(async (res) => {
                  setproducts(res.data.results[0]);
                  setLoading(false);
                });
              }, 300);

              swalWithBootstrapButtons.fire(
                "Supprimé !",
                "Le produit a été supprimé.",
                "success"
              );
            })
            .catch(function (error) {
              if (!error.response) {
                swalWithBootstrapButtons.fire(
                  "Annulé",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "error"
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? swalWithBootstrapButtons.fire(
                    "Annulé",
                    error.response.data.error,
                    "error"
                  )
                  : swalWithBootstrapButtons.fire(
                    "Annulé",
                    "Impossible de supprimer ce produit",
                    "error"
                  );
              } else if (error.response.status === 401) {
                setLoading(false);
                swalWithBootstrapButtons.fire(
                  "Annulé",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "error"
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "Le produit n'a pas été supprimé.",
            "error"
          );
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    ProductService.allProduct().then(async (res) => {
      console.log(res.data.results);
      setproducts(res.data.results[0]);
      setLoading(false);
      // console.log(window.IMAGE_API_URL);
    });

    // dataTableScripts.mountScripts();

    // return () => {
    //   dataTableScripts.unMountScripts();
    // };
  }, []);

  // ADD PRODUCT TO SOLDE

  const ajouterProductToSolde = () => {
    const userId = sessionStorage.getItem("id_user");
    console.log(userId);
    setLoading(true);
    // const userId = sessionStorage.getItem("id_user");
    var formSolde = {
      id_product: id_product,
      pourcentage: pourcentage,
      date_debutsolde: date_debutsolde,
      date_finsolde: date_finsolde,
      userId: userId,
    };

    if (pourcentage === "" || date_debutsolde === "" || date_finsolde === "") {
      NotificationManager.warning("Please check all the inputs", "", 2000);
      setLoading(false);
    } else {
      ProductService.verifierSolde(id_product)
        .then((success) => {
          ProductService.addProductToSolde(formSolde)
            .then((res) => {
              // setDisabled(true);
              NotificationManager.success(
                "product added successfully to solde",
                "",
                2000
              );
              setLoading(false);
            })
            .catch(function (error) {
              if (!error.response) {
                NotificationManager.error(
                  "Votre session est expiree, veuillez vous reconnectez",
                  "",
                  4000
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? NotificationManager.warning(
                    error.response.data.error,
                    "",
                    2000
                  )
                  : NotificationManager.warning(
                    "Could not add product",
                    "",
                    2000
                  );

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.response.status === 401) {
                setLoading(false);
                NotificationManager.error(
                  "Votre session est expiree, veuillez vous reconnectez",
                  "",
                  4000
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        })
        .catch(function (error) {
          swalWithBootstrapButtons
            .fire({
              title: "Attention !",
              text: "Ce produit figure deja en liste des soldes!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui, Renouvellez le solde!",
              cancelButtonText: "Non, Annuller!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                ProductService.renouvelerSolde(formSolde)
                  .then((res) => {
                    // setDisabled(true);
                    swalWithBootstrapButtons.fire(
                      "Renouvlez!",
                      "Le Nouveaux solde est enregistre.",
                      "success"
                    );
                    setLoading(false);
                  })
                  .catch(function (error) {
                    if (!error.response) {
                      NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                      );
                    } else if (error.response.status === 403) {
                      setLoading(false);
                      error.response.data.error
                        ? NotificationManager.warning(
                          error.response.data.error,
                          "",
                          2000
                        )
                        : NotificationManager.warning(
                          "Could not add product",
                          "",
                          2000
                        );
                    } else if (error.response.status === 401) {
                      setLoading(false);
                      NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                      );
                      setTimeout(() => {
                        AuthService.logout();
                      }, 500);
                    }
                  })
                  .catch(function (error) {
                    if (!error.response) {
                      swalWithBootstrapButtons.fire(
                        "Annulez",
                        "Votre session est expiree, veuillez vous reconnectez",
                        "error"
                      );
                    } else if (error.response.status === 403) {
                      setLoading(false);
                      error.response.data.error
                        ? swalWithBootstrapButtons.fire(
                          "Annulé",
                          error.response.data.error,
                          "error"
                        )
                        : swalWithBootstrapButtons.fire(
                          "Annulé",
                          "Impossible de supprimer ce produit",
                          "error"
                        );

                      // console.log(error.response.data);
                      // console.log(error.response.status);
                      // console.log(error.response.headers);
                    } else if (error.response.status === 401) {

                      swalWithBootstrapButtons.fire(
                        "Annulé",
                        "Votre session est expiree, veuillez vous reconnectez",
                        "error"
                      );
                      setLoading(false);
                      setTimeout(() => {
                        AuthService.logout();
                      }, 500);
                    }
                  });
              } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
              ) {
                swalWithBootstrapButtons.fire(
                  "Annulé",
                  "Le produit n'a pas été supprimé.",
                  "error"
                );
                setLoading(false);
              }
            });
        });
    }
  };

  return (
    <>
      <WichPage pageTitle="Produits" pageLocation="Produit" />

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5>Produits</h5>
              <div className="card-header-right">
                <h5>Ajouter un produit</h5>

                <Link
                  to="/ajouterProduit"
                  className="btn btn-icon btn-outline-primary"
                >
                  <i className="fas fa-plus-circle" />
                </Link>
              </div>
            </div>
            <div className="card-body shadow border-0">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    paginationDefaultPage={pageId}
                    onChangePage={(page) => history.push(`/produits?page_id=${page}`)}
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>

        {/* customar project  end */}
        {/*  START ADD PRODUCT TO SOLDE MODAL */}

        <div
          className="modal fade"
          id="modal-addToSolde"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ajouter le produit au solde</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <TextField
                      id="Name"
                      label="Pourcentage"
                      className="w-100"
                      // value={name}
                      onChange={(e) => setPourcentage(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      id="DateD"
                      label="DateDebut"
                      className="w-100"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      // value={name}
                      onChange={(e) => setDateDebut(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      id="DateF"
                      label="DateFin"
                      className="w-100"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      // value={name}
                      onChange={(e) => setDateFin(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-12 text-right mt-5">
                    {/* <button
                      className="btn btn-icon btn-outline-primary"
                      onClick={ajouterProductToSolde}
                    >
                      <i className="feather icon-save" />
                    </button> */}
                    {!loading ? (
                      <button
                        type="button"
                        className="btn btn-icon btn-outline-primary"
                        onClick={ajouterProductToSolde}
                        data-dismiss="modal"
                        // disabled={disabled}
                      >
                        <i className="feather icon-save" />
                      </button>
                    ) : (
                      <ClipLoader color={color} loading={loading} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  END ADD PRODUCT TO SOLDE */}
      </div>
    </>
  );
};

export default Product;
