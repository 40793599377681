import axios from "axios";


const allMarques = () => {
     return axios.get(window.API_URL + "/marques");
}

const getMarqueById = (id) => {
     return axios.get(`${window.API_URL}/marque/:${id}`);
}

const role = sessionStorage.getItem('role');

const addMarque = ( formData,idAdmin, token) => {
     // return console.log("name in service : " +formData.get("name"))
     const config = { headers: { "Authorization": `Bearer ${token}` } }
     const data = formData
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/add_marque/${idAdmin}`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/add_marque/${idAdmin}`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/add_marque/${idAdmin}`
     }
     return axios.post(URL, data, config)
}

const updateMarque = (idMarque, name, token) => {
     var URL = ''
     const config = { headers: { "Authorization": `Bearer ${token}` } }
     const data = { id: idMarque, name: name }
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/marque`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/marque`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/marque`
     }
     return axios.patch(URL, data, config)
}

const updateMarqueImage = (idMarque, image, token) => {
    var URL = ''
    const config = { headers: { "Authorization": `Bearer ${token}` } }
    const data = { id: idMarque, image:image  }
    if (role === "Admin") {
         URL = `${window.API_URL}/admin/image_marque`
    }
    else if (role === "Commercial") {
         URL = `${window.API_URL}/commercial/image_marque`
    }
    else if (role === "Moderateur_Commercial") {
         URL = `${window.API_URL}/modcommercial/image_marque`
    }
    return axios.patch(URL, data, config)
}

const deleteMarque = (id, v_admin, token) => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/marque/${id}`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/marque/${id}`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/marque/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               },
               data: {
                    v_admin: v_admin
               }
          });

}

const MarqueService = {
     allMarques,
     getMarqueById,
     addMarque,
     updateMarque,
     updateMarqueImage,
     deleteMarque
}

export default MarqueService;