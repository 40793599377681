import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

const user = AuthService.getCurrentUser()

const allProduct = () => {
     return axios.get(`${window.API_URL}/products`);
}
const ProductDetailSlider = (id) => {
     return axios.get(`${window.API_URL}/detail-slider`, {
          params : {
               product_id : id
          }
     });
}

const allProductEtonants = () => {
     return axios.get(`${window.API_URL}/product_plus_etonnants`);
}

const allProductCovid = () => {
     return axios.get(`${window.API_URL}/product_mnt_special`);
}

const getProductById = (id) => {
     return axios.get(`${window.API_URL}/product/${id}`)
}

const allProductInSolde = () => {
     return axios.get(window.API_URL + "/products_solde")
}

const allProductInPromoJr = () => {
     return axios.get(window.API_URL + "/promo_jour")
}

const addProduct = (formData) => {
     // return console.log(formData.get("nouveau_arrivage"))
     var URL = ""
     if (formData.get("nouveau_arrivage") === 'true') {
          if (user.role === "Admin") {
               URL = `${window.API_URL}/admin/add_arrivalProduct/${user.id}`
          }
          else if (user.role === "Commercial") {
               URL = `${window.API_URL}/commercial/add_arrivalProduct/${user.id}`
          }
          else if (user.role === "Moderateur_Commercial") {
               URL = `${window.API_URL}/modcommercial/add_arrivalProduct/${user.id}`
          }
     } else if (formData.get("nouveau_arrivage") === 'false') {
          if (user.role === "Admin") {
               URL = `${window.API_URL}/admin/add_product/${user.id}`
          }
          else if (user.role === "Commercial") {
               URL = `${window.API_URL}/commercial/add_product/${user.id}`
          }
          else if (user.role === "Moderateur_Commercial") {
               URL = `${window.API_URL}/modcommercial/add_product/${user.id}`
          }
     }
     return axios.post(URL, formData, authHeader())
}

const addAmazingProduct = (formData) => {
     var URL = ""
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/amazing/products/${user.id}`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/amazing/products/${user.id}`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/amazing/products/${user.id}`
     }
     return axios.post(URL, formData, authHeader())
}

const verifierSolde = (id_product) => {
     var URL = ''
     var data = { id: id_product}
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/verifier_solde`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/verifier_solde`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/verifier_solde`
     }
     return axios.post(URL, data, authHeader())
}

const verifierPromoJr = (solde_id) => {
     var URL = ''
     var data = { id: solde_id}
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/verifier_promo_jour`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/verifier_promo_jour`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/verifier_promo_jour`
     }
     return axios.post(URL, data, authHeader())
}
const addProductToSolde = (data) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/product_solde/${user.id}`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/product_solde/${user.id}`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/product_solde/${user.id}`
     }
     return axios.post(URL, data, authHeader())
}
const addProductToPromoJr = (data) => {
     var URL = ''
     // console.log(data)
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/add_promojr/${user.id}`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/add_promojr/${user.id}`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/add_promojr/${user.id}`
     }
     return axios.post(URL, data, authHeader())
}
const addProductToSubSliders = (data) => {
     var URL = ''
     // console.log(data)
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/add_sub_slider/${user.id}`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/add_sub_slider/${user.id}`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/add_sub_slider/${user.id}`
     }
     return axios.post(URL, data, authHeader())
}
const renouvelerSolde = (data) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/renouveler_sold/${user.id}`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/renouveler_sold/${user.id}`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/renouveler_sold/${user.id}`
     }
     return axios.post(URL, data, authHeader())
}


const updateProductSolde = (data) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/product_solde`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/product_solde`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/product_solde`
     }
     return axios.patch(URL, data, authHeader())
}

const updateProduct = (data) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/product/`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/product/`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/product/`
     }
     return axios.patch(URL, data, authHeader())
}

const updateProductMentionSpecial = (data) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/product_mnt_special`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/product_mnt_special`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/product_mnt_special`
     }
     return axios.patch(URL, data, authHeader())
}

const updateProductPLusett = (data) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/product_plus_etonnant`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/product_plus_etonnant`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/product_plus_etonnant`
     }
     return axios.patch(URL, data, authHeader())
}

const UpdateProductQuantity = (data) => {
     // var URL = ''
     // if (user.role === "Admin") {
     //      URL = `${window.API_URL}/admin/stock_quantity/${user.id}`
     // }
     // else if (user.role === "Commercial") {
     //      URL = `${window.API_URL}/commercial/stock_quantity/${user.id}`
     // }
     // else if (user.role === "Moderateur_Commercial") {
     //      URL = `${window.API_URL}/modcommercial/stock_quantity/${user.id}`
     // }
     // return axios.patch(URL, data, authHeader())
}
const updateCatalogueProduct = (formData) => {

     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/productCatalogue`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/productCatalogue`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/productCatalogue`
     }
     return axios.patch(URL, formData, authHeader())
}

const updateImagesProduct = (formData) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/productImage`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/productImage`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/productImage`
     }
     return axios.patch(URL, formData, authHeader())
}

const deleteProduct = (id, token) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/product/${id}`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/product/${id}`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/product/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               },               
          });

}
const removeProductSolde = (id, token) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/product_solde/${id}`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/product_solde/${id}`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/product_solde/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               },               
          });

}
const removeProductPromoJr = (id, token) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/promo_jour/${id}`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/promo_jour/${id}`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/promo_jour/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               },               
          });
}

const AddToDetailSlider = ({
     id,
     items
}) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/detail-slider`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/detail-slider`
     }
     else
          return false;
     return axios.post(URL,{
          id,
          items
     } ,{
          headers: {
               Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },               
     });
}

const ProductService = {
     AddToDetailSlider,
     allProduct,
     ProductDetailSlider,
     getProductById,
     addProduct,
     addProductToSolde,
     allProductInSolde,
     updateProduct,
     updateCatalogueProduct,
     updateImagesProduct,
     deleteProduct,
     verifierSolde,
     renouvelerSolde,
     removeProductSolde,
     updateProductSolde,
     allProductInPromoJr,
     addProductToPromoJr,
     removeProductPromoJr,
     verifierPromoJr,
     UpdateProductQuantity,
     addProductToSubSliders,
     addAmazingProduct,
     updateProductMentionSpecial,
     updateProductPLusett,
     allProductCovid,
     allProductEtonants
}

export default ProductService;