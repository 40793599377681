import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import { NotificationManager } from "react-notifications";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

const WichPage = (props) => {
  const toLinks = props.pageLocation.split("/");
  const [socket, setSocket] = useState(io(window.API_URL));

  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  socket.on("send-reclamation", (user, status) => {
    setMessage(
      `Nouvelle réclamation de type: ${status} par le client ${user.firstName} ${user.lastName}, consultez la page des réclamations`
    );
    setShow(true);
  });
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">{props.pageTitle}</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home"></i>
                  </a>
                </li>
                {toLinks.map((tolink, index) => (
                  <li key={index} className="breadcrumb-item">
                    <Link to={"/" + tolink}>
                      {tolink.charAt(0).toUpperCase() + tolink.slice(1)}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-bottom w-50 ml-auto mr-3">
        {show ? (
          <Alert show={show} variant="secondary">
            <Alert.Heading>Nouvelle réclamation!</Alert.Heading>
            <p>{message}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setShow(false)}
                variant="outline-secondary"
                size="sm"
              >
                Fermer
              </Button>
            </div>
          </Alert>
        ) : null}
      </div>
    </>
  );
};

export default WichPage;
