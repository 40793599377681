import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import ProductService from "../../../services/product.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
// import dataTableScripts from "../../inc/scripts/dataTableScripts";
// import UploadCatalogue from "./UploadCatalogue";
// import { ClipLoader } from "react-spinners";
// import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Moment from "react-moment";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import TextField from "@material-ui/core/TextField";
import { ClipLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, ownerDocument } from "@material-ui/core";
import { useLocation, useHistory} from "react-router-dom";
import { Slider, Checkbox, alertTitleClasses } from "@mui/material";

const Product = (props) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const location = useLocation();
  const history = useHistory();
  const pageId = 
  (() => {
    let id = new URLSearchParams(location.search).get('page_id');
    if (!id || id === '' || !/^[\d]{1,100}$/.test(id) || parseInt(id) === 0)
      return 1;
    return parseInt(id);
  })();
  const [products, setproducts] = useState([]);
  const [SliderProducts, setSliderProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#00000");

  const SlidersRef = useRef(SliderProducts);
  SlidersRef.current = SliderProducts;

  const renderProductImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + "products/" + row?.image?.split(";")[0]}
        alt={row?.image?.split(";")[0]}
        width={130}
      />
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "id", width: "90px" },
    {
        selector : 'checked',
        name : 'Select',
        width : 100,
        cellExport : (row) => '',
        cell : (row) => <Checkbox {...{inputProps: { 'aria-label': 'Checkbox demo' }}} checked={row.checked} onClick={(e) => pushToSlider(row, e)}></Checkbox>,
    },
    {
      selector: "image",
      name: "Image",
      width: "200px",
      cellExport : (row) => row.images,
      cell: (row) => renderProductImage(row),
    },
    {
      selector: "name",
      name: "Titre",
      width: "180px",
      wrap: true,
    },
    {
      selector: "category",
      name: "Catégorie",
      width: "200px",
      wrap: true,
    }
  ]);

  const tableData = {
    columns,
    data: products,
  };

  useEffect(() => {
    setLoading(true);
    ProductService.allProduct().then(async (res) => {
      setproducts(res.data.results[0]);
      setLoading(false);
    });
    ProductService.ProductDetailSlider(props.match?.params?.id).then((res) => (setSliderProducts(res?.data?.data[0])));
  }, []);

  const pushToSlider = (row, e) => {
    let checked = e?.target?.checked;
    ProductService.AddToDetailSlider({
        id : props.match.params.id,
        items : (checked ? SlidersRef.current.concat([row]).map((val) => val.id) 
        : SlidersRef.current.filter(val => val.id !== row.id).map((val) => val.id))
    }).then((res) => {
        setSliderProducts((old) => (checked ? old.concat([row]) : old.filter(val => val.id !== row.id)));
        setproducts((old) => old.map(val => val.id === row.id ? {...row, checked} : val))
        NotificationManager.success(checked ? 'Product Added successfully' : 'Product Removed successfully', '', 3000);
    }).catch(e => 
        NotificationManager.success(e?.response?.data?.error || e?.response?.data?.message || 'something went wrong !! try again', '', 3000)
    );
  }

  useEffect(() => {
    if (products.length && SliderProducts.length && !products[0].checked) {
        setproducts(old => {
            let new_products = SliderProducts.map(item => 
                ({
                    ...item,
                    checked : true
                })
            );
            new_products = new_products.concat(
                old.filter(item => 
                    SliderProducts.reduce(
                        (prev, value) => value.id === item.id ? false : prev,
                        true
                    )
                )
            )
            return new_products;
        })
    }
  }, [products, SliderProducts])

  return (
    <>
      <WichPage pageTitle="Manage Sliders" pageLocation={`manage-slider/${props.match.params.id}`} />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5>Manage Sliders</h5>
            </div>
            <div className="card-body shadow border-0">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    DefaultSortField="checked"
                    DefaultSortAsc={true}
                    SortField="checked"
                    SortAsc={true}
                    pagination
                    highlightOnHover
                    paginationDefaultPage={pageId}
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
