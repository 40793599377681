import axios from "axios";
import authHeader from "./auth-header";

const userId = sessionStorage.getItem("id_user");
const role = sessionStorage.getItem("role");

const getSliders = () => {
  return axios.get(window.API_URL + "/sliders");
};
const getSubSliders = () => {
  return axios.get(window.API_URL + "/sub_sliders");
};
const getSliderById = (id) => {
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/slider/${id}`;
  } else if (role === "Commercial") {
    URL = `${window.API_URL}/commercial/slider/${id}`;
  } else if (role === "Moderateur_Commercial") {
    URL = `${window.API_URL}/modcommercial/slider/${id}`;
  }
  return axios.get(URL, authHeader());
};

const addSlider = (formData, idAdmin, token) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const data = formData;
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/add_slider/${idAdmin}`;
  }
  return axios.post(URL, data, config);
};
const updateSliderInfos = (data, token) => {
  // var URL = ''
  // const config = { headers: { "Authorization": `Bearer ${token}` } }
  // if (role === "Admin") {
  //      URL = `${window.API_URL}/admin/marque`
  // }
  // else if (role === "Commercial") {
  //      URL = `${window.API_URL}/commercial/marque`
  // }
  // else if (role === "Moderateur_Commercial") {
  //      URL = `${window.API_URL}/modcommercial/marque`
  // }
  // return axios.patch(URL, data, config)
};
const updateSliderImage = (idSlider, image, token) => {
  // var URL = ''
  // const config = { headers: { "Authorization": `Bearer ${token}` } }
  // const data = { id: idSlider, image:image  }
  // if (role === "Admin") {
  //      URL = `${window.API_URL}/admin/sliderImage`
  // }
  // return axios.patch(URL, data, config)
};

const deletSlider = (id, token) => {
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/slider/${id}`;
  }
  return axios.delete(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deletSubSlider = (id, token) => {
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/sub_slider/${id}`;
  } else if (role === "Commercial") {
    URL = `${window.API_URL}/commercial/sub_slider/${id}`;
  } else if (role === "Moderateur_Commercial") {
    URL = `${window.API_URL}/modcommercial/sub_slider/${id}`;
  }
  return axios.delete(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const SlidersService = {
  getSliders,
  getSliderById,
  deletSlider,
  updateSliderImage,
  updateSliderInfos,
  addSlider,
  getSubSliders,
  deletSubSlider,
};

export default SlidersService;
