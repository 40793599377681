import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductService from "../../../services/product.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
import axios from "axios";
import AuthService from "../../../services/auth.service";
import { ClipLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Moment from 'react-moment';
// import { Carousel } from 'react-carousel-minimal';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}



const ProductDetails = () => {
  const params = useParams();

  const [product, setproduct] = useState({});

  const [loading, setLoading] = useState(false)
  const [color, setColor] = useState("#00000");
  const [items, setItems] = useState([])
  const [rest, setRest] = useState(0)

  useEffect(() => {
    setLoading(true)
    ProductService.getProductById(params.id)
      .then(async (res) => {
        const data = res.data.result[0]
        await setproduct(data)
        const listImages = await data.image.substring(0, data.image.length - 1).split(';')
        await console.log(data)
        await console.log(listImages)
        listImages.map(async item => {
          setItems(items => [...items, { src: `${window.IMAGE_API_URL}products/${item}` }]);
        })
        setRest(parseFloat(data.quantite - (data.quantite / 100) * data.count))
        await setLoading(false)
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        }
        else if (error.response.status === 403) {
          setLoading(false)
          error.response.data.error ?
            NotificationManager.warning(error.response.data.error, "", 2000)
            :
            NotificationManager.warning("Could not load client list", "", 2000)

        } else if (error.response.status === 401) {
          setLoading(false)
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout()
          }, 500);
        }
      });

  }, []);

  const downloadFile = (e) => {
    const path = e.target.id
    axios.get(`${window.API_URL}/download/catalogue/${path}`)
  }


  return (
    <div>
      <WichPage
        pageTitle="Details du produit"
        pageLocation="produits/detailsProduit"
      />
      <div className="row">
        {/* customar project  start */}
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {console.log(items)}
              <div className="row">
                <div className="col-lg-5">
                  {items.length > 0 ?
                    <Carousel images={items} style={{ height: 500, width: "100%" }} />
                    :
                    <ClipLoader color={color} loading={loading} />
                  }
                </div>
                <div className="col-lg-7">
                  <div className="pl-lg-4">
                    <h5 className="text-muted">
                      Catégorie : {product.category} {product.sub_category ? ' - ' + product.sub_category : ''} {product.familly ? ' - ' + product.familly : ''}
                    </h5>
                    <h3 className="mt-0">
                      {product.name}
                      <a href="#!" className="text-muted">
                        <i className="mdi mdi-square-edit-outline ml-2" />
                      </a>
                    </h3>
                    <p className="mb-1">
                      Date d'ajout:
                      <span className="ml-2">
                        <Moment format="YYYY/MM/DD">
                          {product.created_at}
                        </Moment>
                      </span>
                    </p>
                    <div className="mt-3">
                      <h5>
                        {product.quantite > 0 ?
                          <span className="badge badge-success">
                            En stock
                          </span>
                          :
                          <span className="badge badge-danger">
                            Out of stock
                          </span>
                        }

                      </h5>
                    </div>
                    <div className="mt-4 d-flex align-items-center">
                      <h5>Prix:</h5>
                      <h3 className="ml-2">
                        {parseFloat(product.tarif).toFixed(2)} MAD
                      </h3>
                    </div>
                    <div className="mt-4">
                      <h4>Description:</h4>
                      <p>{product.description}</p>
                    </div>
                    {
                      product.Catalogue ?
                        <div className="mt-4">
                          <label className="" >
                            Telecharger Catalogue :
                          </label>
                          <button className="btn btn-primary btn-sm ml-4" id={product.Catalogue} onClick={downloadFile}>Telecharger</button>
                        </div>
                        : null
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mt-4"></div>

                  <div className="w-100">
                    <div className="row mb-2">
                      <div className="col-4">Nom</div>
                      <div className="col-8">{product.name}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Categorie</div>
                      <div className="col-8">{product.category}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Sous-categorie</div>
                      <div className="col-8">{product.sub_category}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Famille</div>
                      <div className="col-8">{product.familly}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Largeur</div>
                      <div className="col-8">{product.largeur} cm</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Longueur</div>
                      <div className="col-8">{product.largeur} cm</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Volume</div>
                      <div className="col-8">{product.volume} m<sup>3</sup> (mL) </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Surface</div>
                      <div className="col-8">{product.surface} m<sup>2</sup></div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">Hauteur</div>
                      <div className="col-8">{product.hauteur} cm</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mt-4">
                    <div className="w-100">
                      <div className="row mb-2">
                        <div className="col-4">Reference</div>
                        <div className="col-8">{product.reference}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-4">Designation</div>
                        <div className="col-8">{product.designation}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-4">Caracteristiques</div>
                        <div className="col-8">{product.caracteristique}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-4">Tarif</div>
                        <div className="col-8">{parseFloat(product.tarif).toFixed(2)} MAD</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-4">Marque</div>
                        <div className="col-8">{product.marque}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-4">Quantite</div>
                        <div className="col-8">{product.quantite}</div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-4">Info supp</div>
                        <div className="col-8">{product.infos_supp}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive mt-4">
                <table className="table mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>Name</th>
                      <th>Prix</th>
                      <th>Stock</th>
                      <th>Quantité vendu</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{product.name}</td>
                      <td>{product.tarif}</td>
                      <td>
                        <div className="mb-0">
                          <span>{product.quantite} </span>

                          <LinearProgressWithLabel value={rest} />
                        </div>
                      </td>
                      <td>{product.count}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* customar project  end */}
      </div>
    </div>
  );
};

export default ProductDetails;
