import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import WichPage from "../../inc/helpfulComponents/WichPage";
// import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Moment from "react-moment";
import NotificationManager from "react-notifications/lib/NotificationManager";
import "react-notifications/lib/notifications.css";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

import {
  ReadAchatDetails,
  UpdateAchat
} from "../../../services/achat_maintenant";
import { ClipLoader } from "react-spinners";

const orderSteps = ['pending', 'confirmed'];

const OrderDetails = () => {
  const params = useParams();
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [order, setorder] = useState(null);
  const [orderItems, setorderItems] = useState([]);
  const [color, setColor] = useState("#00000");

  useEffect(() => {
    setLoading(true);
    ReadAchatDetails(params.id)
    .then((res) => {
        if (!res.data.data.length)
          return NotificationManager.warning(
            `cannot found order id ${params.id}`,
            "",
            2000
            );
        setorder(res.data.data[0]);
    })
    .catch(function (error) {
        if (!error.response) {
            NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
            );
        } else if (error.response.status === 403 || error.response.status === 400) {
          error.response.data.error
          ? NotificationManager.warning(error.response.data.error, "", 2000)
          : NotificationManager.warning(
              "Impossible de charger les informations du devis",
              "",
              2000
          );
      } else if (error.response.status === 401) {
          NotificationManager.error(
          "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
          );
          setTimeout(() => {
          AuthService.logout();
          }, 500);
      }
    }).then(() => setLoading(false));
  }, []);

  const openSwal = (status) => {
    Swal.fire({
      title: 'validation de commande',
      html : `you are about to made this order as <b>${status}</b> status.</br>Confirm to move on`,
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText : 'Cancel',
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
          UpdateAchat({id : order.id})
          .then((resp) => (setorder({...order, status : status}),
          NotificationManager.success(
            resp?.message || "le command a ete confirmee",
            "",
            4000
            )
          ))
          .catch(function (error) {
            if (!error.response) {
                NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
                );
            } else if (error.response.status === 403 || error.response.status === 400) {
                error.response.data.error
                ? NotificationManager.warning(error.response.data.error, "", 2000)
                : NotificationManager.warning(
                    "Impossible de charger les informations du devis",
                    "",
                    2000
                );
            } else if (error.response.status === 401) {
                NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
                );
                setTimeout(() => {
                AuthService.logout();
                }, 500);
            }
            })
      }
    })
  }

  return (
    order ?
    <>
      <WichPage pageTitle="Detail Achat" pageLocation={`/achat-imediat/${params.id}`} />
        <div className="row">
          {/* [ Invoice ] start */}
          {/* <div className="container"> */}
          <div className="col-12">
            <div ref={componentRef} className="card" id="printable_devis">
              <div className="row invoice-contact">
                <div className="col-md-12">
                  <div className="invoice-box row">
                    <div className="col-sm-12">
                      <table className="table table-responsive invoice-table table-borderless p-l-20 hfc-info">
                        <tbody>
                          <tr>
                            <td>
                              <a href="index.html" className="b-brand">
                                <img
                                  className="img-fluid"
                                  src={"/assets/images/logo.png"}
                                  alt="Able pro Logo"
                                  width={120}
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>{'Healthcare Facilities Consult'}</td>
                          </tr>
                          <tr>
                            <td>
                              504 lotissement haj Fateh  20260  El Oulfa Casablanca Maroc
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a
                                className="text-secondary"
                                href="mailto:contact@healthcarefacilitiesconsult.com"
                                target="_top"
                              >
                                contact@healthcarefacilitiesconsult.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>+212 625-701262</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-4" />
              </div>
              <div className="card-body">
                <div className="row invoive-info">
                  <div className="col-md-4 col-xs-12 invoice-client-info">
                    <h6>Informations client :</h6>
                    <h6 className="m-0">{`${order?.firstName || ''} ${order?.lastName || ''}`}</h6>
                    <p className="m-0">{order?.phone}</p>
                    <p>
                      <a
                        className="text-secondary"
                        href={"mailto:" + order?.email}
                        target="_top"
                      >
                        {order?.email}
                      </a>
                    </p>
                    {
                  order?.confirm_par_id ?
                  <>
                    <h6>Confirmé par administrateur :</h6>
                    <div style={{marginLeft : 10}}>
                      <h5 className="m-0">{`${order?.admin_full_name || ''}`}</h5>
                    </div>
                  </> :<></>
                  }
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <h6 className="m-b-20">
                      Numéro de achat:{" "}
                      <span className="mx-2"> {order?.id || ''}</span>
                    </h6>
                    <h6 className="m-0 m-t-10">
                      Date de demande :
                      {/* <h6 className="text-uppercase text-primary mt-2"> */}
                      <Moment format="DD/MM/YYYY" className="text-primary mx-2">
                        {order?.created_at}
                      </Moment>
                    </h6>
                    <h6 className="m-0 m-t-10">
                      Date de last update :
                      {/* <h6 className="text-uppercase text-primary mt-2"> */}
                      <Moment format="DD/MM/YYYY" className="text-primary mx-2">
                        {order?.modified_at}
                      </Moment>
                    </h6>
                  </div>
                </div>
                <div>
                    <div className="col-12" style={{fontSize : 20}}>Status</div>
                    <div className="col-12 my-2">
                        <Stepper
                        activeStep={orderSteps.indexOf(order?.status)}
                        alternativeLabel
                        >
                        {orderSteps.map((label) => (
                            <Step key={label} onClick={() => openSwal(label)}>
                                <StepLabel style={{cursor : 'pointer'}}>{label}</StepLabel>
                            </Step>
                        ))}
                        </Stepper>
                    </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-responsive">
                      <table className="table invoice-detail-table">
                        <thead>
                          <tr className="thead-default">
                            <th>Produits</th>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Color</th>
                            <th>Quantité</th>
                            <th>Prix TTC</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                          <td>
                            <a href={
                              order?.product_type === 'product' ?
                              `/DetailsProduit/${order.product_id}` :
                              (
                                order?.product_type === 'arrival' ?
                              `/detailsNouveauArrive/${order.product_id}` :
                              `/details/amazing_products/${order.product_id}`
                              )
                            } target="_blank">
                            <img
                              src={`${window.IMAGE_API_URL}products/${order?.product_image?.split(';')[0]}`}
                              alt="contact-img"
                              title="contact-img"
                              className="rounded mr-3"
                              height={100}
                            />
                            </a>
                          </td>
                          <td><p className="m-0 d-inline-block align-middle font-16">
                              {order?.product_name}
                            </p></td>
                          <td><b>{order?.size}</b></td>
                          <td><b>{order?.color}</b></td>
                          <td><b>{order?.quantity}</b></td>
                          <td>
                            {/*Prix TTC / Unite  */}
                            {parseFloat(order?.price || 0).toFixed(2)}
                          </td>
                         </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <table className="table table-responsive invoice-table invoice-total">
                      <tbody>
                      <tr className="text-info">
                          <td>
                            <hr />
                            <h5 className="text-primary m-r-10">Sub-Total TTC :</h5>
                          </td>
                          <td>
                            <hr />
                            <h5 className="text-primary">
                              {parseFloat(((order?.price || 0) * order?.quantity)).toFixed(2)} MAD
                            </h5>
                          </td>
                        </tr>
                        <tr className="text-info">
                          <td>
                            <hr />
                            <h5 className="text-primary m-r-10">Livraison :</h5>
                          </td>
                          <td>
                            <hr />
                            <h5 className="text-primary">
                              {order?.total_livraison > 0 ? `${parseFloat(order?.total_livraison).toFixed(2)} MAD` : 'gratuit'} 
                            </h5>
                          </td>
                        </tr>
                        <tr className="text-info">
                          <td>
                            <hr />
                            <h5 className="text-primary m-r-10">Total TTC :</h5>
                          </td>
                          <td>
                            <hr />
                            <h5 className="text-primary">
                              {parseFloat(((order?.price || 0) * order?.quantity) + order?.total_livraison).toFixed(2)} MAD
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 invoice-btn-group text-center">
                {loading ? (
                  <ClipLoader color={color} loading={loading} />
                ) : (
                  <ReactToPrint
                    trigger={() => (
                      <button type="button" className="btn btn-primary buttons">
                        Imprimer
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* [ Invoice ] end */}
        </div>
    </> :
    <></>
  );
};

export default OrderDetails;
