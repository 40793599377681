import axios from "axios";

const allSubCategories = () => {
     return axios.get(window.API_URL + "/sub_categories");
}

const allfamilles = () => {
    return axios.get(window.API_URL + "/familles")
}

const getFamillyById = (id) => {
     return axios.get(`${window.API_URL}/famille/:${id}`);
}

const role = sessionStorage.getItem('role');

const addFamilly = ( subcategory_id ,familly,idAdmin, token) => {
     const config = { headers: { "Authorization": `Bearer ${token}` } }
     const data = { subcategory_id: subcategory_id, name: familly}
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/famille/${idAdmin}`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/famille/${idAdmin}`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/famille/${idAdmin}`
     }
     return axios.post(URL, data, config)
}

const updateFamilly = (idFamilly,subcategory, name, token) => {
     console.log(subcategory);
     console.log(idFamilly);
     console.log(name);
     console.log(token);
     var URL = ''
     const config = { headers: { "Authorization": `Bearer ${token}` } }
     const data = { id: idFamilly, subcategory_id: subcategory ,name: name }
     console.log(JSON.stringify(data))
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/famille`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/famille`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/famille`
     }
     return axios.patch(URL, data, config)
}

const deleteFamilly = (id, v_admin, token) => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/famille/${id}`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/famille/${id}`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/famille/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               },
               data: {
                    v_admin: v_admin
               }
          });

}

const getFamilliesBySubCategoryId = (id)=> {
     const URL = `${window.API_URL}/famille_by_subcatg/${id}`;
     return axios.get(URL)
}

const FamillyService = {
     allSubCategories,
     allfamilles,
     getFamillyById,
     addFamilly,
     updateFamilly,
     deleteFamilly,
     getFamilliesBySubCategoryId
}

export default FamillyService;