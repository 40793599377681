import { TextField } from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductService from "../../../services/product.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import AuthService from "../../../services/auth.service";
import axios from "axios";

const Stock = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState("");
  const [quantite, setQuantite] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [Bulle, setBulle] = useState("");
  const [position, setPosition] = useState("");
  const [image, setimage] = useState();

  // const renderproductActions = (params) => {
  //   return (
  //     <div className="d-flex align-items-center justify-content-around w-100">
  //       <button
  //         // to={"/modifierProduit/" + params.row.id}
  //         // className="btn btn-icon btn-outline-success"
  //         data-toggle="modal"
  //         data-target="#modal-edit"
  //         className="btn btn-icon btn-outline-primary"
  //         onClick={() => {
  //           setProductToUpdate(params.row);
  //         }}
  //       >
  //         <i className="feather icon-edit" />
  //       </button>
  //     </div>
  //   );
  // };

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
      name: "Numéro de produit",
      selector: "id",
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      selector: "image",
      // sortable: true,
      width: "200px",
      cell: (row) => (
        <img
          src={window.IMAGE_API_URL + "products/" + row.image.split(";")[0]}
          alt={row.image.split(";")[0]}
          width={150}
        />
      ),
    },
    {
      name: "Titre",
      selector: "name",
      // width: "100px",
      editable: false,
    },
    {
      selector: "quantite",
      name: "Quantité",
      // width: "100px",
      editable: false,
    },
    {
      selector: "count",
      name: "Quantité en stock",
      // type: 'number',
      // width: 300,
      cell: (row) => row.quantite - row.count,
    },
    {
      selector: "action",
      name: "Actions ",
      // type: 'number',
      // width: "300px",
      cell: (row) => (
        <div className="d-flex align-items-center justify-content-around w-100">
          <button
            data-toggle="modal"
            data-target="#modal-edit"
            className="btn btn-icon btn-outline-primary"
            onClick={() => {
              setProductToUpdate(row.id);
            }}
          >
            <i className="feather icon-edit" />
          </button>
          <button
            data-toggle="modal"
            data-target="#modal-add"
            id={row.id}
            className="btn btn-icon btn-outline-danger"
            onClick={() => {
              setProductToUpdate(row.id);
            }}
          >
            <i className="fas fa-image" id={row.id}></i>
          </button>
        </div>
      ),
    },
  ]);
  const tableData = {
    columns,
    data: products,
  };
  useEffect(() => {
    setLoading(true);
    ProductService.allProduct()
      .then(async (res) => {
        console.log(res.data.results);
        setProducts(res.data.results[0]);
        setLoading(false);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Nous N'avons pas pus recharger la liste des contacts",
                "",
                2000
              );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }, []);

  // ADD Product to sub_sliders

  const ajouterProductToSubSlider = (event) => {
    event.preventDefault();
    const userId = sessionStorage.getItem("id_user");
    const formData = new FormData();
    formData.append("Bulle", Bulle);
    formData.append("v_admin", userId);
    formData.append("product_id", productToUpdate);
    formData.append("image", selectedFile);
    formData.append("position", position);

    if (Bulle !== "" && image !== "" && position !== "") {
      ProductService.addProductToSubSliders(formData)
        .then((res) => {
          // console.log(res)
          setBulle("");
          setPosition("");
          setSelectedFile(null);
          // ProductService.allProduct().then((res) => {
            // console.log(res)
            NotificationManager.success(
              "Le produit est ajoute avec success a la liste des sous sliders ",
              "",
              2000
            );
            setProducts(res.data.results[0]);
            setLoading(false);
          // });
        })
        .catch( (error)=> {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.message, "", 2000);

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, reconnectez-vous svp ",
              "",
              4000
            );
          }
        });

      setBulle("");
      setSelectedFile(null);
    } else {
      if (Bulle === "" || image === "" || position === "") {
        NotificationManager.warning("Verifier les inputs", "", 2000);
      }
    }
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0] ? event.target.files[0] : null);
    setimage(event.target.files[0] ? event.target.files[0].name : "");
  };

  // Update Quantite
  const updateQuantite = () => {
    setQuantite("");
    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    if (quantite !== "") {
      setQuantite(quantite);

      const data = { id: productToUpdate, quantite: quantite, v_admin: userId };

      var URL = "";
      const config = { headers: { Authorization: `Bearer ${token}` } };
      var URL = "";
      if (role === "Admin") {
        URL = `${window.API_URL}/admin/stock_quantity/${userId}`;
      } else if (role === "Commercial") {
        URL = `${window.API_URL}/commercial/stock_quantity/${userId}`;
      } else if (role === "Moderateur_Commercial") {
        URL = `${window.API_URL}/modcommercial/stock_quantity/${userId}`;
      }
      return axios
        .patch(URL, data, config)
        .then((res) => {
          ProductService.allProduct().then((res) => {
            NotificationManager.success(
              "La quantite est modifiee avec success",
              "",
              2000
            );
            setProducts(res.data.results[0]);
            setLoading(false);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });
    }
  };
  return (
    <>
      <WichPage pageTitle="Stock" pageLocation="Stock" />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5>Stock</h5>
              <div className="card-header-right d-flex align-items-center ">
                <div className="mx-2">
                  <h5>Produit en Sous-Slides</h5>
                  <Link
                    to="/subsliders"
                    className="btn btn-icon btn-outline-warning mr-5"
                  >
                    <i className="fas fa-eye" />
                  </Link>
                </div>
                <div className="mx-2">
                  <h5>Ajouter un produit</h5>

                  <Link
                    to="/ajouterProduit"
                    className="btn btn-icon btn-outline-primary"
                  >
                    <i className="fas fa-plus-circle" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body shadow border-0">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
          <div
            className="modal fade"
            id="modal-edit"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myExtraLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Modufier la quantité de {productToUpdate.name}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <span></span>
                      <div className="form-group">
                        <TextField
                          id="desc"
                          label="entrez le nombre à ajouter"
                          className="w-100"
                          type="number"
                          variant="outlined"
                          // multiline
                          // maxRows={4}
                          // value={description}
                          onChange={(e) => setQuantite(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 text-right mt-5">
                      <button
                        className="btn btn-icon btn-outline-primary save_catg"
                        onClick={updateQuantite}
                      >
                        <i className="feather icon-save save_catg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  START ADD PRODUCT TO SubSliders MODAL */}

        <div
          className="modal fade"
          id="modal-add"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ajouter le produit au solde</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="Bulle"
                        onChange={(e) => setBulle(e.target.value)}
                        name="name"
                        value={Bulle}
                        placeholder="Bulle"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <select
                        name="position"
                        id="position"
                        onChange={(e) => setPosition(e.target.value)}
                        placeholder="Bulle"
                      >
                        <option value="Gauche">Gauche</option>
                        <option value="Bas">Bas</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        id="SliderImage"
                        name="image"
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 text-right mt-5">
                    {!loading ? (
                      <button
                        type="button"
                        className="btn btn-icon btn-outline-primary"
                        onClick={ajouterProductToSubSlider}
                        // data-dismiss="modal"
                      >
                        <i className="feather icon-save" />
                      </button>
                    ) : (
                      <ClipLoader loading={loading} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  END ADD PRODUCT TO SubSliders */}
      </div>
    </>
  );
};

export default Stock;
