import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

const user = AuthService.getCurrentUser()
const token = sessionStorage.getItem('token');
const nvDevis =()=>{
    return axios.get(window.API_URL+"/admin/demande_nnvalidee",authHeader())
}

const vDevis =()=>{
    return axios.get(window.API_URL+"/admin/demande_validee",authHeader())
}


const deleteDevis = (id) => {
    var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/demande_devis/${id}`
     }
     if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/demande_devis/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               }
          });
}

const getDevisById = (id)=>{
    var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/demande_devis/${id}`
     }
     if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/demande_devis/${id}`
     }
   
    return axios.get(URL,authHeader())
}

const valideDevis = (data)=>{
    
    var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/reponse_demande_devis`
     }
     if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/reponse_demande_devis`
     }
   
    return axios.patch(URL,data,authHeader())
}

const QuotationService ={
    nvDevis,
    vDevis,
    deleteDevis,
    getDevisById,
    valideDevis   
}

export default QuotationService;