/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../services/auth.service";

const Navbar = () => {
  const user = AuthService.getCurrentUser();

  return (
    <header className="navbar pcoded-header navbar-expand-lg navbar-light header-blue">
      <div className="m-header">
        <a className="mobile-menu" id="mobile-collapse" href="#!">
          <span></span>
        </a>
        <a href="#!" className="b-brand">
          <img
            src="/assets/images/logo.png"
            alt=""
            className="logo"
            width="100"
            height="50"
          />
        </a>
        <a href="#!" className="mob-toggler">
          <i className="feather icon-more-vertical"></i>
        </a>
      </div>
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <a href="#!" className="pop-search">
              <i className="feather icon-search"></i>
            </a>
            <div className="search-bar">
              <input
                type="text"
                className="form-control border-0 shadow-none"
                placeholder="Search hear"
              />
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li>
            <div className="dropdown drp-user">
              <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="feather icon-user"></i>
              </Link>
              <div className="dropdown-menu dropdown-menu-right profile-notification">
                <div className="pro-head">
                  <img
                    src={window.IMAGE_API_URL+'avatar/'+user.avatar}
                    // className="img-radius"
                    alt="User-Profile-Image"
                  />
                  <span>{user.user}</span>
                  <a
                    href="/"
                    className="dud-logout"
                    title="Logout"
                    onClick={AuthService.logout}
                  >
                    <i className="feather icon-log-out"></i>
                  </a>
                </div>
                <ul className="pro-body">
                  <li>
                    <Link to="/profil" className="dropdown-item">
                      <i className="feather icon-user"></i> Profile
                    </Link>
                  </li>
                  <li>
                    <Link to="/contacts" className="dropdown-item">
                      <i className="feather icon-mail"></i> Messages
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="dropdown-item"
                      onClick={AuthService.logout}
                    >
                      <i className="feather icon-lock"></i> Déconnexion
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
};
export default Navbar;
