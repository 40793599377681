import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardService from "../../../services/dashboard.service";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";

const ComplaintsCard = () => {

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [reclamations, setReclamations] = useState([]);
  const [urgent, setUrgent] = useState(0);
  const [haute, setHaute] = useState(0);
  const [moyenne, setMoyenne] = useState(0);
  const [faible, setFaible] = useState(0);
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  
  useEffect(() => {
    setLoading(true)
    if (role === "Admin" || role === "Moderateur") {
    DashboardService.getReclamationNumbByType().then((res) => {
      setReclamations(res.data.result);
      setLoading(false)
      // console.log(res.data.result)
      res.data.result.map((reclam) => {
        if (reclam.type === "Urgente") {
          setUrgent(reclam.number)
        }
        if (reclam.type === "Haute") {
          setHaute(reclam.number)
        }
        if (reclam.type === "Moyenne") {
          setMoyenne(reclam.number)
        }
        if (reclam.type === "Faible") {
          setFaible(reclam.number)
        }
      })
    })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        }
        else if (error.response.status === 403) {
          setLoading(false)
          error.response.data.error || error.response.data.message ?
            NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
            :
            NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des reclamations", "", 2000)

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false)
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout()
          }, 500);
        }
      });
    }
  }, []);

  return (
    <>
    {
    role === "Admin" || role === "Moderateur" ? 
    <div className="card mb-3">
      <div className="card-header">
        <h5>Réclamations</h5>
      </div>
      <div className="card-body p-3">
        <div className="cat-list">
          <div className="border-bottom my-2 d-flex align-items-center justify-content-between p-2 ">
            <div className="d-inline-block">
              <span className="bg-danger"> </span>

              <Link to="/reclamations">Urgent</Link>
            </div>
            <div className="float-right span-content d-fex align-items-center">
              <button
                style={{ minWidth: "50px" }}
                className="btn badge-danger btn-sm"
              // className="btn waves-effect waves-light btn-default badge-danger rounded-circle mr-1"
              >
                {urgent}
              </button>
            </div>
          </div>
          <div className="border-bottom my-2 d-flex align-items-center justify-content-between p-2 ">
            <div className="d-inline-block">
              <span className="bg-danger"> </span>

              <Link to="/reclamations">Haut</Link>
            </div>
            <div className="float-right span-content d-fex align-items-center">
              <button
                style={{ minWidth: "50px" }}
                className="btn  badge-warning btn-sm"
              >
                {haute}
              </button>
            </div>
          </div>
          <div className="border-bottom my-2 d-flex align-items-center justify-content-between p-2 ">
            <div className="d-inline-block">
              <span> </span>

              <Link to="/reclamations">moyen</Link>
            </div>
            <div className="float-right span-content d-fex align-items-center">
              <button
                style={{ minWidth: "50px" }}
                className="btn  badge-info btn-sm"
              >
                {moyenne}
              </button>
            </div>
          </div>
          <div className="border-bottom my-2 d-flex align-items-center justify-content-between p-2 ">
            <div className="d-inline-block">
              <span> </span>

              <Link to="/reclamations">Faible</Link>
            </div>
            <div className="float-right span-content d-fex align-items-center">
              <button
                style={{ minWidth: "50px" }}
                className="btn  badge-secondary btn-sm"
              >
                {faible}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> : null }
    </>
  );
};

export default ComplaintsCard;
