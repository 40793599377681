import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

const getNewArrivalProductById = (id) => {
     return axios.get(`${window.API_URL}/new_arrival/${id}`);
}
const user = AuthService.getCurrentUser()

const getAllNewProducts = () =>{
     return axios.get(`${window.API_URL}/new_arrival`, authHeader())
}

const updateNewArrivalProduct = (data) => {

     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/new_arival/`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/new_arival/`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/new_arival/`
     }
     return axios.patch(URL, data, authHeader())
}

const deleteNewArrival = (id) => {
      var URL = ''
      if (user.role === "Admin") {
           URL = `${window.API_URL}/admin/new_arrival/${id}`
      }
      else if (user.role === "Commercial") {
           URL = `${window.API_URL}/commercial/new_arrival/${id}`
      }
      else if (user.role === "Moderateur_Commercial") {
           URL = `${window.API_URL}/modcommercial/new_arrival/${id}`
      }
      return axios.delete(URL,
           {
                headers: {
                     Authorization: `Bearer ${user.token}`
                }
           });
}

const updateNewArrivalImages = (formData) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/new_arrival_img`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/new_arrival_img`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/new_arrival_img`
     }
     return axios.patch(URL, formData, authHeader())
}

// amazing products

const getAmazingProductById = (id) => {
     return axios.get(`${window.API_URL}/amazing/products/${id}`);
}

const getAllAmazingProducts = () =>{
     return axios.get(`${window.API_URL}/amazing/products`, authHeader())
}

const updateAmazingProduct = (data) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/amazing/products/`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/amazing/products/`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/amazing/products/`
     }
     return axios.patch(URL, data, authHeader())
}

const deleteAmazingProduct = (id) => {
      var URL = ''
      if (user.role === "Admin") {
           URL = `${window.API_URL}/admin/amazing/products/${id}`
      }
      else if (user.role === "Commercial") {
           URL = `${window.API_URL}/commercial/amazing/products/${id}`
      }
      else if (user.role === "Moderateur_Commercial") {
           URL = `${window.API_URL}/modcommercial/amazing/products/${id}`
      }
      return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${user.token}`
               }
          });
}

const updateAmazingImages = (formData) => {
     var URL = ''
     if (user.role === "Admin") {
          URL = `${window.API_URL}/admin/amazing/products_img`
     }
     else if (user.role === "Commercial") {
          URL = `${window.API_URL}/commercial/amazing/products_img`
     }
     else if (user.role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/amazing/products_img`
     }
     return axios.patch(URL, formData, authHeader())
}

const newArrivalService = {
     getNewArrivalProductById,
     updateNewArrivalProduct,
     deleteNewArrival,
     getAllNewProducts,
     updateNewArrivalImages,

     getAmazingProductById,
     updateAmazingProduct,
     deleteAmazingProduct,
     getAllAmazingProducts,
     updateAmazingImages,
}

export default newArrivalService;