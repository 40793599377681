import axios from 'axios';
import authHeader from './auth-header';
 
const userId = sessionStorage.getItem("id_user")
const role = sessionStorage.getItem('role');

const getContacts = ()=>{
    var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/contacts`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/contacts`
     }
    return axios.get(URL, authHeader())
} 

const getContactById = (id)=>{
    var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/contact/${id}`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/contact/${id}`
     }
    return axios.get(URL, authHeader())
} 

const deleteContact = (id, token) => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/contact/${id}`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/contact/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               },               
          });

}

const ContactService = {
    getContacts,
    getContactById,
    deleteContact

}

export default ContactService ;