import React from 'react'
import { useHistory } from "react-router-dom";



const NotFound = () => {
    
    const history = useHistory();
    return (
        <div className="auth-wrapper maintance">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="text-center">
                            <img src={window.location.origin + "/assets/images/maintance/404.png"} alt="" className="img-fluid" />
                            <h5 className="text-muted my-4">Oups! Page non trouvée!</h5>
                            <button className="btn waves-effect waves-light btn-primary mb-4" onClick={() => history.goBack()}><i className="feather icon-refresh-ccw mr-2"></i>Retourner</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NotFound;