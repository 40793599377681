import React, { useEffect, useState, useRef } from "react";
import WichPage from "../../inc/helpfulComponents/WichPage";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import Swal from "sweetalert2";
import MarqueService from "../../../services/marque.service";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";
import AuthService from "../../../services/auth.service";


const Marques = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const Addform = useRef(null);

  const [marques, setMarques] = useState([]);

  const [marque, setMarque] = useState("");
  const [idMarqueUpdate, setIdMarqueUpdate] = useState(0);
  // const [marqueId, setMarqueId] = useState(0);
  const [name, setName] = useState("");
  const [image, setimage] = useState();
  // const [marque, setMarque] = useState("");
  const [loading, setLoading] = useState(true);

  const [selectedFile, setSelectedFile] = useState();

  const renderActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <button
          data-toggle="modal"
          data-target="#modal-edit"
          className="btn btn-icon btn-outline-primary"
          onClick={() => {
            setIdMarqueUpdate(row.id);
            setimage(row.image);
            setMarque(row.name);
            setName(row.name);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>

        <button
          type="button"
          className="btn btn-icon btn-outline-danger my-2"
          id={row.id}
          onClick={deleteMarque}
        >
          <i className="far fa-trash-alt" id={row.id}></i>
        </button>
      </div>
    );
  };
  const rendeImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + `marques/` + row.image}
        alt={row.image}
        width={130}
      />
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "id" },
    {
      selector: "image",
      name: "IMAGE REPRESENTATIVE",
      width: "350px",
      cell: (row) => rendeImage(row),
    },
    {
      selector: "name",
      name: "NOM DE LA MARQUE",
      width: "300px",
    },
    {
      selector: "nbr_product",
      name: "NOMBRE DE PRODUITS",
      width: "300px",
    },

    {
      name: "Action",
      width: "200px",

      cell: (row) => renderActions(row),
    },
  ]);
  const tableData = {
    columns,
    data: marques,
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0] ? event.target.files[0] : null);
    setimage(event.target.files[0] ? event.target.files[0].name : "");
  };

  // load Marques
  useEffect(() => {
    MarqueService.allMarques().then((res) => {
      console.log(res);
      setMarques(res.data.results);
      setLoading(false);
    }) .catch(function (error) {
      if (!error.response) {
        NotificationManager.error(
          "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
      }
      else if (error.response.status === 403) {
        setLoading(false)
        error.response.data.error || error.response.data.message ?
          NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
          :
          NotificationManager.warning("Nous n'avons pas pu recharger la liste des abonnees", "", 2000)

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.response.status === 401) {
        setLoading(false)
        NotificationManager.error(
          "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
        setTimeout(() => {
          AuthService.logout()
        }, 500);
      }
    });
  }, []);

  // ADD Marque
  const addMarque = (e) => {
    setName("");
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", selectedFile);
    console.log(formData.get("name"), formData.get("image"));
    // return;
    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    if (name !== "" && image !== "") {
      MarqueService.addMarque(formData, userId, token)
        .then((res) => {
          // console.log(res)
          MarqueService.allMarques().then((res) => {
            // console.log(res)
            NotificationManager.success(
              "La marque est ajouter avec success",
              "",
              2000
            );
            setMarques(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, reconnectez-vous svp ",
              "",
              4000
            );
          }
        });

      setName("");
    } else {
      if (name === "") {
        NotificationManager.warning(
          "Le nom de la marque est necessaire",
          "",
          2000
        );
      }
      if (image === "") {
        NotificationManager.warning(
          "Le logo de la marque est necessaire",
          "",
          2000
        );
      }
    }
  };

  // Update Marque
  const updateMarque = () => {
    setName("");
    setSelectedFile(null);
    // const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    if (name !== "" && image !== "") {
      if (name === "") {
        setName(marque);
      }
      const formData = new FormData();

      formData.append("name", name);
      formData.append("id", idMarqueUpdate);
      formData.append("image", selectedFile);

      var URL = "";
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const data = formData;
      if (selectedFile && selectedFile !== {}) {
        if (role === "Admin") {
          URL = `${window.API_URL}/admin/image_marque`;
        } else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/image_marque`;
        } else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/image_marque`;
        }
        axios
          .patch(URL, data, config)
          .then((res) => {
            MarqueService.allMarques().then((res) => {
              NotificationManager.success(
                "La marque mise à jour avec succès",
                "",
                2000
              );
              setMarques(res.data.results);
            });
          })
          .catch(function (error) {
            if (error.response.status === 403) {
              NotificationManager.warning(error.response.data.error, "", 2000);
            } else if (error.response.status === 401) {
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
            }
          });
      }

      if (role === "Admin") {
        URL = `${window.API_URL}/admin/marque`;
      } else if (role === "Commercial") {
        URL = `${window.API_URL}/commercial/marque`;
      } else if (role === "Moderateur_Commercial") {
        URL = `${window.API_URL}/modcommercial/marque`;
      }
      axios
        .patch(URL, { id: idMarqueUpdate, name: name }, config)
        .then((res) => {
          MarqueService.allMarques().then((res) => {
            NotificationManager.success(
              "La marque a ete modifie avec success",
              "",
              2000
            );
            setMarques(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });
    }
  };

  // DELETE Marque

  const deleteMarque = (event) => {
    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    const MarqueId = event.target.id;
    console.log(MarqueId);
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "Vous ne pourrez pas revenir sur cette marque !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez-le",
        cancelButtonText: "Non, annulez !",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          MarqueService.deleteMarque(MarqueId, userId, token).then((res) => {
            // console.log(res)
            MarqueService.allMarques().then((res) => {
              // console.log(res)
              setMarques(res.data.results);
            });
          });

          swalWithBootstrapButtons.fire(
            "Supprimé !",
            "La marque a été supprimée.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "La marque n'a pas été supprimée",
            "error"
          );
        }
      });
  };

  // useDataTableScripts();
  return (
    <div>
      <NotificationContainer />
      <WichPage pageTitle="Marques" pageLocation="Marques" />
      <div className="row">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card shadow-none">
            <div className="card-header">
              <h5>Marques</h5>
              <div className="card-header-right">
                <h5>Ajouter une marque</h5>
                <button
                  className="btn btn-icon btn-outline-primary"
                  data-toggle="modal"
                  data-target="#modal-add"
                >
                  <i className="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* MODALS */}

      {/*  START ADD CATEGORY MODAL */}
      <div
        className="modal fade"
        id="modal-add"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter une marque</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {/* <form ref={Addform} onSubmit={addMarque} className="w-100"> */}
                <div className="col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      value={name}
                      placeholder="Nom de la marque"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      id="marqueImage"
                      name="image"
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary save_catg"
                    onClick={addMarque}
                  >
                    <i className="feather icon-save save_catg" />
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  END ADD CATEGORY MODAL */}

      {/*  START UPDATE CATEGORY MODAL */}

      <div
        className="modal fade"
        id="modal-edit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modifier la marque</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="m-3">
                    <label>Nom actuel : {marque}</label>
                  </div>
                  <div className="form-group">
                    <label className="" htmlFor="Name">
                      le nouveau nom
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      onChange={(e) => setName(e.target.value)}
                      placeholder="le nouveau nom"
                    />
                  </div>
                </div>
                <div className="col-12">
                  {/* <div className="m-3">
                    <label>
                      Image actuel :  {image}
                    </label>
                  </div> */}
                  <label className="floating-label" htmlFor="Name">
                    le nouveau logo
                  </label>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      onChange={changeHandler}
                      // value={image}
                    />
                  </div>
                </div>

                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary"
                    onClick={updateMarque}
                  >
                    <i className="feather icon-save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  END UPDATE CATEGORY MODAL */}
    </div>
  );
};

export default Marques;
