import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WichPage from "../../inc/helpfulComponents/WichPage";
// import AdminCards from "./AdminCards";
import ComplaintsCard from "./ComplaintsCard";
import MessagesCard from "./MessagesCard";
import SubscibersCard from "./SubscibersCard";
import BestSellingProducts from "./BestSellingProducts";
import { Bar, Line } from "react-chartjs-2";
import DashboardService from "../../../services/dashboard.service";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";


import { io } from 'socket.io-client'


const socket = io(window.socket_API_URL)


const Home = () => {

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [users, setUsers] = useState(0);
  const [subscribers, setSubscribers] = useState(0);
  const [products, setProducts] = useState(0);
  const [blogs, setBlogs] = useState(0);
  const [usersByYear, setUsersByYear] = useState(0);
  const [Views, setViews] = useState(0);
  const [usersByMonth, setUsersByMonth] = useState(0);
  const [selledproductsByY, setSelledProductsByY] = useState(0);
  const [selledproductsByM, setSelledProductsByM] = useState(0);
  const [devisValide, setDevisValide] = useState(0);
  const [devisNonValide, setDevisNonValide] = useState(0);
  const [adminList, setAdminList] = useState([]);
  const [adminListActivities, setAdminListActivities] = useState([]);
  const [adminListActivitiesDates, setAdminListActivitiesDates] = useState([]);
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const [devisNV, setDevisNV] = useState([]);

  const getUserNumer = () => {
    if (role === "Admin" || role === "Moderateur")
      DashboardService.getUsersNumber().then((res) => {
        setUsers(res.data.results[0].number);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }

  const getViews = () => {
    if (role === "Admin" || role === "Moderateur")
      DashboardService.getViews().then((res) => {
        setViews(res.data.results);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        }
        else if (error.response.status === 403) {
          setLoading(false)
          error.response.data.error || error.response.data.message ?
            NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
            :
            NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false)
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout()
          }, 500);
        }
      });
  }

  const getSubscribersNumber = () => {
    if (role === "Admin" || role === "Moderateur")
      DashboardService.getSubscribersNumber().then((res) => {
        setSubscribers(res.data.results[0].number);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }

  const getProductsNumber = () => {
    if (role === "Admin" || role === "Commercial" || role === "Moderateur_Commercial")
      DashboardService.getProductsNumber().then((res) => {
        setProducts(res.data.results[0].number);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }

  const getBlogsNumber = () => {
    if (role === "Admin" || role === "Redacteur")
      DashboardService.getBlogsNumber().then((res) => {
        setBlogs(res.data.results[0].number);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }

  const getUsersByYear = () => {
    if (role === "Admin" || role === "Moderateur")
      DashboardService.getUsersByYear().then((res) => {
        setUsersByYear(res.data.results[0].number);
        setLoading(false)
        // console.log(res.data.results)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }

  const getUsersByMonth = () => {
    if (role === "Admin" || role === "Moderateur")
      DashboardService.getUsersByMonth().then((res) => {
        setUsersByMonth(res.data.results[0].number);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }


  const getProductByMonth = () => {
    if (role === "Admin" || role === "Commercial" || role === "Moderateur_Commercial")
      DashboardService.getProductByMonth().then((res) => {
        setSelledProductsByM(res.data.results[0].number);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }

  const getDevisValide = () => {
    if (role === "Admin" || role === "Commercial" || role === "Moderateur_Commercial")
      DashboardService.getDemandeDevisByValide().then((res) => {
        setDevisValide(res.data.results[0].length);
        setLoading(false)
        if(res.data.results[0].length >=5)
        {
          setDevisNV(res.data.results[0].slice(0, 5))
        }
        else{

          setDevisNV(res.data.results[0])
        }
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }
  const getDevisNonValide = () => {
    if (role === "Admin" || role === "Commercial" || role === "Moderateur_Commercial")
      DashboardService.getDemandeDevisNVByValide().then((res) => {
        setDevisNonValide(res.data.results.length);
        setLoading(false)
        console.log(res.data.results.length)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }


  const getActivitiesByAdmin = (id) => {
    if (role === "Admin")
      DashboardService.getAdminActivity({ admin_id: id }).then((res) => {
        // console.log(res.data.result)
        // setAdminListActivities(res.data.result)
        setAdminListActivities(adminListActivities => [...adminListActivities, res.data.result.activity]);
        setAdminListActivitiesDates(adminListActivitiesDates => [...adminListActivitiesDates, res.data.result.dates]);
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }


  const getlistAdmins = () => {
    if (role === "Admin")
      DashboardService.getAllAdmins().then((res) => {
        setAdminList(res.data.results);
        setLoading(false)
        res.data.results.map(admin => {
          getActivitiesByAdmin(admin.id)
        })
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }

  const getProductByYear = () => {
    if (role === "Admin" || role === "Commercial" || role === "Moderateur_Commercial")
      DashboardService.getProductByYear().then((res) => {
        setSelledProductsByY(res.data.results[0].number);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
  }




  useEffect(() => {
    setLoading(true)
    // USERS NUMBER
    getUserNumer()
    // Subscribers Number
    getSubscribersNumber()
    //Products Number
    getProductsNumber()
    //Blogs Number
    getBlogsNumber()
    // Number Users Last year
    getUsersByYear()
    // Number Users Last month
    getUsersByMonth()
    // Number Products selled Last month
    getProductByMonth()
    // Number Products selled Last year
    getProductByYear()
    // Number Devis Valider
    getDevisValide()
    // Number Devis Non Valider
    getDevisNonValide()
    //Admin list
    getlistAdmins()
    getViews();


  }, []);


  return (
    <>
      <WichPage pageTitle="Tableau de bord" pageLocation="tableau de bord" />
      <div className="row">
        {/* customar project  start */}
        {role === "Admin" || role === "Moderateur" ?
          <div className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-around flex-wrap m-l-0">
                  <div className="col-2">
                    <i className="fas fa-users f-36 text-c-purple" />
                  </div>
                  <div className="col-10 d-flex flex-column">
                    <h6 className="text-muted m-b-10">
                      Nombre des utilisateurs durant ce mois
                    </h6>
                    <h2 className="m-b-0">{usersByMonth}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        {role === "Admin" || role === "Moderateur" ?
          <div className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-around flex-wrap m-l-0">
                  <div className="col-2">
                    <i className="fas fas fa-users f-36 text-c-red" />
                  </div>
                  <div className="col-10 d-flex flex-column">
                    <h6 className="text-muted m-b-10">
                      nombre des utilisateurs durant cette année
                    </h6>
                    <h2 className="m-b-0">{usersByYear}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
          <div className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-around flex-wrap m-l-0">
                  <div className="col-2">
                    <i className="fas fa-chart-line f-30 text-warning" />
                  </div>
                  <div className="col-10 d-flex flex-column">
                    <h6 className="text-muted m-b-10">
                      nombre de ventes durant ce mois
                    </h6>
                    <h2 className="m-b-0">{selledproductsByM}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}


        {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
          <div className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-around flex-wrap m-l-0">
                  <div className="col-2">
                    <i className="fas fa-chart-line f-30 text-warning" />
                  </div>
                  <div className="col-10 d-flex flex-column">
                    <h6 className="text-muted m-b-10">
                      nombre de ventes durant cette année
                    </h6>
                    <h2 className="m-b-0">{selledproductsByY}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        {/* customar project  end */}
        {/* subscribe start */}
        {role === "Admin" || role === "Moderateur" ?
          <div className="col-md-12 col-lg-3">
            <div className="card">
              <div className="card-body text-center">
                <i className="fas fa-user-tie f-36 text-c-blue" />
                <h4 className="m-t-20 m-b-20">
                  <span className="text-c-blue">{users}</span> Utilisateurs
                </h4>
                <Link to="/clients" className="btn btn-primary btn-sm btn-round">
                  Voir la liste
                </Link>
              </div>

            </div>
          </div>
          : null}
        {role === "Admin" || role === "Redacteur" ?
          <div className="col-md-12 col-lg-3">
            <div className="card">
              <div className="card-body text-center">
                <i className="far fa-newspaper f-36 text-c-red" />
                <h4 className="m-t-20 m-b-20">
                  <span className="text-c-red">{blogs}</span> Blogs
                </h4>
                <Link to="/blogs" className="btn btn-danger btn-sm btn-round">
                  Voir la liste
                </Link>
              </div>
              {/* <div id="operation-chart">
                <Line
                  data={{
                    labels: monts,
                    datasets: [
                      {
                        label: "# of Votes",
                        data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.2)",
                          "rgba(54, 162, 235, 0.2)",
                          "rgba(255, 206, 86, 0.2)",
                          "rgba(75, 192, 192, 0.2)",
                          "rgba(153, 102, 255, 0.2)",
                          "rgba(255, 159, 64, 0.2)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  width={100}
                  height={100}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div> */}
            </div>
          </div>
          : null}
        {role === "Admin" || role === "Moderateur" ?
          <div className="col-md-12 col-lg-3">
            <div className="card">
              <div className="card-body text-center">
                <i className="feather icon-mail text-c-blue d-block f-40"></i>
                <h4 className="m-t-20 m-b-20">
                  <span className="text-c-blue">{subscribers}</span> Abonnés
                </h4>
                <Link to="/abonnes" className="btn btn-primary btn-sm btn-round">
                  voir la liste
                </Link>
              </div>
              {/* <div id="operation-chart">
                <Bar
                  data={{
                    labels: monts,
                    datasets: [
                      {
                        label: "# of Votes",
                        data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.2)",
                          "rgba(54, 162, 235, 0.2)",
                          "rgba(255, 206, 86, 0.2)",
                          "rgba(75, 192, 192, 0.2)",
                          "rgba(153, 102, 255, 0.2)",
                          "rgba(255, 159, 64, 0.2)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  width={100}
                  height={100}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div> */}
            </div>
          </div>
          : null}
        {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
          <div className="col-md-12 col-lg-3">
            <div className="card">
              <div className="card-body text-center">
                <i className="fas fa-store text-c-green d-block f-40" />
                <h4 className="m-t-20 m-b-20">
                  <span className="text-c-green">{products}</span> Produits
                </h4>
                <Link to="/produits" className="btn btn-success btn-sm btn-round">
                  voir la liste
                </Link>
              </div>
            </div>
          </div>
          : null}
           {role === "Admin" || role === "Moderateur" ?
             <div className="col-xl-3 col-md-6">
             <div className="card">
               <div className="card-body">
                 <div className="row d-flex align-items-around flex-wrap m-l-0">
                   <div className="col-2">
                     <i className="fas fa-eye f-36 text-c-purple" />
                   </div>
                   <div className="col-10 d-flex flex-column">
                     <h6 className="text-muted m-b-10">
                       Nombre des visitors
                     </h6>
                     <h2 className="m-b-0">{Views}</h2>
                   </div>
                 </div>
               </div>
             </div>
           </div>
          : null}
        {/* subscribe end */}
      </div>
      <div className="row">
        {role === "Admin" || role === "Moderateur" || role === "Commercial" || role === "Moderateur_Commercial" ?
          <div className="col-12 col-md-4">
            {
              role === "Admin" || role === "Moderateur" ?
                <SubscibersCard /> : null
            }
            {
              role === "Admin" || role === "Commercial" || role === "Moderateur_Commercial" ?
                <BestSellingProducts /> : null
            }

          </div>
          : null}
        {role === "Admin" || role === "Moderateur" || role === "Commercial" || role === "Moderateur_Commercial" ?
          <div className="col-12 col-md-4">
            {role === "Admin" || role === "Moderateur" ?

              <div>
                <ComplaintsCard />
              </div>
              : null}

            <div>

              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="mb-0">Devis validé</h5>
                  <a href="/devisValides" className="mb-0">Voir plus</a>
                </div>
                <div className="card-body">
                  <div className="row d-flex align-items-around flex-wrap m-l-0">
                    <div className="col-auto">
                      <i className="icon feather icon-book f-30 text-c-purple" />
                    </div>
                    {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
                      <div className="col-auto">
                        <h6 className="text-muted m-b-10">Devis validé </h6>
                        <h2 className="m-b-0">{devisValide}</h2>
                      </div>
                      : null}
                    <hr />
                    <div className="table-responsive mt-2">
                      <table className="table table-hover m-0">
                        <thead>
                          <tr >
                            <th className="text-center" >#</th>
                            <th className="text-center text-wrap">Client</th>
                            <th className="text-center text-wrap">Nombre de produits</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            devisNV.map((devis, index) => (
                              <tr key={index} onClick={() => { window.location = `/devisDetails/${devis.id}` }}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center text-wrap">{devis.username ? devis.username : null}</td>
                                <td className="text-center text-wrap">{devis.nbr_produits ? devis.nbr_produits : null}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex align-items-around flex-wrap m-l-0">
                    <div className="col-auto">
                      <i className="icon feather icon-book f-30 text-c-purple" />
                    </div>
                    {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
                      <div className="col-auto">
                        <h6 className="text-muted m-b-10">
                          Devis Non validé
                        </h6>
                        <h2 className="m-b-0">{devisNonValide}</h2>

                      </div>
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}

        {role === "Admin" || role === "Moderateur" ?
          <div className="col-12 col-md-4">
            <MessagesCard />
          </div>
          : null}
      </div>
      {role === "Admin" ?
        <div className="row">
          {
            adminList.map((admin, index) => (
              <div key={index} className="col-md-6 col-xl-4">
                <div className="card client-map">
                  <div className="card-body">
                    <div className="client-detail">
                      <div className="client-profile">
                        <img src={window.IMAGE_API_URL + "avatar/" + admin.avatar} alt="" />
                      </div>
                      <div className="client-contain">
                        <h5>{admin.nom} {admin.prenom}</h5>
                        <a href="#!" className="text-muted">{admin.email}</a>
                        <p className="text-muted m-0 p-t-10">{admin.role}</p>
                      </div>
                    </div>
                    <div >
                      <div className="client-card-box">
                        <div className="row">
                          <div className="col-12 text-center client-border p-10">
                            <p className="text-muted m-0">Nombre d'activités total</p>
                            <span className="text-c-blue f-20 f-w-600">{admin.activity}</span>
                          </div>
                          {/* <div className="col-6 text-center p-10">
                            <p className="text-muted m-0">Pending</p>
                            <span className="text-c-blue f-20 f-w-600">12</span>
                          </div> */}
                        </div>
                        <div className="col-sm-12 client-border-card p-t-10">
                          <div id="operation-chart">
                            <Line
                              data={{
                                labels: adminListActivitiesDates[index],
                                datasets: [
                                  {
                                    label: "Nombre d'activités",
                                    data: adminListActivities[index],
                                    backgroundColor: [
                                      "rgba(255, 99, 132, 0.2)",
                                      "rgba(54, 162, 235, 0.2)",
                                      "rgba(255, 206, 86, 0.2)",
                                      "rgba(75, 192, 192, 0.2)",
                                      "rgba(153, 102, 255, 0.2)",
                                      "rgba(255, 159, 64, 0.2)",
                                    ],
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                              width={100}
                              height={200}
                              options={{
                                maintainAspectRatio: false,
                                scales: {
                                  y: { // defining min and max so hiding the dataset does not change scale range
                                    min: 0,
                                    // max: 100
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div id="client-map-1" style={{ height: '70px' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))

          }
        </div>
        : null}
    </>
  );
};

export default Home;
