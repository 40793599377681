import axios from "axios"
import authHeader from "./auth-header";
import AuthService from "./auth.service";
const user = AuthService.getCurrentUser()

const token = user.token
const role = sessionStorage.getItem("role");





const getAllRedacteurs = () => {
    if (role === "Admin") {
        URL = `${window.API_URL}/admin/redacteurs`;
      } else if (role === "Redacteur") {
        URL = `${window.API_URL}/redacteur/redacteurs`;
      }
    return axios.get(URL , authHeader())
}


const RedacteurService = {
    getAllRedacteurs,

}
export default RedacteurService;