import React, { useEffect, useState } from "react";
import CategoryService from "../../../services/category.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import Swal from "sweetalert2";
// import { XGrid } from '@material-ui/x-grid';
// import ModalAddCategory from "./modals/ModalAddCategory";
// import ModalEditCategoryName from "./modals/ModalEditCategoryName";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";

const Categories = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [categories, setcategories] = useState([]);

  const [category, setCategory] = useState("");
  const [idCategoryUpdate, setIdCategoryUpdate] = useState(0);

  const [name, setName] = useState("");
  const [image, setImage] = useState();

  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(true);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0] ? event.target.files[0] : null);
    setImage(event.target.files[0] ? event.target.files[0].name : "");
  };

  const renderCategoryImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + "category_product/" + row.image}
        alt={row.image}
        width={100}
      />
    );
  };

  const renderCatActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <button
          data-toggle="modal"
          data-target="#modal-edit"
          className="btn btn-icon btn-outline-primary MY-2"
          onClick={() => {
            setCategory(row.name);
            setIdCategoryUpdate(row.id);
            setName(row.name);
          }}
        >
          <i className="fas fa-edit" id={row.id}></i>
        </button>

        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          id={row.id}
          onClick={deleteCategory}
        >
          <i className="far fa-trash-alt" id={row.id}></i>
        </button>
      </div>
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "id" },
    {
      selector: "image",
      name: "IMAGE REPRESENTATIVE",
      width: "350px",
      cell: (row) => renderCategoryImage(row),
    },
    {
      selector: "name",
      name: "NOM DE LA CATEGORIE",
      width: "300px",
    },
    {
      selector: "nbr_product",
      name: "NOMBRE DE PRODUITS",
      editable: false,
      // hide: false
    },

    {
      name: "Action",
      width: "200px",

      cell: (row) => renderCatActions(row),
    },
  ]);
  const tableData = {
    columns,
    data: categories,
  };

  // load categories
  useEffect(() => {
    CategoryService.allCategories().then((res) => {
      // console.log(res)
      setcategories(res.data.results);
      setLoading(false);
    });
  }, []);

  const addCategory = () => {
    setName("");
    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");

    const formData = new FormData();

    formData.append("name", name);
    formData.append("image", selectedFile);

    if (name !== "" && image !== "") {
      CategoryService.addCategory(userId, formData, token)
        .then((res) => {
          // console.log(res)
          CategoryService.allCategories().then((res) => {
            // console.log(res)
            NotificationManager.success(
              "Catégorie ajoutée avec succès",
              "",
              2000
            );
            setcategories(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });
    } else {
      if (name === "")
        NotificationManager.warning("Le nom du catégorie est requis", "", 2000);
      if (image === "")
        NotificationManager.warning("L'image du catégorie est requise", "", 2000);
    }
  };

  // Update Category
  const updateCategory = () => {
    setName();
    // setCategory('')
    setSelectedFile(null);
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");
    if (name !== "" && image !== "") {
      if (name === "") {
        setName(category);
      }
      const formData = new FormData();

      formData.append("name", name);
      formData.append("id", idCategoryUpdate);
      formData.append("image", selectedFile);

      // const formDataImage = new FormData();
      // formDataImage.append('id', idCategoryUpdate);
      // formDataImage.append('image', selectedFile);

      // return console.log(formData.get("image") ? 1 : 0)
      // if (formData.get("image") != null ) {
      var URL = "";
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const data = formData;
      if (selectedFile && selectedFile !== {}) {
        if (role === "Admin") {
          URL = `${window.API_URL}/admin/img_catg`;
        } else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/img_catg`;
        } else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/img_catg`;
        }
        axios
          .patch(URL, data, config)
          .then((res) => {
            CategoryService.allCategories().then((res) => {
              NotificationManager.success(
                "Catégorie mise à jour avec succès",
                "",
                2000
              );
              setcategories(res.data.results);
            });
          })
          .catch(function (error) {
            if (error.response.status === 403) {
              NotificationManager.warning(error.response.data.error, "", 2000);

              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            } else if (error.response.status === 401) {
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
            }
          });
      }

      if (role === "Admin") {
        URL = `${window.API_URL}/admin/categorie`;
      } else if (role === "Commercial") {
        URL = `${window.API_URL}/commercial/categorie`;
      } else if (role === "Moderateur_Commercial") {
        URL = `${window.API_URL}/modcommercial/categorie`;
      }
      axios
        .patch(URL, { id: idCategoryUpdate, name: name }, config)
        .then((res) => {
          CategoryService.allCategories().then((res) => {
            NotificationManager.success(
              "Catégorie mise à jour avec succès",
              "",
              2000
            );
            setcategories(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });
      // }

      // CategoryService.updateCategory(formData, token)
      // .then((res) => {
      //   CategoryService.allCategories().then((res) => {
      //     NotificationManager.success('Category updated successfully', '', 2000);
      //     setcategories(res.data.results);
      //   })
      // })
      // .catch(function (error) {
      //   if (error.response.status === 403) {
      //     NotificationManager.warning(error.response.data.error, '', 2000);

      //     // console.log(error.response.data);
      //     // console.log(error.response.status);
      //     // console.log(error.response.headers);
      //   }
      //   else if (error.response.status === 401) {
      //     NotificationManager.error('Votre session est expiree, veuillez vous reconnectez', '', 4000);
      //   }
      // });
    } else {
      NotificationManager.warning("No data inserted", "", 2000);
    }
  };

  // DELETE CATEGORY

  const deleteCategory = (event) => {
    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    const categoryId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "Vous ne pourrez pas revenir sur cette catégorie !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez-le!",
        cancelButtonText: "Non, annulez !",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          CategoryService.deleteCategory(categoryId, token).then(
            (res) => {
              // console.log(res)
              setTimeout(() => {
                CategoryService.allCategories().then((res) => {
                  // console.log(res)
                  setcategories(res.data.results);
                });
              }, 300);
              swalWithBootstrapButtons.fire(
                "Supprimé!",
                "La catégorie a été supprimée.",
                "success"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "La catégorie n'a pas été supprimée.",
            "error"
          );
        }
      });
  };

  // useDataTableScripts();
  // useDataTableScripts();
  return (
    <div>
      <WichPage pageTitle="Categories" pageLocation="categories" />
      <div className="row">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card shadow-none">
            <div className="card-header">
              <h5>Catégories</h5>
              <div className="card-header-right">
                <h5>Ajouter une catégorie</h5>
                <button
                  className="btn btn-icon btn-outline-primary"
                  data-toggle="modal"
                  data-target="#modal-add"
                >
                  <i className="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="card-body">
                {!loading ? (
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                    />
                  </DataTableExtensions>
                ) : (
                  <ClipLoader color={"#000000"} loading={loading} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MODALS */}

      {/*  START ADD CATEGORY MODAL */}
      <div
        className="modal fade"
        id="modal-add"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter une catégorie</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      placeholder="Nom de la catégorie"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary save_catg"
                    onClick={addCategory}
                  >
                    <i className="feather icon-save save_catg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  END ADD CATEGORY MODAL */}

      {/*  START UPDATE CATEGORY MODAL */}

      <div
        className="modal fade"
        id="modal-edit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modifier le nom de la catégorie</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="my-3">
                    <label>Nom actuel : {category}</label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      onChange={(e) => setName(e.target.value)}
                      placeholder="le nouveau nom"
                    />
                  </div>
                  <label className="floating-label" htmlFor="Name">
                    la nouvelle image
                  </label>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary"
                    onClick={updateCategory}
                  >
                    <i className="feather icon-save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  END UPDATE CATEGORY MODAL */}
    </div>
  );
};

export default Categories;
