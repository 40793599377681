import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

const user = AuthService.getCurrentUser();
const role = sessionStorage.getItem("role");
var URL = "";
switch (user.role) {
  case "Admin":
    URL = window.API_URL + "/admin/";
    break;
  case "Redacteur":
    URL = window.API_URL + "/redacteur/";
    break;
  default:
    URL = window.API_URL;
    break;
}

const allBlogs = () => {
  return axios.get(window.API_URL + "/blogs");
};

const myBlogs = () => {
  return axios.get(URL + "list_blog_reda/" + user.id, authHeader());
};
// const allCategoryBlogs = () => {
//   return axios.get(
//     `${window.API_URL}/${
//       role.charAt(0).toLocaleLowerCase() + role.slice(1)
//     }/blog_categories/${user.id}`,
//     authHeader()
//   );
// };
// const allCategoryBlogs = () => {
//   return axios.get(`${URL}blog_categories`, authHeader());
// };
const allCategoryBlogs = () => {
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/blog_categories`;
  } else if (role === "Redacteur") {
    URL = `${window.API_URL}/redacteur/blog_categories`;
  }
  return axios.get(URL, authHeader());
};

// const updateCategoryBlog = (data) => {
//   return axios.patch(`${window.API_URL}blog_catg`, data, authHeader());
// };

// const addCategoryBlog = (data) => {
//   return axios.post(`${window.API_URL}/add_blog_cat/${user.id}`, data, authHeader());
// };
const addCategoryBlog = (data) => {
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/add_blog_cat/${user.id}`;
  } else if (role === "Redacteur") {
    URL = `${window.API_URL}/redacteur/add_blog_cat/${user.id}`;
  }
  return axios.post(URL, data, authHeader());
};
const updateCategoryBlog = (data) => {
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/blog_catg`;
  } else if (role === "Redacteur") {
    URL = `${window.API_URL}/redacteur/blog_catg`;
  }
  return axios.patch(URL, data, authHeader());
};

const deleteCatgBlog = (idCat) => {
  const config = {
    headers: authHeader().headers,
    data: { v_admin: user.id },
  };
  return axios.delete(`${window.API_URL}/admin/blog_catg/${idCat}`, config);
};

const deleteBlog = (id_blog) => {
  return axios.delete(
    `${window.API_URL}/${
      role.charAt(0).toLocaleLowerCase() + role.slice(1)
    }/blog/${id_blog}`,
    authHeader()
  );
};

const blogById = (id) => {
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/blog/${id}`;
  } else if (role === "Redacteur") {
    URL = `${window.API_URL}/redacteur/blog/${id}`;
  }
  return axios.get(URL, authHeader());
};
const commentsById = (id) => {
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/blog_comments/${id}`;
  } else if (role === "Redacteur") {
    URL = `${window.API_URL}/redacteur/blog_comments/${id}`;
  }
  return axios.get(URL, authHeader());
};
const commentByBlogId = (idBlog) => {
  return axios.get(`${window.API_URL}/blog_comments/${idBlog}`);
};

const addBlog = (data) => {
  return axios.post(`${window.API_URL}/admin/add_blog`, data, authHeader());
};

const addComment = (data) => {
  return axios.post(
    `${window.API_URL}/${
      role.charAt(0).toLocaleLowerCase() + role.slice(1)
    }/add_comment/${user.id}`,
    data,
    authHeader()
  );
};

const getBlogByAdmin = () => {
  var URL = "";
  if (role === "Admin") {
    URL = `${window.API_URL}/admin/list_blog_reda/${user.id}`;
  } else if (role === "Redacteur") {
    URL = `${window.API_URL}/redacteur/list_blog_reda/${user.id}`;
  }
  return axios.get(URL, authHeader());
};
const updateBlogContent = (data) => {
  return axios.patch(
    `${window.API_URL}/${
      role.charAt(0).toLocaleLowerCase() + role.slice(1)
    }/blog/${user.id}`,
    data,
    authHeader()
  );
};
const deleteComment = (id) => {
  const data = { admin_id: user.id };
  return axios.post(
    `${window.API_URL}/${
      role.charAt(0).toLocaleLowerCase() + role.slice(1)
    }/delete_comment/${id}`,
    data,
    authHeader()
  );
};
const importImage = (data) => {
  return axios.post(
    `${window.API_URL}/${
      role.charAt(0).toLocaleLowerCase() + role.slice(1)
    }/ImportImage`,
    data,
    authHeader()
  );
};

const updateBlogImage = (data) => {
  return axios.patch(
    `${window.API_URL}/${
      role.charAt(0).toLocaleLowerCase() + role.slice(1)
    }/blog/img/${user.id}`,
    data,
    authHeader()
  );
};
const BlogService = {
  allBlogs,
  myBlogs,
  allCategoryBlogs,
  addCategoryBlog,
  updateCategoryBlog,
  deleteCatgBlog,
  blogById,
  addBlog,
  getBlogByAdmin,
  commentsById,
  deleteBlog,
  addComment,
  updateBlogImage,
  updateBlogContent,
  commentByBlogId,
  deleteComment,
  importImage,
};

export default BlogService;
