import Moment from "react-moment";
import React, { useEffect, useState } from "react";
import SliderService from "../../../services/slider.service";
import useDataTableScripts from "../../inc/helpfulComponents/useDataTableScripts";
import WichPage from "../../inc/helpfulComponents/WichPage";
import dataTableScripts from "../../inc/scripts/dataTableScripts";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { ClipLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import AuthService from "../../../services/auth.service";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import axios from "axios";


const Sliders = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const [slider, setSlider] = useState({});
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [Bulle, setBulle] = useState("");
  const [titre, setTitre] = useState("");
  const [ImageUrl, setImageUrl] = useState("");
  const [type, setType] = useState("");
  const [url, setUrl] = useState('');
  const [color, setColor] = useState('#2f4e81');
  const [urlTitle, setUrlTitle] = useState('');

  const [description, setDescription] = useState("");
  const [image, setimage] = useState();
  const [idSlideUpdate, setIdSliderUpdate] = useState(0);

  const rendeImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + `Sliders/` + row.image}
        alt={row.image}
        width={150}
        className="my-3"
      />
    );
  };
  const [columns, setColumns] = useState([
    {
        name: "IMAGE REPRESENTATIVE",
        selector: "image",
        width: "180px",
        cell: (row) => rendeImage(row),
      },
    {
      name: "Titre",
      selector: "titre",
      sortable: true,
      width: "280px",
      wrap: true,
    },
    {
      name: "Bulle",
      selector: "Bulle",
      sortable: true,
      width: "auto",
      wrap: true,
    },
    {
      name: "Image URL",
      selector: "image_url",
      sortable: true,
      width: "auto",
      wrap: true,
      cell: (row) => row.image_url || '',
    },
    {
      name: "URL",
      selector: "url",
      sortable: true,
      width: "auto",
      wrap: true,
      cell: (row) => row.url || '',
    },
    {
      name: "Button title",
      selector: "button_title",
      sortable: true,
      width: "auto",
      wrap: true,
      cell: (row) => row['button_title'] || '',
    },
    {
      name: "Button color",
      selector: "color",
      sortable: true,
      width: "auto",
      wrap: true,
      cell: (row) => <div style={{backgroundColor : row['color'] || '#3845b5', width : 25, height : 25, borderRadius : 20}}/>,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      width: "100px",
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      width: "400px",
      wrap: true,
    },
    {
      name: "Actions",
      selector: "",
      sortable: true,
      // width: "100px",
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          className="d-flex align-items-center justify-content-arround w-100"
        >
          {/* <div className="d-flex align-items-center justify-content-arround w-100"> */}
          <button
            data-toggle="modal"
            data-target="#modal-edit"
            className="btn btn-icon btn-outline-primary"
            onClick={() => {
              setSlider({...row});
              setIdSliderUpdate(row.id);
              setimage(row.image);
              setTitre(row.titre);
              setUrl(row.url || '');
              setUrlTitle(row.button_title || '');
              setColor(row.color || '');
              setBulle(row.Bulle);
              setType(row.type);
              setImageUrl(row.image_url);
              setDescription(row.description);
              getSliderById(row.id)
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
          <button
            type="button"
            className="btn btn-icon btn-outline-danger mx-3 my-4"
            id={row.id}
            onClick={deleteSlider}
          >
            <i className="far fa-trash-alt" id={row.id}></i>
          </button>
          {/* </div> */}
        </div>
      ),
    },
  ]);
  const tableData = {
    columns,
    data: sliders,
  };

  useEffect(() => {
    setLoading(true);
    SliderService.getSliders()
      .then((res) => {
        setSliders(res.data.results);
        setLoading(false);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Impossible de charger la liste des curseurs",
                "",
                2000
              );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }, []);

  const getSliderById = (id) => {
    SliderService.getSliderById(id)
      .then((res) => {
        console.log(res.data.result[0]);
        setSlider(res.data.result[0]);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Impossible de charger les informations du curseur",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

   // ADD Slider
  const addSlider = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("titre", titre);
    formData.append("Bulle", Bulle);
    formData.append("type", type);
    formData.append("description", description);
    formData.append("url", url);
    formData.append("button_title", urlTitle);
    formData.append("color", color);
    formData.append("image_url", ImageUrl);
    formData.append("id", idSlideUpdate);
    formData.append("image", selectedFile);

    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");

    if ( titre !== "" && Bulle !== "" && type !== "" && description !== "" && image !== "") {
      try {
        SliderService.addSlider(formData, userId, token)
        .then((res) => {
          setBulle("");
          setTitre("");
          setImageUrl('');
          setType("");
          setUrl('');
          setUrlTitle('');
          setColor('');
          setDescription("");
          setSelectedFile(null);
          SliderService.getSliders().then((res) => {
            NotificationManager.success(
              "Le slide est ajoute avec success",
              "",
              2000
            );
            setSliders(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, reconnectez-vous svp ",
              "",
              4000
            );
          }
        });
      }
      catch(e){
        NotificationManager.error(
          e?.response?.data?.error || e?.response?.data?.message || e?.error,
          "",
          4000
        );
      }
    } else {
      
      if (url === '' || titre === "" || Bulle === "" || type === "" || description === "" || image === "") {
        NotificationManager.warning(
          titre == '' ? "title is required" :
          (Bulle == '' ? 'Name is required' :
          (type === '' ? 'type is required' :
          (description == '' ? 'descripiton is required' : "image is required")))
          , 
          "",
          2000
        );
      }
    }
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0] ? event.target.files[0] : null);
    setimage(event.target.files[0] ? event.target.files[0].name : "");
  };
  // Update Slider
  const updateSlider = () => {
    // const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    if (
      titre !== "" &&
      Bulle !== "" &&
      type !== "" &&
      description !== ""
    ) {
      const formData = new FormData();

      formData.append("titre", titre);
      formData.append("Bulle", Bulle);
      formData.append("type", type);
      formData.append("button_title", urlTitle);
      formData.append("color", color);
      formData.append("description", description);
      formData.append("id", idSlideUpdate);
      formData.append("url", url);
      formData.append("image_url", ImageUrl);
      formData.append("image", selectedFile);

      var URL = "";
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const data = formData;
      if (selectedFile && selectedFile !== {}) {
        if (role === "Admin") {
          URL = `${window.API_URL}/admin/sliderImage`;
        }
        axios
          .patch(URL, data, config)
          .then((res) => {
            setBulle("");
            setTitre("");
            setImageUrl('');
            setType("");
            setUrl('');
            setUrlTitle('');
            setColor('');
            setDescription("");
            setSelectedFile(null);
            SliderService.getSliders().then((res) => {
              NotificationManager.success(
                "Le Slider est modifier avec success",
                "",
                2000
              );
              setSliders(res.data.results);
            });
          })
          .catch(function (error) {
            if (error.response.status === 403) {
              NotificationManager.warning(error.response.data.error, "", 2000);
            } else if (error.response.status === 401) {
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
            }
          });
      }

      if (role === "Admin") {
        URL = `${window.API_URL}/admin/slider`;
      }
      axios
        .patch(
          URL,
          {
            id: idSlideUpdate,
            titre: titre,
            Bulle: Bulle,
            url : url,
            button_title : urlTitle,
            color : color,
            type: type,
            image_url : ImageUrl,
            description: description,
          },
          config
        )
        .then((res) => {
          SliderService.getSliders().then((res) => {
            NotificationManager.success(
              "Le slide est modifie avec success",
              "",
              2000
            );
            setSliders(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });
    } else 
      return NotificationManager.warning(
        titre == '' ? "title is required" :
        (Bulle == '' ? 'Name is required' :
        (type === '' ? 'type is required' :
        (description == '' ? 'descripiton is required' : "image is required"))),
        "",
        2000
      );
  };

  const deleteSlider = (event) => {
    const token = sessionStorage.getItem("token");
    const sliderId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes Vous sur?",
        text: "Vous ne pourrez plus voir ce curseur!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez!",
        cancelButtonText: "Non, annullez!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          SliderService.deletSlider(sliderId, token)
            .then((result) => {
              setTimeout(() => {
                SliderService.getSliders().then(async (res) => {
                  setSliders(res.data.results);
                  setLoading(false);
                });
              }, 300);

              swalWithBootstrapButtons.fire(
                "Supprime!",
                "Le curseur est supprime",
                "success"
              );
            })
            .catch(function (error) {
              if (!error.response) {
                swalWithBootstrapButtons.fire(
                  "Annuller",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? swalWithBootstrapButtons.fire(
                      "Annuller",
                      error.response.data.error,
                      "error"
                    )
                  : swalWithBootstrapButtons.fire(
                      "Annuller",
                      "Nous N'avons pas pus supprimmer le curseur",
                      "error"
                    );
              } else if (error.response.status === 401) {
                setLoading(false);
                swalWithBootstrapButtons.fire(
                  "Annuller",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Annuller",
            "Votre curseur est encore la",
            "erreur"
          );
        }
      });
  };

  return (
    <>
      <WichPage pageTitele="Abonnés" pageLocation="abonnes" />
      <div className="row">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5>Sliders</h5>
              <div className="card-header-right">
                <h5 className="">Ajouter un slide</h5>
                <button
                  className="btn btn-icon btn-outline-primary"
                  data-toggle="modal"
                  data-target="#modal-add"
                >
                  <i className="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              {!loading ? (
                <div className="myaccount-content">
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      columns={columns}
                      data={sliders}
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                      selectableRows={false}
                    />
                  </DataTableExtensions>
                </div>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
          {/* MODALS */}

      {/*  START ADD Slide MODAL */}
      <div
        className="modal fade"
        id="modal-add"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter un slide</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div>
                    <b>Title</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="titre"
                      onChange={(e) => setTitre(e.target.value)}
                      name="name"
                      value={titre}
                      placeholder="Titre"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Name</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Bulle"
                      onChange={(e) => setBulle(e.target.value)}
                      name="name"
                      value={Bulle}
                      placeholder="Bulle"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Redirect url</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Url"
                      onChange={(e) => setUrl(e.target.value)}
                      name="url"
                      value={url}
                      placeholder="url"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Button Title</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="UrlTitle"
                      onChange={(e) => setUrlTitle(e.target.value)}
                      name="button title"
                      value={urlTitle}
                      placeholder="title for the slider button"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Button Color</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="color"
                      className="form-control"
                      id="color"
                      style={{marginLeft : 20,width : 100}}
                      onChange={(e) => setColor(e.target.value)}
                      name="button color"
                      value={color}
                      placeholder="color for the slider button"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="" htmlFor="type">
                      <b>Type</b>
                    </label>
                    <select
                      className="form-control"
                      id="type"
                      onChange={(e) => setType(e.target.value)}
                      name="type"
                    >
                      <option selected></option>
                      <option value="Produit"> Produit </option>
                      <option value="Blog"> Blog </option>
                      
                    </select>
                </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Description</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      onChange={(e) => setDescription(e.target.value)}
                      name="name"
                      value={description}
                      placeholder="description"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Image Url</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="ImageURL"
                      onChange={(e) => setImageUrl(e.target.value)}
                      name="Image url"
                      value={ImageUrl}
                      placeholder="url for the slider image."
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Image</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      id="SliderImage"
                      name="image"
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary save_catg"
                    onClick={addSlider}
                    // data-dismiss="modal"
                  >
                    <i className="feather icon-save save_catg" />
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  END ADD Slider MODAL */}

      {/*  START UPDATE Slider MODAL */}

      <div
        className="modal fade"
        id="modal-edit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modifier le slide</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="m-3">
                    <label>Titre actuel : <b>{slider.titre}</b></label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="titre"
                      onChange={(e) => setTitre(e.target.value)}
                      name="name"
                      value={titre}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="m-3">
                    <label>Bulle actuelle : <b>{slider.Bulle}</b></label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Bulle"
                      onChange={(e) => setBulle(e.target.value)}
                      name="name"
                      value={Bulle}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="m-3">
                    <label>url actuelle : <b>{slider.url || ''}</b></label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Url"
                      onChange={(e) => setUrl(e.target.value)}
                      name="url"
                      value={url}
                      placeholder="url"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="m-3">
                    <label>button title actuelle : <b>{slider.button_title || ''}</b></label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="UrlTitle"
                      onChange={(e) => setUrlTitle(e.target.value)}
                      name="button title"
                      value={urlTitle}
                      placeholder="title for the slider button"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="m-3">
                    <label>Color actuel : <b>{slider.color || ''}</b></label>
                  </div>
                  <div className="form-group">
                    <b>Pick a Color :</b>
                    <input
                      type="color"
                      className="form-control"
                      style={{marginLeft : 20,width : 100}}
                      id="color"
                      onChange={(e) => setColor(e.target.value)}
                      name="button color"
                      value={color}
                      placeholder="color for the slider button"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="m-3">
                    <label>Type actuel : <b>{slider.type}</b></label>
                  </div>
                  <div className="form-group">
                    <label className="" htmlFor="type">
                      Type
                    </label>
                    <select
                      className="form-control"
                      id="type"
                      onChange={(e) => setType(e.target.value)}
                      name="type"
                    >
                      <option value="Blog"> Blog </option>
                      <option value="Produit"> Produit </option>
                    </select>
                </div>
                </div>
                <div className="col-12">
                  <div className="m-3">
                    <label>Description actuelle : <b>{slider.description}</b></label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      onChange={(e) => setDescription(e.target.value)}
                      name="name"
                      value={description}
                      placeholder="description"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="m-3">
                    <label>Image url actuelle : <b>{slider.image_url || ''}</b></label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="ImgUrl"
                      onChange={(e) => setImageUrl(e.target.value)}
                      name="Image url"
                      value={ImageUrl}
                      placeholder="url for the slider image"
                    />
                  </div>
                </div>
                <div className="col-12">
                  {/* <div className="m-3">
                    <label>
                      Image actuel :  {image}
                    </label>
                  </div> */}
                  <label className="floating-label" htmlFor="Name">
                    Nouvelle 
                  </label>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      onChange={changeHandler}
                      // value={image}
                    />
                  </div>
                </div>

                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary"
                    onClick={updateSlider}
                    data-dismiss="modal"
                  >
                    <i className="feather icon-save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  END UPDATE Slider MODAL */}
      </div>
    </>
  );
};

export default Sliders;
