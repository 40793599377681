/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../../../services/auth.service";

const NavLink = ({ to, ...rest }) => {
  let location = useLocation();
  let style = { background: "#4680ff", color: "#fff" };
  if (location.pathname === to) {
    return <Link to={to} {...rest} style={style} />;
  }
  return <Link to={to} {...rest} />;
};

const Sidebar = () => {
  const user = AuthService.getCurrentUser();
  const role = sessionStorage.getItem("role");
  return (
    <nav className="pcoded-navbar menu-light ">
      <div className="navbar-wrapper  ">
        <div className="navbar-content scroll-div ">
          <div className="">
            <div className="main-menu-header">
              <img
                // className="img-radius"
                src={window.IMAGE_API_URL + "avatar/" + user.avatar}
                alt="User-Profile-Image"
                style={{ width: "80px", height: "80px", borderRadius:"50%" }}
              />
              <div className="user-details">
                <div id="more-details">
                  {user.user}
                  <i className="fa fa-caret-down" />
                </div>
              </div>
            </div>
            <div className="collapse" id="nav-user-link">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link
                    to="user-profile.html"
                    data-toggle="tooltip"
                    title="View Profile"
                  >
                    <i className="feather icon-user" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="email_inbox.html">
                    <i
                      className="feather icon-mail"
                      data-toggle="tooltip"
                      title="Messages"
                    />
                    <small className="badge badge-pill badge-primary">5</small>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="auth-signin.html"
                    data-toggle="tooltip"
                    title="Logout"
                    className="text-danger"
                  >
                    <i className="feather icon-power" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <ul className="nav pcoded-inner-navbar ">
            <li className="nav-item pcoded-menu-caption">
              <label>Navigation</label>
            </li>
            <li className="nav-item">
              <NavLink to="/" className="nav-link">
                <span className="pcoded-micon">
                  <i className="feather icon-home" />
                </span>
                <span className="pcoded-mtext">Tableau de bords</span>
              </NavLink>
            </li>
            {role === "Admin" || role === "Moderateur" ?
            <li className="nav-item">
              <NavLink to="/reclamations" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-newspaper" />
                </span>
                <span className="pcoded-mtext">Réclamations</span>
              </NavLink>
            </li>
             : null}
        {role === "Admin" || role === "Moderateur" ?
            <li className="nav-item">
              <NavLink to="/contacts" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="feather icon-mail" />
                </span>
                <span className="pcoded-mtext">Messages</span>
              </NavLink>
            </li>
             : null}
            {role === "Admin" || role === "Moderateur" ?
            <li className="nav-item">
              <NavLink to="/abonnes" className="nav-link"
              >
                <span className="pcoded-micon">
                  <i className="fas fa-user-check" />
                </span>
                <span className="pcoded-mtext">Abonnés</span>
              </NavLink>
            </li>
             : null}
            {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/stock" className="nav-link has-ripple">
                <span className="pcoded-micon">
                  <i className="fas fa-box" />
                </span>
                <span className="pcoded-mtext">Stock</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur" ?
            <li className="nav-item">
              <NavLink to="/clients" className="nav-link">
                <span className="pcoded-micon">
                  <i className="fas fa-users" />
                </span>
                <span className="pcoded-mtext">Clients</span>
              </NavLink>
            </li>
             : null}
            {role === "Admin"  ?
            <li className="nav-item">
              <NavLink to="/admins" className="nav-link">
                <span className="pcoded-micon">
                  <i className="fas fa-users-cog" />
                </span>
                <span className="pcoded-mtext">Administrateurs</span>
              </NavLink>
            </li>
             : null}
            {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item pcoded-menu-caption">
              <label>Menu - Produits</label>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/produits" className="nav-link has-ripple">
                <span className="pcoded-micon">
                  <i className="fas fa-store" />
                </span>
                <span className="pcoded-mtext">Produits</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/pdoduitsEnSolde" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-funnel-dollar" />
                </span>
                <span className="pcoded-mtext">Produits en solde</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/nouveauxArrivages" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-plane-arrival" />
                </span>
                <span className="pcoded-mtext">Nouveaux arrivages</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/amazing_products" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-plane-arrival" />
                </span>
                <span className="pcoded-mtext">Offres  Combinées</span>
              </NavLink>
            </li>
             : null}

            {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/etonnant-products" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-plane-arrival" />
                </span>
                <span className="pcoded-mtext">les plus etonnants</span>
              </NavLink>
            </li>
             : null}

            {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/covid-products" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-plane-arrival" />
                </span>
                <span className="pcoded-mtext">Covid</span>
              </NavLink>
            </li>
             : null}

             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/sliders" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-images" />
                </span>
                <span className="pcoded-mtext">Sliders </span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/subsliders" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-images" />
                </span>
                <span className="pcoded-mtext">Sub-Sliders </span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/categories" className="nav-link">
                <span className="pcoded-micon">
                  <i className="feather icon-align-justify" />
                </span>
                <span className="pcoded-mtext">Categories</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item ">
              <NavLink to="/souscategories" className="nav-link">
                <span className="pcoded-micon">
                  <i className="feather icon-align-justify" />
                </span>
                <span className="pcoded-mtext">Sous Categories</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/familles" className="nav-link">
                <span className="pcoded-micon">
                  <i className="feather icon-align-justify" />
                </span>
                <span className="pcoded-mtext">Familles</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Moderateur_Commercial" || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/marques" className="nav-link">
                <span className="pcoded-micon">
                  <i className="feather icon-align-justify" />
                </span>
                <span className="pcoded-mtext">Marques</span>
              </NavLink>
            </li>
            : null}
            {role === "Admin"  || role === "Commercial" ?
            <li className="nav-item pcoded-menu-caption">
              <label>Menu Devis</label>
            </li>
             : null}
            {role === "Admin"  || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/devisDemandes" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-receipt" />
                </span>
                <span className="pcoded-mtext">Demandes de devis</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin"  || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/devisValides" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="feather icon-check-square" />
                </span>
                <span className="pcoded-mtext">devis validés</span>
              </NavLink>
            </li>
             : null}

            {role === "Admin"  || role === "Commercial" ?
            <li className="nav-item pcoded-menu-caption">
              <label>Client Orders</label>
            </li>
             : null}
            {role === "Admin"  || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/orders/pending" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-receipt" />
                </span>
                <span className="pcoded-mtext">List orders</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin"  || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/orders/delivered" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="feather icon-check-square" />
                </span>
                <span className="pcoded-mtext">Delivered orders</span>
              </NavLink>
            </li>
             : null}

            {role === "Admin"  || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/orders/cancelled" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="feather icon-check-square" />
                </span>
                <span className="pcoded-mtext">cancelled orders</span>
              </NavLink>
            </li>
             : null}

{role === "Admin"  || role === "Commercial" ?
            <li className="nav-item pcoded-menu-caption">
              <label>Achat Imédiat</label>
            </li>
             : null}
            {role === "Admin"  || role === "Commercial" ?
            <li className="nav-item">
              <NavLink to="/achat-imediat" className="nav-link ">
                <span className="pcoded-micon">
                  <i className="fas fa-receipt" />
                </span>
                <span className="pcoded-mtext">List achats</span>
              </NavLink>
            </li>
             : null}

            {role === "Admin" || role === "Redacteur" ?
            <li className="nav-item pcoded-menu-caption">
              <label>Menu Blogs</label>
            </li>
             : null}
            {role === "Admin" || role === "Redacteur" ?
            <li className="nav-item">
              <NavLink to="/redacteurs" className="nav-link">
                <span className="pcoded-micon">
                  <i className="fas fa-user-edit" />
                </span>
                <span className="pcoded-mtext">Rédacteurs</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Redacteur" ?
            <li className="nav-item">
              <NavLink to="/blogs" className="nav-link">
                <span className="pcoded-micon">
                  <i className="fas fa-newspaper" />
                </span>
                <span className="pcoded-mtext">Blogs</span>
              </NavLink>
            </li>
             : null}
             {role === "Admin" || role === "Redacteur" ?
            <li className="nav-item">
              <NavLink to="/categoryBlogs" className="nav-link">
                <span className="pcoded-micon">
                  <i className="fas fa-newspaper" />
                </span>
                <span className="pcoded-mtext">Catégories de blogs</span>
              </NavLink>
            </li>
             : null}
            
            <li className="nav-item pcoded-menu-caption">
              <label>Menu Administrateur </label>
            </li>
            <li className="nav-item">
              <NavLink to="/profil" className="nav-link active">
                <span className="pcoded-micon">
                  <i className="fas fa-user-shield" />
                </span>
                <span className="pcoded-mtext">Profile</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
