import axios from 'axios';
import authHeader from './auth-header';
 
const userId = sessionStorage.getItem("id_user")
const role = sessionStorage.getItem('role');

const getReclamations = ()=>{
    var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/reclamations`
     }
    //  else if (role === "Commercial") {
    //       URL = `${window.API_URL}/commercial/add_marque/${idAdmin}`
    //  }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/reclamations`
     }
    return axios.get(URL, authHeader())
} 

const getReclamationById = (id)=>{
    var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/reclamation/${id}`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/reclamation/${id}`
     }
    return axios.get(URL, authHeader())
} 

const AddAnswerReclamation = (data) => {
     console.log("I m here")
    var URL = ''
    if (role === "Admin") {
         URL = `${window.API_URL}/admin/reponse_reclam/${userId}`
    }
    else if (role === "Moderateur") {
         URL = `${window.API_URL}/moderateur/reponse_reclam/${userId}`
    }
   return axios.patch(URL,data, authHeader())
}

const deleteReclamation = (id, token) => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/reclamation/${id}`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/reclamation/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               },               
          });

}

const ReclamationService = {
    getReclamations,
    getReclamationById,
    AddAnswerReclamation,
    deleteReclamation

}

export default ReclamationService ;