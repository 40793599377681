import Moment from "react-moment";
import React, { useEffect, useState } from "react";
import AdminService from "../../../services/admin.service";
import useDataTableScripts from "../../inc/helpfulComponents/useDataTableScripts";
import WichPage from "../../inc/helpfulComponents/WichPage";
import dataTableScripts from "../../inc/scripts/dataTableScripts";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { ClipLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import AuthService from "../../../services/auth.service";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";



const Subscribers = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(false)
  const [displayDelete, setDisplayDelete] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  // const [subIds ,setSubIds] = useState([])
  const [columns, setColumns] = useState([

    // { field: 'id', headerName: 'id', width: 90, editable: false, hide: true },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      width: "auto",
    },
    {
      name: "Date",
      selector: "created_at",
      sortable: true,
      width: "auto",
      cell: (d) => <div className="d-flex w-100 align-items-center justify-content-start">
        <Moment format="DD/MM/YYYY" className="text-center">
          {d.created_at}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{d.created_at}</Moment> )
        </div>
      </div>
    },
    {
      name: "Actions",
      selector: "",
      sortable: true,
      width: "150px",
      cell: row =>
        <div data-tag="allowRowEvents" className="w-100">
          <div className="d-flex align-items-center justify-content-arround">
            <button
              type="button"
              className="btn btn-icon btn-outline-danger my-3"
              id={row.id}
              onClick={deleteSubscriber}
            >
              <i
                className="far fa-trash-alt"
                id={row.id}
              ></i>
            </button>
          </div>
        </div>,
    },

  ]);
  const tableData = {
    columns,
    data: subscribers
  };


  const deleteAll = () => {
    const list = []
    selectedRows.selectedRows.map(item => {
      list.push(item.id)
    })
    const data = {
      list_id: list
    }

    swalWithBootstrapButtons
      .fire({
        title: "Etes Vous sur?",
        text: "Vous ne pourrez plus voir ces abonnes!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez!",
        cancelButtonText: "Non, annullez!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          AdminService.removeMultiSubscribers(data)
            .then(res => {
              swalWithBootstrapButtons.fire(
                "Supprimer!",
                "Tous les abonnés ont été supprimés avec succès",
                "success"
              );

              setTimeout(() => {
                AdminService.allSubscribers().then(async (res) => {
                  setSubscribers(res.data.results);
                  setLoading(false);
                });
              }, 300);
            })
            .catch(function (error) {
              if (!error.response) {
                swalWithBootstrapButtons.fire(
                  "Annuller",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? swalWithBootstrapButtons.fire(
                    "Annuller",
                    error.response.data.error,
                    "erreur"
                  )
                  : swalWithBootstrapButtons.fire(
                    "Annuller",
                    "Nous N'avons pas pu supprimer les abonnees",
                    "erreur"
                  );

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.response.status === 401) {
                setLoading(false);
                swalWithBootstrapButtons.fire(
                  "Annuller",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annuller",
            "Votre abonnees sont encore la",
            "erreur"
          );
        }
      });


  }

  useEffect(() => {
    console.log(selectedRows)
    if (selectedRows.selectedCount > 0) {
      setDisplayDelete(true)

    }
    else {
      setDisplayDelete(false)
    }
  }, [selectedRows])

  useEffect(() => {
    setLoading(true)
    AdminService.allSubscribers().then((res) => {
      setSubscribers(res.data.results);
      setLoading(false)

    }).catch(function (error) {
      if (!error.response) {
        NotificationManager.error(
          "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
      }
      else if (error.response.status === 403) {
        setLoading(false)
        error.response.data.error || error.response.data.message ?
          NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
          :
          NotificationManager.warning("Nous n'avons pas pu recharger la liste des abonnees", "", 2000)

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.response.status === 401) {
        setLoading(false)
        NotificationManager.error(
          "Votre session est expiree, veuillez vous reconnectez",
          "",
          4000
        );
        setTimeout(() => {
          AuthService.logout()
        }, 500);
      }
    });
  }, []);

  const deleteSubscriber = (event) => {
    const token = sessionStorage.getItem("token");
    const subscriberId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes Vous sur?",
        text: "Vous ne pourrez plus voir cet abonne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez!",
        cancelButtonText: "Non, annullez!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          AdminService.removeSubscriber(subscriberId, token)
            .then((result) => {
              setTimeout(() => {
                AdminService.allSubscribers().then(async (res) => {
                  setSubscribers(res.data.results);
                  setLoading(false);
                });
              }, 300);

              swalWithBootstrapButtons.fire(
                "Supprimer!",
                "L'abonne est supprime.",
                "success"
              );
            })
            .catch(function (error) {
              if (!error.response) {
                swalWithBootstrapButtons.fire(
                  "Annuller",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? swalWithBootstrapButtons.fire(
                    "Annuller",
                    error.response.data.error,
                    "erreur"
                  )
                  : swalWithBootstrapButtons.fire(
                    "Annuller",
                    "Nous N'avons pas pu supprimer l'abonne",
                    "erreur"
                  );

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.response.status === 401) {
                setLoading(false);
                swalWithBootstrapButtons.fire(
                  "Annuller",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annuller",
            "Votre subscriber est encore la ",
            "erreur"
          );
        }
      });
  };


  return (
    <>
      <WichPage pageTitele="Abonnés" pageLocation="abonnes" />
      <div className="row">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5>Abonnés à la newsletter</h5>
              <div className={displayDelete ? "btn btn-outline-danger btn-sm" : "btn btn-outline-danger btn-sm d-none"} onClick={deleteAll}>
                Supprimer tous
              </div>
            </div>

            <div className="card-body">
              {!loading ?
                <div className="myaccount-content">
                  <DataTableExtensions {...tableData} exportHeaders={true} >

                    <DataTable
                      columns={columns}
                      data={subscribers}
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                      selectableRows={true}
                      className="mt-2"
                      onSelectedRowsChange={(rows) => { setSelectedRows(rows) }}
                    />
                  </DataTableExtensions>

                </div>
                :
                <ClipLoader color={"#000000"} loading={loading} />}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribers;
