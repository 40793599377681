import React, { useEffect, useState } from "react";

import DashboardService from "../../../services/dashboard.service";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";

const BestSellingProducts = () => {
  const [products, setProducts] = useState([]);
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    // products Number
    if (role === "Admin" || role === "Commercial" || role === "Moderateur_Commercial") {
      DashboardService.getHotProducts().then((res) => {
        console.log(res.data.results)
        setProducts(res.data.results);
        setLoading(false)
        // console.log(res.data)
      })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
          else if (error.response.status === 403) {
            setLoading(false)
            error.response.data.error || error.response.data.message ?
              NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
              :
              NotificationManager.warning("Nous n'avons pas pu recuperer le nombre des utilisateurs", "", 2000)
          } else if (error.response.status === 401) {
            setLoading(false)
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout()
            }, 500);
          }
        });
    }
  }, []);


  return (
    <>
      {
        role === "Admin" || role === "Moderateur" ?
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5 className="mb-0">Produits les plus vendus</h5>
              <a href="/produits" className="mb-0">Voir plus</a>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover m-0">
                  <thead>
                    <tr >
                      <th className="text-center" ></th>
                      <th className="text-center text-wrap">Produit</th>
                      <th className="text-center text-wrap">Nombre de commandes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      products.map(product => (
                        <tr key={product.id} onClick={()=> {window.location=`/DetailsProduit/${product.id}`}}>
                          <td className="text-center"> {product.image ? <img src={window.IMAGE_API_URL + "products/" + product.image.split(';')[0]} alt="" style={{width:"60px"}}  /> : null } </td>
                          <td className="text-center text-wrap">{product.name ? product.name : ''}</td>
                          <td className="text-center text-wrap">{product.total_orders ? product.total_orders : 0}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> : null
      }

    </>
  )
};

export default BestSellingProducts;
