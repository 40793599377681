import axios from "axios";

const allSubCategories = () => {
     return axios.get(window.API_URL + "/sub_categories");


}

const allCategories = () => {
     return axios.get(window.API_URL + "/categories");
}

const getSubCategoryById = (id) => {
     return axios.get(`${window.API_URL}/sub_categorie/:${id}`);
}

const role = sessionStorage.getItem('role');

const addSubCategory = (category_id, name, adminId, token) => {

     const config = { headers: { "Authorization": `Bearer ${token}` } }
     const data = { category_id: category_id, name: name }

     var URL = ''

     if (role === "Admin") {
          URL = `${window.API_URL}/admin/sub_categorie/${adminId}`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/sub_categorie/${adminId}`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/sub_categorie/${adminId}`
     }

     return axios.post(URL, data, config)
}

const updateSubCategory = (idSubCat, category_id, name, token) => {

     const config = { headers: { "Authorization": `Bearer ${token}` } }
     const data = { id: idSubCat, category_id: category_id, name: name }

     var URL = ''

     if (role === "Admin") {
          URL = `${window.API_URL}/admin/sub_categorie/`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/sub_categorie/`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/sub_categorie/`
     }

     return axios.patch(URL, data, config)
}

const deleteSubCategory = (id, idAdmin, token) => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/sub_categorie/${id}`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/sub_categorie/${id}`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/sub_categorie/${id}`
     }
     return axios.delete(URL,
          {
               headers: {
                    Authorization: `Bearer ${token}`
               },
               data: {
                    idAdmin: idAdmin
               }
          });
}

const getSybCateoiesByCategoryId = (id)=> {
     const URL = `${window.API_URL}/subcat_by_catg/${id}`;
     return axios.get(URL)
}

const SubCategoryService = {
     allSubCategories,
     getSubCategoryById,
     addSubCategory,
     updateSubCategory,
     deleteSubCategory,
     allCategories,
     getSybCateoiesByCategoryId
}

export default SubCategoryService;