import WichPage from "../../inc/helpfulComponents/WichPage";
import { useEffect, useState } from "react";
import ProductService from "../../../services/product.service";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { ClipLoader } from "react-spinners";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const Promodujour = () => {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    
      const [promoDuJR, setpromoDuJR] = useState([]);
      const [loading, setLoading] = useState(false);
      const [disabled, setDisabled] = useState(false);
      const [promo_jour, setPromoJr] = useState(false);
      const[color, setColor]=useState("#ffffff");
    
    
      useEffect(() => {
        ProductService.allProductInPromoJr()
        
          .then((res) => {
            console.log(res.data.results);
            setpromoDuJR(res.data.results[0]);
          })
          .catch(function (error) {
            if (!error.response) {
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
            } else if (error.response.status === 403) {
              setLoading(false);
              error.response.data.error
                ? NotificationManager.warning(error.response.data.error, "", 2000)
                : NotificationManager.warning("Could not get products", "", 2000);
    
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            } else if (error.response.status === 401) {
              setLoading(false);
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
              setTimeout(() => {
                AuthService.logout();
              }, 500);
            }
          })
      }, []);

      const renderProductImage = (row) => {
        return (
          <img
            src={
              window.IMAGE_API_URL + "products/" + row.image.split(";")[0]
            }
            alt={row.image.split(";")[0]}
            width={150}
          />
        );
      };
    
      const renderActions = (row) => {
        return (
          <div className="d-flex align-items-center justify-content-around w-100">
             <Link
              to={"/DetailsProduit/" + row.id}
              className="btn btn-icon btn-outline-primary"
            >
              <i className="feather icon-eye" />
            </Link>
            <button
              type="button"
              className="btn btn-icon btn-outline-danger"
              id={row.promojr}
              onClick={deletePromo}
            >
              <i
                className="far fa-trash-alt"
                id={row.promojr}
              ></i>
            </button>
          </div>
        )
      }

      const [columns, setColumns] = useState([
        
        {
          selector: "image",
          name: "Image",
          width: "250px",
          sortable: false,
          disableClickEventBubbling: true,
          cell: (row) => renderProductImage(row),
        },
        {
          selector: "name",
          name: "Titre",
          width: "auto",
        },
        {
          selector: "action",
          name: "Action",
          // type: 'number',
          width: "250px",
    
          sortable: false,
          cell: (row) => renderActions(row),
        },
      ]);
    
      const deletePromo= (event) => {
        const token = sessionStorage.getItem("token");
        const promoDuJour = event.target.id;
        console.log(promoDuJour);
        swalWithBootstrapButtons
          .fire({
            title: "Etes-vous sûr?",
            text: "Vous ne pourrez pas revenir sur ce produit !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimez-le",
            cancelButtonText: "Non, annulez !",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              ProductService.removeProductPromoJr(promoDuJour, token)
                .then((result) => {
                  setTimeout(() => {
                    ProductService.allProductInPromoJr().then(async (res) => {
                      setpromoDuJR(res.data.results[0]);
                      setLoading(false);
                    });
                  }, 300);
    
                  swalWithBootstrapButtons.fire(
                    "Supprimé !",
                    "Le produit a été supprimé.",
                    "success"
                  );
                })
                .catch(function (error) {
                  if (!error.response) {
                    swalWithBootstrapButtons.fire(
                      "Annulé",
                      "Votre session est expiree, veuillez vous reconnectez",
                      "error"
                    );
                  } else if (error.response.status === 403) {
                    setLoading(false);
                    error.response.data.error
                      ? swalWithBootstrapButtons.fire(
                          "Annulé",
                          error.response.data.error,
                          "error"
                        )
                      : swalWithBootstrapButtons.fire(
                          "Annulé",
                          "Impossible de supprimer ce produit",
                          "error"
                        );
    
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                  } else if (error.response.status === 401) {
                    setLoading(false);
                    swalWithBootstrapButtons.fire(
                      "Annulé",
                      "Votre session est expiree, veuillez vous reconnectez",
                      "error"
                    );
                    setTimeout(() => {
                      AuthService.logout();
                    }, 500);
                  }
                });
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                "Annulé",
                "Le produit n'a pas été supprimé.",
                "error"
              );
            }
          });
      };


      const tableData = {
        columns,
        data: promoDuJR,
      };

      return (
        <>
          <WichPage pageTitle="Produits en solde" pageLocation="promo du jour" />
          <div className="row">
            {/* [ basic-table ] start */}
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h5>Promo Du Jour </h5>
                  </div>
    
                <div className="card-body">
                <DataTableExtensions {...tableData}>
                <DataTable
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>

                {/* <div style={{ height: "70vh", width: '100%' }}>
                    <DataGrid
                      // XGrid
                      rows={promoDuJR}
                      columns={columns}
                      pageSize={5}
                      checkboxSelection
                      disableSelectionOnClick
                      stickyHeader
                      // autoHeight="true"
                      loading={loading}
                      rowHeight={100}
                      components={{
                        Toolbar: GridToolbar,
                      }}
    
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      );
}

export default Promodujour;
