import axios from "axios"
import authHeader from "./auth-header";
import AuthService from "./auth.service";
const user = AuthService.getCurrentUser()
const token = user.token

const allClients = () => {
    return axios.get(window.API_URL + "/admin/users", authHeader())
}

const delteteClient = (id) => {
    var URL = ''
    if (user.role === "Admin") {
        URL = `${window.API_URL}/admin/user/${id}`
    }
    // else if (user.role === "Moderateur") {
    //      URL = `${window.API_URL}/moderateur/user/${id}`
    // }
    // else if (user.role === "Moderateur_Commercial") {
    //      URL = `${window.API_URL}/modcommercial/user/${id}`
    // }
    return axios.delete(URL,
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });

}

const getClientById = (id) => {
    var URL = ''
    if(user.role === "Admin") {
        URL = `${window.API_URL}/admin/user/${id}`
    }
    return axios.get(URL, authHeader())
}

const updateClient = (data) => {
    
    var URL = ''
    if(user.role === "Admin") {
        URL = `${window.API_URL}/admin/user_infos`
    }
    return axios.patch(URL, data,authHeader())
    
}
const updateClientAvatar = (data) => {
    var URL = ''
    if(user.role === "Admin") {
        URL = `${window.API_URL}/admin/user_img`
    }
    return axios.patch(URL, data,authHeader())
}

const updateClientPassword = (data) =>{
    var URL = ''
    if(user.role === "Admin")
    {
        URL = window.API_URL + '/admin/user_pwd/' + data.id
    }
    return axios.patch(URL, data, authHeader())

}


const ClinetService = {
    allClients,
    delteteClient,
    getClientById,
    updateClient,
    updateClientAvatar,
    updateClientPassword
}
export default ClinetService;