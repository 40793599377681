import axios from 'axios';
import authHeader from './auth-header';

const role = sessionStorage.getItem('role');

const getUsersByYear = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/year_users`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/year_users`
     } else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}

const getViews = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/views`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/views`
     } else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}

const getUsersByMonth = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/month_users`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/month_users`
     } else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}
const getProductByYear = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/year_selledproduct`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/year_selledproduct`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/year_selledproduct`
     } else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}
const getProductByMonth = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/month_selledproduct`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/month_selledproduct`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/modcommercial/month_selledproduct`
     } else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}
const getUsersNumber = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/users_nbr`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/users_nbr`
     } else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}
const getSubscribersNumber = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/subscribers_nbr`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/subscribers_nbr`
     }
     else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}
const getBlogsNumber = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/blogs_nbr`
     }
     else if (role === "Redacteur") {
          URL = `${window.API_URL}/redacteur/blogs_nbr`
     }
     else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}
const getProductsNumber = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/product_nbr`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/product_nbr`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/moderateur/product_nbr`
     }
     else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}
const getLastMessages = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/last_contact_msg`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/last_contact_msg`
     }
     else {
          URL = `${window.API_URL}`
     }

     return axios.get(URL, authHeader())
}
const getLastSubscrivers = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/last_subscribers`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/last_subscribers`
     }
     else {
          URL = `${window.API_URL}`
     }

     return axios.get(URL, authHeader())
}

const getReclamationNumbByType = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/reclamations_bytype`
     }
     else if (role === "Moderateur") {
          URL = `${window.API_URL}/moderateur/reclamations_bytype`
     }
     else {
          URL = `${window.API_URL}`
     }

     return axios.get(URL, authHeader())
}

const getDemandeDevisByValide = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/demande_validee`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/demande_validee`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/moderateur/demande_validee`
     }
     else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}
const getDemandeDevisNVByValide = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/demande_nnvalidee`
     }
     else if (role === "Commercial") {
          URL = `${window.API_URL}/commercial/demande_nnvalidee`
     }
     else if (role === "Moderateur_Commercial") {
          URL = `${window.API_URL}/moderateur/demande_nnvalidee`
     } else {
          URL = `${window.API_URL}`
     }
     return axios.get(URL, authHeader())
}

const getAllAdmins = () => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/admins`
     }
     return axios.get(URL, authHeader())
}

const getAdminActivity = (data) => {
     var URL = ''
     if (role === "Admin") {
          URL = `${window.API_URL}/admin/admin_activity`
     } else {
          URL = `${window.API_URL}`
     }
     return axios.post(URL, data, authHeader())
}
const getHotProducts = () => {
     var URL = `${window.API_URL}/hot_iteams`
     return axios.get(URL, authHeader())
}

const SlidersService = {
     getUsersByYear,
     getUsersByMonth,
     getProductByYear,
     getProductByMonth,
     getSubscribersNumber,
     getUsersNumber,
     getBlogsNumber,
     getProductsNumber,
     getLastMessages,
     getLastSubscrivers,
     getReclamationNumbByType,
     getDemandeDevisByValide,
     getDemandeDevisNVByValide,
     getAllAdmins,
     getAdminActivity,
     getHotProducts,
     getViews
}

export default SlidersService;