import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import WichPage from "../../inc/helpfulComponents/WichPage";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

import Swal from "sweetalert2";
import BlogService from "../../../services/blog.service";
import Moment from "react-moment";
// import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";

const CategoryBlogs = () => {
  const [catBlog, setCatBlog] = useState(null);
  const [catBlogName, setCatBlogName] = useState("");
  const [categoryBlogs, setCategoryBlogs] = useState([]);
  const [catToUpdate, setCatToUpdate] = useState("");

  const [loading, setLoading] = useState(true);

  const renderCatBlogActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <button
          data-toggle="modal"
          data-target="#modal-edit"
          className="btn btn-icon btn-outline-primary mx-3 my-2"
          onClick={() => {
            setCatToUpdate(row);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>

        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          id={row.id}
          onClick={(e) => deleteCategory(row.id)}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
    );
  };

  // const renderDevistDate = (row) => {
  //   return (
  //     <div className="d-flex w-100 align-items-center justify-content-start">
  //       <Moment format="D MMM YYYY" withTitle>
  //         {row.created_at}
  //       </Moment>
  //       <div className="text-muted mx-3">
  //         ( <Moment fromNow>{row.created_at}</Moment> )
  //       </div>
  //     </div>
  //   );
  // };

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    { selector: "id", name: "N° Catégorie" },

    {
      selector: "name",
      name: "NOM",
      width: "300px",
      wrap: true,
    },
    {
      selector: "created_at",
      name: "Date d'ajout",
      cell: (d) => <div className="d-flex w-100 align-items-center justify-content-start">
                  <Moment format="DD/MM/YYYY" className="text-center">
                      {d.created_at}
                  </Moment>
                  <div className="text-muted mx-3">
                      ( <Moment fromNow>{d.created_at}</Moment> )
                  </div>
              </div>
    },
    {
      selector: "nbrblg",
      name: "Nombre de blog",
      width: 300,
    },

    {
      name: "Action",

      cell: (row) => renderCatBlogActions(row),
    },
  ]);
  const tableData = {
    columns,
    data: categoryBlogs,
  };
  const updateCatgBlog = (e) => {
    BlogService.updateCategoryBlog({ id: catToUpdate.id, name: catBlog })
      .then(async () => {
        await BlogService.allCategoryBlogs().then((result) => {
          setCategoryBlogs(result.data.results);
        });
        NotificationManager.success("la categorie est ajouté");
      })
      .catch((err) => NotificationManager.error(`${err}`));
  };

  const addCatBlog = () => {
    if (catBlogName) {
      BlogService.addCategoryBlog({ name: catBlogName })
        .then(async () => {
          await BlogService.allCategoryBlogs().then((result) => {
            setCategoryBlogs(result.data.results);
          });
          NotificationManager.success("la categorie est ajouté");
        })
        .catch((err) => NotificationManager.error(`${err}`));
    } else {
      NotificationManager.error("entez le nom de categorie");
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success ml-2",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  // load CategoryBlogs
  useEffect(() => {
    BlogService.allCategoryBlogs()
      .then((res) => {
        setCategoryBlogs(res.data.results);
        setLoading(false);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        NotificationManager.error(resMessage);
      });
  }, []);

  // DELETE CATEGORY

  const deleteCategory = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "Êtes-vous sûr?",
        text:
          " la suppression de la catégorie entraînera la suppression de tous les blogs associés",
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Oui je comprends!",
        cancelButtonText: "Annuler!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          BlogService.deleteCatgBlog(id)
            .then(async () => {
              swalWithBootstrapButtons.fire(
                "Supprimé!",
                "La catégorie a été supprimée.",
                "success"
              );
              await BlogService.allCategoryBlogs().then((result) => {
                setCategoryBlogs(result.data.results);
              });
            })
            .catch((err) =>
              swalWithBootstrapButtons.fire(
                "Annuler",
                "Problème de connection",
                "error"
              )
            );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Annuler", "", "error");
        }
      });
  };

  return (
    <div>
      <WichPage pageTitle="CategoryBlogs" pageLocation="Blogs/categoryBlogs" />
      <div className="row">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card shadow-none">
            <div className="card-header">
              <h5>Categories-Blogs</h5>
              <div className="card-header-right">
                <h5>Ajouter une categorie</h5>
                <button
                  className="btn btn-icon btn-outline-primary"
                  data-toggle="modal"
                  data-target="#modal-add"
                >
                  <i className="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
              {/* <div className="table-responsive dt-responsive">
                <table
                  id="setting-default"
                  className="table table-striped table-bordered nowrap"
                >
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Date d'ajout</th>
                      <th>Nombre de blog</th>
                      <th width="10%">Actions </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryBlogs.map((catB, index) => (
                      <tr key={index}>
                        <td>{catB.name}</td>
                        <td>{moment(catB.created_at).format("YYYY-MM-DD")}</td>
                        <td>{moment(catB.updated_at).format("YYYY-MM-DD")}</td>
                        <td>{catB.nbrblg}</td>
                        <td className="table-action">
                          <div className="d-flex align-items-center justify-content-around">
                            <button
                              data-toggle="modal"
                              data-target="#modal-edit"
                              className="btn btn-icon btn-outline-primary"
                              onClick={() => {
                                setCatToUpdate(catB);
                              }}
                            >
                              <i className="fas fa-edit"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-icon btn-outline-danger"
                              id={catB.id}
                              onClick={(e) => deleteCategory(catB.id)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* MODALS */}
      {/* <ModalAddCatBlog /> */}
      <div
        className="modal fade"
        id="modal-add"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter une categorie blog</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      value={catBlogName}
                      onChange={(e) => setCatBlogName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary save_catg"
                    onClick={addCatBlog}
                  >
                    <i className="feather icon-save save_catg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ModalEditCatBlog categoryBlog={catToUpdate} /> */}
      <div
        className="modal fade"
        id="modal-edit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modifier le nom de la Catégorie</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="m-3">
                    <label>Nom actuel : {catToUpdate.name}</label>
                  </div>
                  <div className="form-group">
                    <label className="floating-label" htmlFor="Name">
                      le nouveau nom
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      onChange={(e) => setCatBlog(e.target.value)}
                      // value={catBlog}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 text-right mt-5">
                <button
                  className="btn btn-icon btn-outline-primary"
                  onClick={updateCatgBlog}
                >
                  <i className="feather icon-save" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBlogs;
