import axios from "axios";

// const register = (username, email, password) => {
//   return axios.post(API_URL + "signup", {
//     username,
//     email,
//     password,
//   });
// };

const login = (email, password) => {
  return axios
    .post(window.API_URL + "/admin/adminlogin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data) {
        console.log(response.data)
        sessionStorage.setItem("token", response.data.data.token);
        sessionStorage.setItem("user", `${response.data.data.user.nom} ${response.data.data.user.prenom}`);
        sessionStorage.setItem("avatar", response.data.data.user.avatar);
        sessionStorage.setItem("id_user", response.data.data.user.id);
        sessionStorage.setItem("role", response.data.data.user.role);
      }
      return response.data;
    });
};

const logout = () => {
  sessionStorage.clear()
  window.location.reload();
}
const getCurrentUser = () => {
  // console.log(sessionStorage.getItem("user"));
  const user ={
    user : sessionStorage.getItem("user"),
    avatar : sessionStorage.getItem("avatar"),
    role: sessionStorage.getItem("role"),
    id: sessionStorage.getItem("id_user"),
    token: sessionStorage.getItem("token")
  }
  return user;
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
};
export default AuthService;
