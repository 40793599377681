const script = document.createElement("script");
const script1 = document.createElement("script");
const script3 = document.createElement("script");

script.src = "./assets/js/plugins/jquery.dataTables.min.js";
script.type = "text/javascript";
script.async = true;

script3.src = "./assets/dt_setting.js";
script3.type = "text/javascript";
script3.async = true;

script1.src = "./assets/js/plugins/dataTables.bootstrap4.min.js";
script1.type = "text/javascript";
script1.async = true;

const mountScripts = () => {
  document.body.appendChild(script);
  document.body.appendChild(script1);
  document.body.appendChild(script3);

};

const unMountScripts = () => {
  document.body.removeChild(script);
  document.body.removeChild(script1);
  document.body.removeChild(script3);
};
const dataTableScripts = {
  mountScripts,
  unMountScripts
};
export default dataTableScripts;
