/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState, useRef } from "react";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import AdminService from "../../../services/admin.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
import dataTableScripts from "../../inc/scripts/dataTableScripts";
import AddAdmin from "./AddAdmin";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import TextField from "@material-ui/core/TextField";
import AuthService from "../../../services/auth.service";
import { ClipLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [id, setId] = useState(null);
  const [adminToUpdate, setadminToUpdate] = useState({});
  const [idAdminToDelete, setIdAdminToDelete] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [description, setDescription] = useState("");
  const [image, setimage] = useState();
  const [newRole, setNewRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#00000");
  const [formdata, setformdata] = useState();
  // for add admin
  const form = useRef(null);
  const formUpdate = useRef(null);

  const [avatar, setAvatar] = useState("");

  const handelSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(form.current);
    // console.log(avatar);
    data.append("avatar", avatar);
    // console.log(data);
    AdminService.addAdmin(data)
      .then((res) => {
        NotificationManager.success("l'admin est ajouté");
        AdminService.allAdmins()
          .then((res) => {
            // console.log(res.data);
            setAdmins(res.data.results);
          })
          .catch(function (error) {
            if (!error.response) {
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
            } else if (error.response.status === 403) {
              setLoading(false);
              error.response.data.error
                ? NotificationManager.warning(
                    error.response.data.error,
                    "",
                    2000
                  )
                : NotificationManager.warning(
                    "Nos n'avons pas pu charge la liste des admins",
                    "",
                    2000
                  );
              // console.log(error.response.headers);
            } else if (error.response.status === 401) {
              setLoading(false);
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
              setTimeout(() => {
                AuthService.logout();
              }, 500);
            }
          });
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Nous n'avons pas pu ajouter l'admin",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success ml-2",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const renderActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <button
          data-toggle="modal"
          data-target="#modal-edit"
          className="btn btn-icon btn-outline-primary"
          onClick={() => {
            setadminToUpdate(row);
            setId(row.id);
          }}
        >
          <i className="feather icon-edit" />
        </button>
        <button
          id={row.id}
          onClick={deleteAdmin}
          className="btn btn-icon btn-outline-danger"
        >
          <i className="feather icon-trash-2" />
        </button>
      </div>
    );
  };

  const rendeImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + `avatar/` + row.avatar}
        alt={row.image}
        width={100}
      />
    );
  };

  // function getExtension(filename) {
  //   try {
  //     var parts = filename.split('.');
  //     return parts[parts.length - 1];
  //   } catch (error) {
  //     return ''
  //   }
  // }

  // function isImage(filename) {
  //   var ext = getExtension(filename);
  //   switch (ext.toLowerCase()) {
  //     case 'jpg':
  //       return true
  //     case 'jpeg':
  //       return true
  //     case 'png':
  //       return true
  //     default:
  //       return false
  //   }

  // }

  const [columns, setColumns] = useState([
    {
      selector: "avatar",
      name: "IMAGE",
      width: "140px",
      sortable: false,
      disableClickEventBubbling: true,
      cell: (row) => rendeImage(row),
    },
    {
      selector: "nom",
      name: "Nom",
      width: "170px",
      wrap: true,
    },
    {
      selector: "prenom",
      name: "Prenom",
      width: "170px",
      wrap: true,
    },
    {
      selector: "email",
      name: "Email",
      width: "200px",
      wrap: true,
    },
    {
      selector: "description",
      name: "Desription",
      width: "auto",
      wrap: true,
    },
    {
      selector: "role",
      name: "Privilèges",
      width: "150px",
    },
    {
      selector: "action",
      name: "Action",
      // type: 'number',
      width: "200px",

      sortable: false,
      cell: (row) => renderActions(row),
    },
  ]);
  const tableData = {
    columns,
    data: admins,
  };

  const deleteAdmin = (event) => {
    const adminId = event.target.id;
    if (adminId == sessionStorage.getItem("id_user")) {
      swalWithBootstrapButtons.fire(
        "Annuler",
        "Impossible de supprimer cet administrateur",
        "error"
      );
      return;
    } else {
      swalWithBootstrapButtons
        .fire({
          title: "Êtes-vous sûr?",
          text: " voulez vous vraiment supprimer cet admin",
          icon: "warning",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Oui je comprends!",
          cancelButtonText: "Annuler!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            AdminService.deleteAdmin(adminId)
              .then(async () => {
                swalWithBootstrapButtons.fire(
                  "Supprimé!",
                  "admin supprimé",
                  "success"
                );
                await AdminService.allAdmins().then((result) => {
                  setAdmins(result.data.results);
                });
              })
              .catch((err) =>
                swalWithBootstrapButtons.fire(
                  "Annuler",
                  "Problème de connection",
                  "error"
                )
              );
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire("Annuler", "", "error");
          }
        });
    }
  };

  const fdToJson = (fd) => {
    let obj = {};
    for (let key of fd.keys()) {
      obj[key] = fd.get(key);
    }
    return obj;
  };
  // Update Admin
  const handelUpdate = (e) => {
    e.preventDefault();
    const data = fdToJson(new FormData(formUpdate.current));
    console.log(data);
    AdminService.updatePersonalInfo(data)
      .then((res) => {
        loadAdmins();
        NotificationManager.success(
          "Le profile est mise a jour avec success",
          "",
          2000
        );
      })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            2000
          );
        } else if (error.response.status === 403) {
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
                "Nous n'avons pas pu mettre a jour les donnees d'admin",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
    AdminService.updateAdminRole(data)
      .then((res) => {
        loadAdmins();
        NotificationManager.success(
          "Le role d'admin est mise a jour avec success",
          "",
          2000
        );
      })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            2000
          );
        } else if (error.response.status === 403) {
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
                "Nous n'avons pas pu mettre a jour les donnees d'admin",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };
  let changePwSwal = {
    title: "Modifier le mot de passe?",
    focusConfirm: false,
    html: `
    <input class="swal2-input" id="password1" type="password" placeholder="Enter le nouveau mot de passe..." /><br />
    <input class="swal2-input" id="confirmPassword" type="password" placeholder="Confirmer votre nouveau mot de passe..." /><br />
  `,
    type: "warning",
    showCancelButton: true,
    cancelButtonColor: "grey",
    confirmButtonText: "Modifier",
    allowOutsideClick: false,
    preConfirm: () => ({
      password: document.getElementById("password1").value,
      passwordConfirm: document.getElementById("confirmPassword").value,
      id: id,
    }),
  };

  const handleResetPassword = () => {
    const resetPw = async () => {
      const swalval = await MySwal.fire(changePwSwal);
      let v = (swalval && swalval.value) || swalval.dismiss;
      if ((v && v.id && v.password && v.passwordConfirm) || v === "cancel") {
        if (v.password !== v.passwordConfirm) {
          await MySwal.fire({
            type: "error",
            title: "Les mots de passes ne sont pas compatible!",
          });
          resetPw();
        } else if (v !== "cancel") {
          setformdata(swalval);
          console.log(swalval.value);
          AdminService.updatePasswordOfAdmin(swalval.value)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Succès!",
                "Le mot de passe a été modifié avec succès.",
                "success"
              );
            })
            .catch(function (error) {
              // console.log(error)
              if (!error.response) {
                NotificationManager.error(
                  "Votre session est expiree, veuillez vous reconnectez",
                  "",
                  4000
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error || error.response.data.message
                  ? NotificationManager.warning(
                      error.response.data.error
                        ? error.response.data.error
                        : error.response.data.message,
                      "",
                      2000
                    )
                  : NotificationManager.warning(
                      "Nous n'avons pas pu modifier le mot de passe",
                      "",
                      2000
                    );

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.response.status === 401) {
                setLoading(false);
                NotificationManager.error(
                  "Votre session est expiree, veuillez vous reconnectez",
                  "",
                  4000
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        }
      } else {
        await MySwal.fire({
          type: "error",
          title: "Tous les champs sont necessaire!!",
        });
        resetPw();
      }
    };

    resetPw();
  };

  const loadAdmins = () => {
    AdminService.allAdmins()
      .then((res) => {
        console.log(res.data);
        setAdmins(res.data.results);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
                "Nous n'avons pas pu charger la liste des admins",
                "",
                2000
              );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  useEffect(() => {
    loadAdmins();
  }, []);

  return (
    <div>
      <WichPage pageTitle="Administrateurs" pageLocation="administrateurs" />

      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h5>Administrateurs</h5>
            <div className="card-header-right">
              <h5>Ajouter un administrateur</h5>
              <button
                className="btn btn-icon btn-outline-primary"
                data-toggle="modal"
                data-target="#modal-report"
              >
                <i className="fas fa-plus-circle"></i>
              </button>
            </div>
          </div>

          <div className="card-body">
            <div className="row align-items-center m-l-0"></div>
            {!loading ? (
              <DataTableExtensions {...tableData}>
                <DataTable
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
            ) : (
              <ClipLoader color={"#000000"} loading={loading} />
            )}
          </div>
        </div>
      </div>
      {/* Update admin */}
      <div
        className="modal fade"
        id="modal-edit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Modifier les info de {adminToUpdate.nom}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className="row">
                <div className="col-12">
                  <div className="m-3">
                    <label>
                      {adminToUpdate.nom} est un : {adminToUpdate.role}
                    </label>
                  </div>
                  <div className="form-group">
                    <label htmlFor="role">Role</label>
                    <select
                      className="form-control"
                      name="role"
                      value={newRole}
                      id="role"
                      onChange={(e) => setNewRole(e.target.value)}
                    >
                      <option value="Admin">Admin</option>
                      <option value="Redacteur">Redacteur</option>
                      <option value="Moderateur">Moderateur</option>
                      <option value="Commercial">Commercial</option>
                      <option value="ModCommercial">ModCommercial</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <form onSubmit={handelUpdate} ref={formUpdate}>
                <input
                  name="id"
                  type="type"
                  className="d-none"
                  defaultValue={adminToUpdate.id}
                />
                {/* <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Avatar
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="file"
                      className="form-control"
                      defaultValue={adminToUpdate.avatar}
                      name="avatar"
                    />
                  </div>
                </div> */}
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Email
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={adminToUpdate.email}
                      name="email"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Nom
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={adminToUpdate.nom}
                      name="nom"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Prenom
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={adminToUpdate.prenom}
                      name="prenom"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Privilèges
                  </label>
                  <div className="col-sm-9">
                    <select className="form-control" name="role" id="Blood">
                      <option value />
                      <option
                        value="Admin"
                        selected={adminToUpdate.role === "Admin" ? true : false}
                      >
                        Admin
                      </option>
                      <option
                        value="Redacteur"
                        selected={
                          adminToUpdate.role === "Redacteur" ? true : false
                        }
                      >
                        Redacteur
                      </option>
                      <option
                        value="Moderateur"
                        selected={
                          adminToUpdate.role === "Moderateur" ? true : false
                        }
                      >
                        Moderateur
                      </option>
                      <option
                        value="Commercial"
                        selected={
                          adminToUpdate.role === "Commercial" ? true : false
                        }
                      >
                        Commercial
                      </option>
                      <option
                        value="ModCommercial"
                        selected={
                          adminToUpdate.role === "ModCommercial" ? true : false
                        }
                      >
                        ModCommercial
                      </option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Description
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      name="description"
                      className="form-control"
                      defaultValue={adminToUpdate.description}
                    />
                  </div>
                </div>
                <h4>Social media</h4>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Facebook
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="url"
                      className="form-control"
                      defaultValue={adminToUpdate.fb}
                      name="fb"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Instagram
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="url"
                      className="form-control"
                      defaultValue={adminToUpdate.insta}
                      name="insta"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Twitter
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="url"
                      className="form-control"
                      defaultValue={adminToUpdate.twitter}
                      name="twitter"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    Linked In
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="url"
                      className="form-control"
                      defaultValue={adminToUpdate.linkedin}
                      name="linkedin"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label font-weight-bolder">
                    youtube
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="url"
                      className="form-control"
                      defaultValue={adminToUpdate.youtube}
                      name="youtube"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-9 text-right">
                    <div className="col-sm-12 text-right m-6">
                      <div className="d-flex align-items-center justify-content-end">
                        <button
                          type="button"
                          className="btn btn-icon btn-outline-warning mx-3"
                          data-dismiss="modal"
                          onClick={handleResetPassword}
                        >
                          <i class="fas fa-lock"></i>
                        </button>
                        <button
                          type="submit"
                          className="btn btn-icon btn-outline-primary mx-3"
                        >
                          <i className="feather icon-save" />
                        </button>
                      </div>
                    </div>
                    {/* <button
                      type="submit"
                      className="btn btn-icon btn-outline-primary"
                    >
                      <i className="feather icon-save" />
                    </button> */}
                  </div>
                  <label className="col-sm-3 col-form-label" />
                </div>
              </form>

              {/* <div className="col-sm-12 text-right mt-5">
                <button
                  className="btn btn-icon btn-outline-primary"
                  onClick={updateAdmin}
                >
                  <i className="feather icon-save" />
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* Add admin modal */}
      <div
        className="modal fade"
        id="modal-report"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter un administrateur </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form ref={form} onSubmit={handelSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <TextField
                      id="fName"
                      label="Nom"
                      className="w-100 mb-2"
                      name="nom"
                    />

                    {/* <div className="form-group">
                      <label className="floating-label" htmlFor="fName">
                        Nom
                      </label>
                      <input
                        type="text"
                        name="nom"
                        className="form-control"
                        id="fName"
                      />
                    </div> */}
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      id="sName"
                      label="Prenom"
                      className="w-100 mb-2"
                      name="prenom"
                    />

                    {/* <div className="form-group">
                      <label className="floating-label" htmlFor="sName">
                        Prenom
                      </label>
                      <input
                        type="text"
                        name="prenom"
                        className="form-control"
                        id="sName"
                      />
                    </div> */}
                  </div>
                  <div className="col-sm-12">
                    <TextField
                      id="Email"
                      label="Email"
                      className="w-100 mb-2"
                      name="email"
                      type="email"
                    />
                  </div>

                  <div className="col-sm-12">
                    <TextField
                      id="desc"
                      label="Description"
                      className="w-100 mb-2"
                      name="description"
                      multiline
                      maxRows={4}
                    />
                  </div>
                  <div className="col-sm-12 mt-2">
                    <div className="form-group multi-preview text-center">
                      {avatar && (
                        <img
                          src={URL.createObjectURL(avatar)}
                          alt={avatar}
                          width="150"
                        />
                      )}
                    </div>
                    <div className="form-group fill">
                      <label className="" htmlFor="Icon">
                        Image du Profil
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="Icon"
                        onChange={(e) => {
                          setAvatar(e.target.files[0] ? e.target.files[0] : "");
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="" htmlFor="Blood">
                        Role
                      </label>
                      <select className="form-control" name="role" id="Blood">
                        <option value />
                        <option value="Admin">Admin</option>
                        <option value="Redacteur">Redacteur</option>
                        <option value="Moderateur">Moderateur</option>
                        <option value="Commercial">Commercial</option>
                        <option value="ModCommercial">ModCommercial</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group fill">
                      <label className="floating-label" htmlFor="password">
                        Mot de passe
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group fill">
                      <label
                        className="floating-label"
                        htmlFor="passwordConfirm"
                      >
                        Confirmer le mot de passe
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="passwordConfirm"
                        name="passwordConfirm"
                      />
                    </div>
                  </div>
                  {!loading ? (
                    <div className="col-sm-12 text-right mt-5">
                      <button
                        type="submit"
                        className="btn btn-icon btn-outline-primary"
                      >
                        <i className="feather icon-save" />
                      </button>
                      <button
                        type="reset"
                        className="ml-2 btn btn-icon btn-outline-secondary"
                      >
                        <i className="fas fa-times-circle" />
                      </button>
                    </div>
                  ) : (
                    <ClipLoader color={color} loading={loading} />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admins;
