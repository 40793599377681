import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import QuotationService from "../../../../services/devis.service";
import WichPage from "../../../inc/helpfulComponents/WichPage";
import Swal from "sweetalert2";
import AuthService from "../../../../services/auth.service";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import NotificationManager from "react-notifications/lib/NotificationManager";
import "react-notifications/lib/notifications.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";

const ValidatedQuotaion = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [devis, setDevis] = useState([]);
  const [loading, setLoading] = useState(true);

  const renderDevisActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <Link
          to={`/devisDetails/${row.id}`}
          className="btn btn-icon btn-outline-success"
        >
          <i class="fas fa-check" id={row.id}></i>
        </Link>
        <button
          type="button"
          className="btn btn-icon btn-outline-danger mx-3 my-2"
          id={row.id}
          onClick={deleteDemandeDevis}
        >
          <i className="far fa-trash-alt" id={row.id} />
        </button>
      </div>
    );
  };
  const renderDevistDate = (row) => {
    return (
      <div className="d-flex w-100 align-items-center justify-content-center">
        <Moment format="DD/MM/YYYY" className="text-center">
          {row.date_commande}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{row.date_commande}</Moment> )
        </div>
      </div>
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "N° devis" , width: "auto"},
    {
      selector: "username",
      name: "Nom du client",
      width: "300px",
    },
    {
      selector: "adresse",
      name: "Adresse",
      width: "280px",
      wrap: true,
    },
    {
      selector: "date_commande",
      name: "Date de commande",
      width: "280px",

      cell: (row) => renderDevistDate(row),
    },
    {
      selector: "tarif",
      name: "Somme",
      width: "auto"
    },

    {
      name: "Action",
      width: "150px",
      cell: (row) => renderDevisActions(row),
    },
  ]);

  const tableData = {
    columns,
    data: devis,
  };
  const deleteDemandeDevis = (event) => {
    const devisId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "Vous ne pourrez pas revenir sur cette demande!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez-le!",
        cancelButtonText: "Non, annuler!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          QuotationService.deleteDevis(devisId).then((res) => {
            setTimeout(() => {
              QuotationService.nvDevis().then((res) => {
                setDevis(res.data.results);
              });
            }, 300);
            swalWithBootstrapButtons.fire(
              "Supprimé!",
              "La demande a été supprimée.",
              "success"
            );
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "La demande n'a pas été supprimée",
            "error"
          );
        }
      })
      .catch(function (error) {
        setLoading(false);
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "une erreur s'est produite",
                "",
                2000
              );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  useEffect(() => {
    QuotationService.vDevis()
      .then((res) => {
        setDevis(res.data.results[0]);
        console.log(res.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "une erreur s'est produite",
                "",
                2000
              );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }, []);
  return (
    <>
      <WichPage
        pageTitle="Demandes de devis"
        pageLocation="devis/devisValides"
      />

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Tous les devis validés</h5>
            </div>
            <div className="card-body">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValidatedQuotaion;
