import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SubCategoryService from "../../../services/subcategory.service";
import useDataTableScripts from "../../inc/helpfulComponents/useDataTableScripts";
import WichPage from "../../inc/helpfulComponents/WichPage";
// import ModalAddSubCategory from "./modals/ModalAddSubCategory";
// import ModalEditSubCategoryName from "./modals/ModalEditSubCategoryName";

import TextField from "@material-ui/core/TextField";
// import MenuItem from '@material-ui/core/MenuItem';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Swal from "sweetalert2";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { ClipLoader } from "react-spinners";
const SubCategories = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [subcategories, setsubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategory, setsubCategory] = useState("");
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [idSubCategoryUpdate, setIdSubCategoryUpdate] = useState(0);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // const renderCategoryImage = (params) => {
  //   return (

  //     <img
  //       src={window.IMAGE_API_URL + 'category_product/' + row.image}
  //       alt={row.image}
  //       width={100}
  //     />

  //   )
  // }

  const renderActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <button
          data-toggle="modal"
          data-target="#modal-edit"
          className="btn btn-icon btn-outline-primary my-2 mx3"
          onClick={() => {
            setsubCategory(row.name);
            setIdSubCategoryUpdate(row.id);
            setCategory(row.category);
            setName(row.name);
          }}
        >
          <i className="fas fa-edit" id={row.id}></i>
        </button>

        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          id={row.id}
          onClick={deleteSubCategory}
        >
          <i className="far fa-trash-alt" id={row.id}></i>
        </button>
      </div>
    );
  };

  const [columns, setColumns] = useState([
    { selector: "id", name: "id" },
    {
      selector: "name",
      name: "Nom de la sous-catégorie",
      width: "300px",
    },
    {
      selector: "category",
      name: "NOM DE LA CATEGORIE",
      width: "300px",
    },
    {
      selector: "nbr_product",
      name: "NOMBRE DE PRODUITS",
      // hide: false
    },
    {
      name: "Action",
      cell: (row) => renderActions(row),
    },
  ]);
  const tableData = {
    columns,
    data: subcategories,
  };

  useEffect(() => {
    SubCategoryService.allSubCategories().then((res) => {
      setsubcategories(res.data.results);
      console.log(res.data.results);
      setLoading(false);
    });
    SubCategoryService.allCategories().then((res) => {
      setCategories(res.data.results);
    });
  }, []);

  // ADD SUB CATEGORY
  const addSubCategory = () => {
    setName("");
    console.log(name);
    console.log(categoryId);
    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    if (name !== "" && categoryId !== 0) {
      SubCategoryService.addSubCategory(categoryId, name, userId, token)
        .then((res) => {
          // console.log(res)
          SubCategoryService.allSubCategories().then((res) => {
            // console.log(res)
            NotificationManager.success(
              "Sous-catégorie ajoutée avec succès",
              "",
              2000
            );
            setsubcategories(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });
    } else {
      if (name === "") {
        NotificationManager.warning("Le nom de la sous-catégorie est requis", "", 2000);
      }
      if (categoryId === "0") {
        NotificationManager.warning("La catégorie est obligatoire", "", 2000);
      }
    }
  };

  // Update SUB Category
  const updateSubCategory = () => {
    setName("");
    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    if (name !== "" && categoryId !== 0) {
      SubCategoryService.updateSubCategory(
        idSubCategoryUpdate,
        categoryId,
        name,
        token
      )
        .then((res) => {
          SubCategoryService.allSubCategories().then((res) => {
            NotificationManager.success(
              "Sous-catégorie mise à jour avec succès",
              "",
              2000
            );
            setsubcategories(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data.error, "", 2000);

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });
    } else {
      if (name === "") {
        NotificationManager.warning("Le nom de la sous-catégorie est requis", "", 2000);
      }
      if (categoryId === "0") {
        NotificationManager.warning("La catégorie est obligatoire", "", 2000);
      }
    }
  };

  // DELETE SUB CATEGORY

  const deleteSubCategory = (event) => {
    const userId = sessionStorage.getItem("id_user");
    const token = sessionStorage.getItem("token");
    const SubcategoryId = event.target.id;
    console.log(SubcategoryId);
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "You won't be able to revert this category!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez-le",
        cancelButtonText: "Non, annulez !",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          SubCategoryService.deleteSubCategory(
            SubcategoryId,
            userId,
            token
          ).then((res) => {
            // console.log(res)
            SubCategoryService.allSubCategories().then((res) => {
              // console.log(res)
              setsubcategories(res.data.results);
            });
          });

          swalWithBootstrapButtons.fire(
            "Supprimé !",
            "La sous-catégorie a été supprimée.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "La sous-catégorie n'a pas été supprimée",
            "error"
          );
        }
      });
  };

  return (
    <div>
      <NotificationContainer />
      <WichPage pageTitle="Sous Categories" pageLocation="souscategories" />
      <div className="row">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5>Sous-Catégories</h5>
              <div className="card-header-right">
                <h5 className="">Ajouter une sous-categorie</h5>
                <button
                  className="btn btn-icon btn-outline-primary"
                  data-toggle="modal"
                  data-target="#modal-add"
                >
                  <i className="fas fa-plus-circle"></i>
                </button>
                {/* <ModalAddSubCategory /> */}
              </div>
            </div>

            <div className="card-body">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* MODALS */}

      {/* START ADD SUBCATEGORY MODAL */}
      <div
        className="modal fade"
        id="modal-add"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter une sous-catégorie</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">

                  <div className="form-group">
                    <label className="" htmlFor="Category">
                      Catégorie
                    </label>
                    <select
                      className="form-control"
                      id="Category"
                      onChange={(e) => setCategoryId(e.target.value)}
                    >
                      <option value="0"></option>
                      {categories.map((cat, index) => (
                        <option value={cat.id}>{cat.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <TextField
                    id="Name"
                    label="Nom de la sous-catégorie"
                    className="w-100"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary"
                    onClick={addSubCategory}
                  >
                    <i className="feather icon-save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* END ADD SUBCATEGORY MODAL */}

      {/* START UPDATE SUBCATEGORY MODAL*/}
      <div
        className="modal fade"
        id="modal-edit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Modifier le nom de la sous-catégorie
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Nom actuel : {subCategory}</label>
                  </div>
                  <div className="form-group">
                    <label>Nom du catégorie : {category} </label>
                  </div>

                  <div className="form-group">
                    <label className="" htmlFor="Category">
                      Catégorie
                    </label>
                    <select
                      className="form-control"
                      id="Category"
                      onChange={(e) => setCategoryId(e.target.value)}
                    >
                      <option value="0"></option>
                      {categories.map((cat, index) => (
                        <option value={cat.id}>{cat.name}</option>
                      ))}
                    </select>
                  </div>
                  <TextField
                    id="Name"
                    label="Nom de la sous-catégorie"
                    className="w-100"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary"
                    onClick={updateSubCategory}
                  >
                    <i className="feather icon-save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* END UPDATE SUBCATEGORY MODAL*/}
    </div>
  );
};
export default SubCategories;
