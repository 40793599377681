import Moment from "react-moment";
import React, { useEffect, useState } from "react";
import SliderService from "../../../services/slider.service";
import useDataTableScripts from "../../inc/helpfulComponents/useDataTableScripts";
import WichPage from "../../inc/helpfulComponents/WichPage";
import dataTableScripts from "../../inc/scripts/dataTableScripts";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { ClipLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import AuthService from "../../../services/auth.service";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import axios from "axios";


const Sliders = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const [subslider, setSubSlider] = useState({});
  const [productId, setproductId] = useState('');
  const [subsliders, setSubSliders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [Bulle, setBulle] = useState("");
  const [position, setPosition] = useState("");
  const [image, setimage] = useState();
  const [idSubSlideUpdate, setIdSubSlideUpdate] = useState(0);

  const rendeImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + `Sliders/` + row.subimage}
        alt={row.subimage}
        width={150}
        className="my-3"
      />
    );
  };

  const [columns, setColumns] = useState([
    {
        name: "IMAGE REPRESENTATIVE",
        selector: "image",
        width: "200px",
        cell: (row) => rendeImage(row),
      },
    {
      name: "Bulle",
      selector: "Bulle",
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Position",
      selector: "position",
      sortable: true,
      width: "200px",
      wrap: true
    },
    {
      name: "Nom Du Produit",
      selector: "product",
      sortable: true,
      width: "auto"
    },
    {
      name: "Categorie Du Produit",
      selector: "catg",
      sortable: true,
      width: "auto",
      wrap: true,
    },
    {
      name: "Actions",
      selector: "",
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          className="d-flex align-items-center justify-content-arround w-100"
        >
          {/* <div className="d-flex align-items-center justify-content-arround w-100"> */}
          <button
            data-toggle="modal"
            data-target="#modal-edit"
            className="btn btn-icon btn-outline-primary"
            onClick={() => {
              setSubSlider(row);
              setIdSubSlideUpdate(row.id);
              setimage(row.image);
              setBulle(row.Bulle);
              setproductId(row.product_id);
              setPosition(row.position);
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
          <button
            type="button"
            className="btn btn-icon btn-outline-danger mx-3 my-4"
            id={row.id}
            onClick={deleteSubSlider}
          >
            <i className="far fa-trash-alt" id={row.id}></i>
          </button>
          {/* </div> */}
        </div>
      ),
    },
  ]);
  const tableData = {
    columns,
    data: subsliders,
  };

  useEffect(() => {
    setLoading(true);
    SliderService.getSubSliders()
      .then((res) => {
        setSubSliders(res.data.results);
        console.log(res.data.results);
        setLoading(false);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          setLoading(false);
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "Nous N'avons pas pus recharger la liste des sous-slide",
                "",
                2000
              );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          setLoading(false);
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }, []);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0] ? event.target.files[0] : null);
    setimage(event.target.files[0] ? event.target.files[0].name : "");
  };
  // Update SubSlider
  const updateSubSlider = () => {
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");
    
    if (Bulle !== "" && image !== "") {
      const formData = new FormData();
      formData.append("Bulle", Bulle);
      formData.append("position", position);
      formData.append("id", idSubSlideUpdate);
      formData.append("image", selectedFile);
      formData.append("product_id", productId);

      var URL = "";
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const data = formData;
      if (selectedFile && selectedFile !== {}) {
        if (role === "Admin") {
            URL = `${window.API_URL}/admin/sub_sliderImage`;
          } else if (role === "Commercial") {
            URL = `${window.API_URL}/commercial/sub_sliderImage`;
          } else if (role === "Moderateur_Commercial") {
            URL = `${window.API_URL}/modcommercial/sub_sliderImage`;
          }
        axios
          .patch(URL, data, config)
          .then((res) => {
            SliderService.getSubSliders().then((res) => {
              NotificationManager.success(
                "Le Sous-Slider est modifier avec success",
                "",
                2000
              );
              setSubSliders(res.data.results);
            });
          })
          .catch(function (error) {
            if (error.response.status === 403) {
              NotificationManager.warning(error.response.data.error, "", 2000);
            } else if (error.response.status === 401) {
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
            }
          });
      }
      const databulle = {
        id: idSubSlideUpdate,
        Bulle: Bulle,
        position: position,
        product_id : productId
      };
      if (role === "Admin") {
        URL = `${window.API_URL}/admin/sub_sliderBulle`;
      } else if (role === "Commercial") {
        URL = `${window.API_URL}/commercial/sub_sliderBulle`;
      } else if (role === "Moderateur_Commercial") {
        URL = `${window.API_URL}/modcommercial/sub_sliderBulle`;
      }
      try {
        axios
          .patch(
            URL,
            databulle,
            config
          )
          .then((res) => {
            SliderService.getSubSliders().then((res) => {
              NotificationManager.success(
                "Le sous slide a ete modifie avec success",
                "",
                2000
              );
              setSubSliders(res.data.results);
            });
          })
          .catch(function (error) {
            if (error.response.status === 403) {
              NotificationManager.warning(error.response.data?.error || error.response.data?.message || error.response.data?.erreur, "", 2000);
            } else if (error.response.status === 401) {
              NotificationManager.error(
                "Votre session est expiree, veuillez vous reconnectez",
                "",
                4000
              );
            }
          });
        }catch (error) {
          if (error.response.status === 403) {
            alert('kwowkow')
            NotificationManager.warning(error.response.data?.error || error.response.data?.message || error.response.data?.erreur, "", 2000);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        }
    }
  };

  const addSubSlider = () => {
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");
    if (Bulle !== "" && image !== "") {
      const formData = new FormData();
      formData.append("product_id", productId);
      formData.append("Bulle", Bulle);
      formData.append("position", position);
      formData.append("image", selectedFile);

      var URL = "";
      const config = { headers: { Authorization: `Bearer ${token}` } };
      
      if (role === "Admin") {
        URL = `${window.API_URL}/admin/add_sub_slider`;
      } else if (role === "Commercial") {
        URL = `${window.API_URL}/commercial/add_sub_slider`;
      } else if (role === "Moderateur_Commercial") {
        URL = `${window.API_URL}/modcommercial/add_sub_slider`;
      } else
        return  NotificationManager.error(
          "Permission denied only Admin/Moderateur/Commercial can do this action.",
          "",
          4000
        );
      axios
        .post(
          URL,
          formData,
          config
        )
        .then((res) => {
          SliderService.getSubSliders().then((res) => {
            NotificationManager.success(
              "Le sous slide a ete ajouter avec success",
              "",
              2000
            );
            setSubSliders(res.data.results);
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            NotificationManager.warning(error.response.data?.error || error.response.data?.message || error.response.data?.erreur, "", 2000);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          }
        });
    }
  }

  const deleteSubSlider = (event) => {
    const token = sessionStorage.getItem("token");
    const subsliderId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes Vous sur?",
        text: "Vous ne pourrez plus voir ce sous-slide!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez!",
        cancelButtonText: "Non, annullez!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          SliderService.deletSubSlider(subsliderId, token)
            .then((result) => {
              setTimeout(() => {
                SliderService.getSubSliders().then(async (res) => {
                    setSubSliders(res.data.results);
                  setLoading(false);
                });
              }, 300);

              swalWithBootstrapButtons.fire(
                "Supprime!",
                "Le sous-slider est supprime",
                "success"
              );
            })
            .catch(function (error) {
              if (!error.response) {
                swalWithBootstrapButtons.fire(
                  "Annuller",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? swalWithBootstrapButtons.fire(
                      "Annuller",
                      error.response.data.error,
                      "error"
                    )
                  : swalWithBootstrapButtons.fire(
                      "Annuller",
                      "Nous N'avons pas pus supprimmer le sous-slider",
                      "error"
                    );
              } else if (error.response.status === 401) {
                setLoading(false);
                swalWithBootstrapButtons.fire(
                  "Annuller",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "erreur"
                );
                setTimeout(() => {
                  AuthService.logout();
                }, 500);
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Annuller",
            "Votre sous-slider est encore la",
            "erreur"
          );
        }
      });
  };

  return (
    <>
      <WichPage pageTitele="Abonnés" pageLocation="abonnes" />
      <div className="row">
        {/* [ basic-table ] start */}
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5>Sub_Sliders</h5>
              <div className="card-header-right">
                <h5 className="">Ajouter un sub-slide</h5>
                <button
                  className="btn btn-icon btn-outline-primary"
                  data-toggle="modal"
                  data-target="#modal-add"
                >
                  <i className="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              {!loading ? (
                <div className="myaccount-content">
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      columns={columns}
                      data={subsliders}
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                      selectableRows={false}
                    />
                  </DataTableExtensions>
                </div>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>


  {/*  START ADD Slide MODAL */}
  <div
        className="modal fade"
        id="modal-add"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter un sub-slide</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div>
                    <b>Product Id</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      id="productId"
                      onChange={(e) => setproductId(e.target.value)}
                      name="Product ID"
                      value={productId}
                      placeholder="Product id"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Name</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Bulle"
                      onChange={(e) => setBulle(e.target.value)}
                      name="name"
                      value={Bulle}
                      placeholder="Bulle"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="" htmlFor="type">
                      <b>Type</b>
                    </label>
                    <select
                      className="form-control"
                      id="type"
                      onChange={(e) => setPosition(e.target.value)}
                      name="type"
                      value={position}
                    >
                      <option value=""></option>
                      <option value="Gauche"> Left </option>
                      <option value="Bas"> Bottom </option>
                      
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Image</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      id="SliderImage"
                      name="image"
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary save_catg"
                    onClick={addSubSlider}
                    // data-dismiss="modal"
                  >
                    <i className="feather icon-save save_catg" />
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  END ADD Slider MODAL */}

{/* MODALS */}
{/*  START UPDATE  SubSlider MODAL */}

      <div
        className="modal fade"
        id="modal-edit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modifier sub-slide</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div>
                    <b>Product Id</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      id="productId"
                      onChange={(e) => setproductId(e.target.value)}
                      name="Product ID"
                      value={productId}
                      placeholder="Product id"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Name</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Bulle"
                      onChange={(e) => setBulle(e.target.value)}
                      name="name"
                      value={Bulle}
                      placeholder="Bulle"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="" htmlFor="type">
                      <b>Type</b>
                    </label>
                    <select
                      className="form-control"
                      id="type"
                      onChange={(e) => setPosition(e.target.value)}
                      name="type"
                      value={position}
                    >
                      <option value=""></option>
                      <option value="Gauche"> Left </option>
                      <option value="Bas"> Bottom </option>
                      
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <b>Image</b>
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      id="SliderImage"
                      name="image"
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 text-right mt-5">
                  <button
                    className="btn btn-icon btn-outline-primary save_catg"
                    onClick={updateSubSlider}
                    // data-dismiss="modal"
                  >
                    <i className="feather icon-save save_catg" />
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  END UPDATE SubSlider MODAL */}
      </div>
    </>
  );
};

export default Sliders;
