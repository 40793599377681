// import { convertFromHTML, convertToHTML } from "draft-convert";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogService from "../../../services/blog.service";
import WichPage from "../../inc/helpfulComponents/WichPage";
import { NotificationManager } from "react-notifications";
import AuthService from "../../../services/auth.service";
import DOMPurify from "dompurify";
import { TextField } from "@material-ui/core";
import Swal from "sweetalert2";
import moment from "moment";

const BlogDetails = () => {
  const params = useParams();

  const [blog, setBlog] = useState({});
  useEffect(() => {
    BlogService.blogById(params.id)
      .then((res) => {
        // console.log(res.data);
        setBlog(res.data.result[0]);
        console.log(res.data.result[0]);
      })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
                "Impossible de charger les informations du blog",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }, []);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <>
      <WichPage pageTitle="Details du blog" pageLocation="blogs/blogDeatails" />

      <div className="row">
        <div className="col-12 col-md-8">
          <div className="card">
            <div className="card-header">
              <h5>Details du blog</h5>

              <div className="card-header-right">
                <h5 className="m-3">{blog.category_blog}</h5>
              </div>
            </div>
            <div className="row card-body shadow border-0">
              <div className="p-30">
                <div className="title d-flex align-items-center">
                  <h2 className="">{blog.titre} </h2>{" "}
                  {/* <span className="text-muted ml-3">{blog.category_blog}</span> */}
                </div>
                <img
                  className="w-100 mt-4 mb-4"
                  src={window.IMAGE_API_URL + "blogs/" + blog.image}
                  alt={blog.image}
                />
                <div
                  dangerouslySetInnerHTML={createMarkup(blog.contenue)}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {blog.id && <BlogComments idBlog={blog.id} />}
      </div>
    </>
  );
};

function compare(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}

export default BlogDetails;
const BlogComments = (props) => {
  const [comment, setComment] = useState([]);
  const [comments, setComments] = useState([]);

  const loadComments = () => {
    BlogService.commentByBlogId(props.idBlog)
      .then((res) => {
        // console.log(res.data);
        setComments(res.data.results.sort(compare));
        // console.log(res.data.results);
      })
      .catch(function (error) {
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
                "Impossible de charger les commentaires du blog",
                "",
                2000
              );
        } else if (error.response.status === 401) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  };

  useEffect(() => {
    loadComments();
  }, []);
  const deleteComment = (id) => {
    BlogService.deleteComment(id)
      .then(() => {
        Swal.fire("commentaire supprimé");
        loadComments();
      })
      .catch((err) => NotificationManager.warning(`${err}`));
  };

  const saveComment = () => {
    if (comment) {
      // if (false) {
      const data = {
        user_id: sessionStorage.getItem("id"),
        content: comment,
        id_blog: props.idBlog,
      };
      BlogService.addComment(data)
        .then(() => {
          // Swal.fire("commentaire est ajouter");
          loadComments();
        })
        .catch((err) => NotificationManager.warning(`${err}`));
    } else {
      Swal.fire("vous n'avez pas le droit de commenter ce blog");
    }
  };
  return (
    <div className="col-12 col-md-4">
      <div className="card">
        <div className="card-header">
          <h5>Commentaires</h5>
        </div>
        <div className="row card-body shadow border-0">
          <ul style={{ width: "100%", listStyle: "none" }}>
            {comments.map((c, i) => (
              <li key={i}>
                <div>
                  <hr />
                  <h6>
                    <span>
                      {c.user_id ? (
                        c.username
                      ) : c.adminname ? (
                        <i className="fas fa-crown">
                          {" "}
                          {`${c.adminname} ${c.adminsurname}`}
                        </i>
                      ) : (
                        "Unkon"
                      )}
                    </span>
                    <samp
                      style={{
                        fontSize: "12px",
                        fontStyle: "italic",
                        fontWeight: "normal",
                      }}
                    >
                      <i className="fas fa-calendar-week" />
                      {moment(c.created_at).fromNow()}
                    </samp>
                    <span>
                      {" "}
                      <button
                        className="btn btn-icon "
                        onClick={() => deleteComment(c.id)}
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </span>
                  </h6>

                  <p className="ml-3">{c.content}</p>
                </div>
              </li>
            ))}
          </ul>
          <ul
            className="mt-2"
            style={{
              width: "100%",
              listStyle: "none",
              borderTop: "thick double #32a1ce",
            }}
          >
            <li className="mt-3">
              {sessionStorage.getItem("role")}: {sessionStorage.getItem("user")}{" "}
            </li>
            <li>
              <TextField
                id="comment"
                label="Ajouter un commentaire"
                variant="outlined"
                className="m-3"
                multiline
                maxRows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button
                className="btn btn-icon btn-outline-primary"
                onClick={saveComment}
              >
                <i className="fas fa-comment-medical"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
