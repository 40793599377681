import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WichPage from "../../inc/helpfulComponents/WichPage";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Moment from "react-moment";
import Swal from "sweetalert2";
import Newarrival from "../../../services/newarrival.service";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ClipLoader } from "react-spinners";

const ListAmazingProducts = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [products, setproducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const renderProductImage = (row) => {
    return (
      <img
        src={window.IMAGE_API_URL + "products/" + row.image.split(";")[0]}
        alt={row.image.split(";")[0]}
        width={150}
      />
    );
  };

  const renderproductActions = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-around w-100">
        <Link
          to={"/details/amazing_products/" + row.id}
          className="btn btn-icon btn-outline-primary"
        >
          <i className="feather icon-eye" />
        </Link>
        <Link
          to={"/modifier/amazing_products/" + row.id}
          className="btn btn-icon btn-outline-success"
        >
          <i className="feather icon-edit" />
        </Link>
        <button
          type="button"
          className="btn btn-icon btn-outline-danger"
          id={row.id}
          onClick={deleteProduct}
        >
          <i className="far fa-trash-alt" id={row.id}></i>
        </button>
      </div>
    );
  };

  const renderProductDate = (row) => {
    return (
      <div className="d-flex w-100 align-items-center justify-content-start">
        <Moment format="D MMM YYYY" withTitle>
          {row.created_at}
        </Moment>
        <div className="text-muted mx-3">
          ( <Moment fromNow>{row.created_at}</Moment> )
        </div>
      </div>
    );
  };
  const [columns, setColumns] = useState([
    { selector: "id", name: "id", width: "70px" },
    {
      selector: "image",
      name: "Image",
      width: "180px",
      cellExport : (row) => row.images,
      cell: (row) => renderProductImage(row),
    },
    {
      selector: "name",
      name: "Titre",
      width: "180px",
    },
    {
      selector: "category",
      name: "Catégorie",
      width: "250px",
    },
    {
      selector: "created_at",
      name: "Date d'ajout",
      type: "date",
      width: "250px",
      cell: (row) => renderProductDate(row),
    },
    {
      selector: "count_fois_devis",
      name: "N° commandes",
      width: "auto",
    },
    {
      selector: "count_fois_wishlist",
      name: "N° souhaits",
      width: "auto",
    },

    {
      selector: "count_fois_click",
      name: "N° clicks",
      width: "auto",
    },
    {
      name: "Action",
      width: "250px",
      sortable: false,
      cellExport : (row) => '',
      cell: (row) => renderproductActions(row),
    },
  ]);
  const tableData = {
    columns,
    data: products,
  };

  const deleteProduct = (event) => {
    const token = sessionStorage.getItem("token");
    const productId = event.target.id;
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "Vous ne pourrez pas revenir sur ce produit !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez-le",
        cancelButtonText: "Non, annulez !",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          Newarrival.deleteAmazingProduct(productId, token)
            .then((result) => {
              setTimeout(() => {
                Newarrival.getAllAmazingProducts().then(async (res) => {
                  setproducts(res.data.results[0]);
                  setLoading(false);
                });
              }, 300);

              swalWithBootstrapButtons.fire(
                "Supprimé !",
                "Le produit a été supprimé.",
                "success"
              );
            })
            .catch(function (error) {
              if (!error.response) {
                swalWithBootstrapButtons.fire(
                  "Annulé",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "error"
                );
              } else if (error.response.status === 403) {
                setLoading(false);
                error.response.data.error
                  ? swalWithBootstrapButtons.fire(
                      "Annulé",
                      error.response.data.error,
                      "error"
                    )
                  : swalWithBootstrapButtons.fire(
                      "Annulé",
                      "Impossible de supprimer ce produit",
                      "error"
                    );

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.response.status === 401) {
                setLoading(false);
                swalWithBootstrapButtons.fire(
                  "Annulé",
                  "Votre session est expiree, veuillez vous reconnectez",
                  "error"
                );
                // setTimeout(() => {
                //   AuthService.logout()
                // }, 500);
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "Le produit n'a pas été supprimé.",
            "error"
          );
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    Newarrival.getAllAmazingProducts().then(async (res) => {
      setproducts(res.data.results[0]);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <WichPage
        pageTitle="Offres  Combinées"
        pageLocation="amazing_products"
      />
      <div className="row">
        {/* customar project  start */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5>Produits</h5>
              <div className="card-header-right">
                <h5>Ajouter un produit</h5>

                <Link
                  className="btn btn-icon btn-outline-primary"
                  to="/addAmazingProduct"
                >
                  <i className="fas fa-plus-circle"></i>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {!loading ? (
                <DataTableExtensions {...tableData}>
                  <DataTable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              ) : (
                <ClipLoader color={"#000000"} loading={loading} />
              )}
            </div>
          </div>
        </div>
        {/* customar project  end */}
      </div>
    </>
  );
};

export default ListAmazingProducts;
